import { Directive, Input, Output, EventEmitter, ElementRef, Renderer2, HostListener, HostBinding } from '@angular/core';

@Directive({ selector: '[flx-dynamic-sep]' })
export class DynamicSeparatorDirective
{
  private _dragging: boolean;
  private viewASpan: number;
  private viewBSpan: number;

  @Input("panelA")
  public viewA: Element;

  @Input("panelB")
  public viewB: Element;

  @Input("direction")
  public direction: 'column' | 'row' = 'column';

  @Output()
  public change = new EventEmitter();

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
  ) { }

  public ngOnInit()
  {
    this._elementRef.nativeElement.classList.add(this.direction);
  }

  @HostListener('mousedown', ['$event'])
  public onMouseDown(e: MouseEvent)
  {
    e.preventDefault();
    this._dragging = true;
  }

  @HostListener('document:mousemove', ['$event'])
  public onMouseMove(e: MouseEvent)
  {
    if (this._dragging)
    {
      if (this.direction == 'column')
      {
        if (this.viewA)
        {
          this.viewASpan = this.viewA.clientWidth + e.movementX;
          this._renderer.setStyle(this.viewA, 'width', this.viewASpan + "px");
        }
        if (this.viewB)
        {
          this.viewBSpan = this.viewB.clientWidth - e.movementX;
          this._renderer.setStyle(this.viewB, 'width', this.viewASpan + "px");
        }
      }
      else  
      {
        if (this.viewA)
        {
          this.viewASpan = this.viewA.clientHeight + e.movementY;
          this._renderer.setStyle(this.viewA, 'height', this.viewASpan + "px");
        }
        if (this.viewB)
        {
          this.viewBSpan = this.viewB.clientHeight - e.movementY;
          this._renderer.setStyle(this.viewB, 'height', this.viewASpan + "px");
        }
      }
    }
  }

  @HostListener('document:mouseup')
  public onMouseUp()
  {
    if (this._dragging)
    {
      this._dragging = false;
      this.change.emit({
        spanA: this.viewASpan,
        spanB: this.viewBSpan,
      });
    }
  }
}
