import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {Message} from "primeng/api";

@Component({
  selector: "dialog-message",
  templateUrl: "dialog-message.html",
  styleUrls: ["dialog-message.css"]
})
export class DialogMessageComponent implements OnInit, OnChanges {
  @Input() messages: Message[];

  public _messages: Message[];

  constructor() {
    this._messages = [];
  }

  public ngOnInit(): void {
    if(this.messages) {
      this._messages = this.messages;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.messages && changes.messages.currentValue) {
      this._messages = changes.messages.currentValue;
    }
  }

  public getColor(severity: string): string {
    let color: string;

    switch(severity) {
      case "success":
        color = "#2fb457";
        break;

      case "error":
        color = "#ee3124";
        break;

      case "warn":
        color = "#fcb131";
        break;

      case "info":
        color = "#00bce4";
        break;

      default:
        color = "#fff";
    }

    return color;
  }

  public removeMessage(message: Message): void {
    let idx = this._messages.findIndex(m => m.summary === message.summary);
    if(idx >= 0) {
      this._messages.splice(idx, 1);
    }
  }
}
