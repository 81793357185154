import { Component, OnInit } from "@angular/core";
import { AuthService } from "@slender/auth";
import { Message, SelectItem } from "primeng/api";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { DownloadController } from "../../controllers";
import { BriefActualDetailsModel } from "../../models/brief-actual-details.model";
import { BriefExplanationModel } from "../../models/brief-explanation.model";
import { FileType } from "../../models/file-data/file-type.model";
import { ClaimService } from "../../services/claim.service";
import { FileService } from "../../services/file.service";
import { FileDownloadDialog } from "../file-download/file-download.dialog";
import { FileUploadDialog } from "../file-upload/file-upload.dialog";
import { ClaimDataModel } from "../../models/claim-data.model";
import { ClaimSaveModel } from "../../models/claim-save.model";
import { DialogResponseModel } from "../../models/response-status/dialog-response.model";
import { IssueReasonModel } from "../../models/issue-reason/issue-reason.model";
import { ClaimIssueReasonInterface } from "../../models/claim-issue-reason/claim-issue-reason.interface";
import { ReasonInterface } from "../../models/issue-reason/reason.interface";
import { ClaimIssueReasonDto } from "../../models/claim-issue-reason/claim-issue-reason.dto";
import { RejectClaimDialog } from "../reject-claim/reject-claim.dialog";

interface issueReasonInt {
  name: string;
  code: string;
}

@Component({
  templateUrl: "claim-brief.html",
  styleUrls: ["claim-brief.css"],
  providers: [DialogService]
})
export class ClaimBriefDialog implements OnInit {
  public _claimDetails: ClaimSaveModel;
  public _claimLoadDetails: ClaimSaveModel;
  public _claimSaveData: ClaimSaveModel;

  public _claimData: ClaimDataModel;
  public _briefActualDetails: BriefActualDetailsModel;
  public _briefExplanations: BriefExplanationModel;
  private _productClassType: string;

  private _enableDownload: boolean;
  private _updateClaimsData: boolean;
  private _isUndelivered: boolean;
  private _isClaimAcceptedRejected: boolean;
  public _shipmentHasFile: boolean;

  public _issues: issueReasonInt[];
  public _reasons: issueReasonInt[];
  public _types: SelectItem[];


  public _claimDate: Date;

  public _issueReasons: IssueReasonModel;
  public _availableReasons: ReasonInterface[];

  public _selectedIssue: ClaimIssueReasonInterface;
  public _selectedReason: ReasonInterface;

  public _currentIssue: ClaimIssueReasonDto;
  public _currentReason: ClaimIssueReasonDto;


  selectedIssue: issueReasonInt;
  selectedReason: issueReasonInt;

  selectedSealIntact: boolean;
  selectedPalletStack: boolean;
  selectedProductOnPallet: boolean;
  selectedCrateGoodCondition: boolean;
  selectedPalletSlip: boolean;
  selectedLoadSheet: boolean;


  public _loading: boolean;
  public _messages: Message[];
  public _readOnlyVar: boolean;
  public _saving: boolean
  public _isOther: boolean;
  public _isNewLoad: boolean;
  public _type: boolean;

  public _isCompletedOrRejected: boolean;

  public _cloverClaim: boolean;
  public _transporterClaim: boolean;

  public _isSameUserGroup: boolean;
  public _isClaimLodger: boolean;

  constructor(
    private _authService: AuthService,
    private _claimService: ClaimService,
    private _config: DynamicDialogConfig,
    private _downloadController: DownloadController,
    private _dialogService: DialogService,
    private _fileService: FileService,
    private _ref: DynamicDialogRef
  ) {
    this._loading = true;
    this._saving = false;
    this._enableDownload = false;
    this._updateClaimsData = false;
    this._isOther = false;
    this._isNewLoad = false;
    this._type = false;
    this._isClaimAcceptedRejected = false;
    this._messages = [];

    this._readOnlyVar = _authService.hasPermission("ReadOnlyUser");
    this._cloverClaim = _authService.hasPermission("CloverClaim");
    this._transporterClaim = _authService.hasPermission("TransporterClaim");

    this._isClaimLodger = false;
    this._isSameUserGroup = false;
    this._isCompletedOrRejected = false;

    this._issueReasons = new IssueReasonModel();
    //this._claimLoadDetails = new ClaimSaveModel();
    this._claimData = new ClaimDataModel();
    this._claimLoadDetails = new ClaimSaveModel();
    this._availableReasons = [];

    this._types = [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ];
  }

  public ngOnInit(): void {
    if (this._config.data.readOnlyVar != null) {
      this._readOnlyVar = this._config.data.readOnlyVar;
    }

    if (this._config.data.cloverClaim != null) {
      this._cloverClaim = this._config.data.cloverClaim;
    }

    if (this._config.data.transporterClaim != null) {
      this._transporterClaim = this._config.data.transporterClaim;
    }

    if (this._config.data.isClaimLodger != null) {
      this._isClaimLodger = this._config.data.isClaimLodger;
    }
    if (this._config.data.isSameUserGroup != null) {
      this._isSameUserGroup = this._config.data.isSameUserGroup;
    }

    if (this._config.data.claimDetails) {
      // TODO: Never passed in calling method.
      //this._claimLoadDetails = this._config.data.claimDetails;
      this._claimDetails = this._config.data.claimDetails;
    } else {
      this._ref.close("Invalid-Claim Details")
    }

    this.loadData();

    if (this._selectedIssue) {
      this._availableReasons = this._selectedIssue.reason.map(r => r);
    }

    this._loading = false;
  }

  public cancelSubmit(): void {
    this._ref.close(this._updateClaimsData);
    
  }

  public onSubmit(): void {

    this._claimSaveData = new ClaimSaveModel();

    this._claimSaveData.claimDate = this._claimDate;
    this._claimSaveData.issue = this._selectedIssue.issue;
    this._claimSaveData.reason = this._selectedReason.reason;
    this._claimSaveData.otherReason = this._claimData.otherReason;
    this._claimSaveData.description = this._claimData.description;
    this._claimSaveData.amount = this._claimData.amount
    this._claimSaveData.sealNumber = this._claimData.sealNumber;

    this._claimSaveData.isSealIntact = this.selectedSealIntact.valueOf();
    this._claimSaveData.isPalletCorrectlyPacked = this.selectedPalletStack.valueOf();
    this._claimSaveData.isProductInPallet = this.selectedProductOnPallet.valueOf();
    this._claimSaveData.isCrateInGoodCondition = this.selectedCrateGoodCondition.valueOf();
    this._claimSaveData.isPalletSlip = this.selectedPalletSlip.valueOf();
    this._claimSaveData.isLoadSheet = this.selectedLoadSheet.valueOf();

    if (this._cloverClaim) {
      this._claimSaveData.userGroup = "Clover";
    }

    if (this._transporterClaim) {
      this._claimSaveData.userGroup = "Transporter";
    }

    //this._claimSaveData.claimStatus = 'Claim Lodged';
    this._claimSaveData.claimUserId = 0;
    this._claimSaveData.transportRequestId = this._claimDetails.transportRequestId ? this._claimDetails.transportRequestId : this._claimLoadDetails.transportRequestId;

    this._claimService.saveClaimData(this._claimSaveData).subscribe(obs => {
      let dialogResponse = new DialogResponseModel("Claim");
      dialogResponse.addResponse(obs);
      
      this._saving = true;
      this._updateClaimsData = true;
      this._messages.push({
        severity: "success",
        detail: "Feedback saved - Upload document(s) to complete claim submission."
      });

    }, error => {
      this._messages.push({ severity: "error", detail: "Failed to update Claims data." });
      this._saving = false;
    })

  }

  private loadData(): void {

    this.loadIssues();
    // Populate brief models
    //ToDo: Move to trip-claim-dialog
    if (this._config.data.type == null) {
      this.loadClaimSavedData();     
    } else {
      this._type = true;
      this._claimDetails.claimId = 0;
    }
    
  }

  //ToDo: Move to trip-claim-dialog
  public showDownload(): void {
    //let shipmentId = this._claimLoadDetails.transportRequestId;
    let shipmentId = this._claimDetails.transportRequestId ? this._claimDetails.transportRequestId : this._claimLoadDetails.transportRequestId;
    //let claimId = this._claimLoadDetails.claimId;
    let claimId = this._claimDetails.claimId ? this._claimLoadDetails.claimId : this._claimDetails.claimId ;

    const ref = this._dialogService.open(FileDownloadDialog, {
      data: {
        type: FileType.claim,
        fileId: shipmentId,
        claimId: claimId,
        shipment: false
      },
      header: "File Download",
      width: "75%"
    });
  }

  public showUpload(): void {
    let tripId = this._claimDetails.transportRequestId !== 0 ? this._claimDetails.transportRequestId : this._claimLoadDetails.transportRequestId;
    let isUndelivered = true;

    const ref = this._dialogService.open(FileUploadDialog, {
      closable: false,
      data: {
        type: FileType.claim,
        tripId: tripId,
        isUndelivered: isUndelivered
      },
      header: "File Upload",
      width: "85%"
    });

    ref.onClose.subscribe((obs: boolean) => {
      if (!obs) {
        return;
      }

      this._claimService.changeClaimStatus(tripId, 'Claim Progress', ' ', 0).subscribe(obs => {
        if (!obs) {
          return;
        } else if (obs.isError === false){
          this._enableDownload = true; 
        }
        
        this._saving = false;
        this._updateClaimsData = true;
        if (this._config.data.type == null) {
          this._enableDownload = true;  
        }
        
        this._messages.push({
          severity: "success",
          detail: "Claim submission completed. You can download your uploaded file(s)."
        });
      }, error => {
        this._messages.push({ severity: "error", detail: "Failed to update claim status" });
      });
    });
  }

  //ToDo: Move to trip-claim-dialog
  public acceptClaim(): void {
    this._claimService.changeClaimStatus(this._claimDetails.transportRequestId, 'Claim Accepted', ' ', this._claimDetails.claimId).subscribe(obs => {
      if (!obs) {
        return;
      }

      this._isClaimAcceptedRejected = true;
      this._updateClaimsData = true;
      this._enableDownload = true;
      this._messages.push({
        severity: "success",
        detail: "Claim Accepted."
      });
    }, error => {
      this._messages.push({ severity: "error", detail: "Failed to update claim status" });
    });
  }

  //ToDo: Move to trip-claim-dialog
  public rejectClaim(): void {

    const ref = this._dialogService.open(RejectClaimDialog, {
      data: {
        trId: this._claimDetails.transportRequestId,
        readOnlyVar: false, 
        claimId: this._claimDetails.claimId
      },
      header: "Reject Claim For " + this._claimDetails.trCode,
      width: "45%"
    });

    ref.onClose.subscribe(obs => {
      if (!obs) {       
        return;
      }

      this._isClaimAcceptedRejected = true;
      this._updateClaimsData = true;
      this._enableDownload = true;
      this._messages.push({
        severity: "success",
        detail: "Claim Rejected."
      });

    });
  }


  private loadIssues(): void {
    this._claimService.getClaimIssueReasons().subscribe(obs => {
      if (!obs) {
        return;
      }
      this._issueReasons = new IssueReasonModel(obs);
    }, error => {
      this._messages.push(
        { severity: "error", detail: "Failed to load issue/reasons" }
      );
    });
  }

  public onIssueChange(event: ClaimIssueReasonInterface): void {
    if (event) {
      this._availableReasons = event.reason.map(r => r);
      if (event.issue === 'Other') {
        this._isOther = true;
      } else {
        this._isOther = false;
        this._claimData.otherReason = '';
      }
    } else {
      this._availableReasons = [];
    }
  }

  //ToDo: Do Not Load here, load on trip-claim-dialog
  private loadClaimSavedData(): void {
    this._claimService.loadClaimSavedData(this._claimDetails.transportRequestId, this._claimDetails.claimId).subscribe(obs => {
      if (!obs) {
        return;
      }

      this._claimLoadDetails = obs[0];

      if (this._claimLoadDetails.transportRequestId == 0) {
        this._isNewLoad = true;
      }    

      this._claimData.claimStatus = this._claimLoadDetails.claimDetailsStatus ? this._claimLoadDetails.claimDetailsStatus : 'No Status';
      this._claimData.otherReason = this._claimLoadDetails.otherReason;

      if (this._claimData.claimStatus === 'Claim Completed' || this._claimData.claimStatus === 'Claim Rejected') {
        this._isCompletedOrRejected = true;
      }

      if (this._claimLoadDetails) {
        let selected = this._issueReasons.claimIssue.find(ir => ir.issue === this._claimLoadDetails.issue);
        if (selected) {
          this._selectedIssue = selected;

          this._availableReasons = this._selectedIssue.reason.map(r => r);
        }
      }

      if (this._claimLoadDetails) {
        let selected = this._availableReasons.find(i => i.reason === this._claimLoadDetails.reason);

        if (selected) {
          this._selectedReason = selected;
        }
      }

      this._claimData.description = this._claimLoadDetails.description;

      this._claimData.sealNumber = this._claimLoadDetails.sealNumber;
      this._claimData.hasFile = this._claimLoadDetails.hasFile;

      if (obs[0].claimUserId != 0) {
        this._claimDate = this._claimLoadDetails.claimDate;
        this._saving = true;

        this._claimData.amount = this._claimLoadDetails.amount;
        this.selectedSealIntact = this._claimLoadDetails.isSealIntact;
        this.selectedPalletStack = this._claimLoadDetails.isPalletCorrectlyPacked;
        this.selectedProductOnPallet = this._claimLoadDetails.isProductInPallet;
        this.selectedCrateGoodCondition = this._claimLoadDetails.isCrateInGoodCondition;
        this.selectedPalletSlip = this._claimLoadDetails.isPalletSlip;
        this.selectedLoadSheet = this._claimLoadDetails.isLoadSheet;
      }
    }, error => {
      this._messages.push(
        { severity: "error", detail: "Failed to load claim saved data" }
      );
    });
  }

}
