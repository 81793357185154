import {Component, OnDestroy, OnInit} from "@angular/core";
import {AuthService} from "@slender/auth";
import {AppStatus, AsyncTaskController} from "@slender/foundation";
import {MessageService} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {Observable, Subject} from "rxjs";
import {switchMap, takeUntil} from "rxjs/operators";
import {PermissionConstants} from "../../constants/permission.constants";
import {PrimaryFilterController} from "../../controllers";
import {RateModel} from "../../models/rate.model";
import {ColumnDefinitionModel} from "../../models/table/column-definition.model";
import {ColumnFilterModel} from "../../models/table/column-filter.model";
import {TableStateModel} from "../../models/table/table-state.model";
import {RatesService} from "../../services/rates.service";
import {TableService} from "../../services/table.service";

@Component({
  selector: "rates-management",
  templateUrl: "rates-management.html",
  styleUrls: ["rates-management.css"],
  providers: [DialogService, MessageService]
})
export class RatesManagementComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean>;

  public _loading: boolean;
  public _readOnlyVar: boolean;

  // Main Table
  public _availableColumns: ColumnDefinitionModel[];
  public _dataKey: string;
  public _tableData: RateModel[];
  public _tableLoading: boolean;
  public _tableState: TableStateModel;

  private _taskId: string;

  constructor(
    private _authService: AuthService,
    private _asyncTaskController: AsyncTaskController,
    private _dialogService: DialogService,
    private _filterController: PrimaryFilterController,
    private _messageService: MessageService,
    private _ratesService: RatesService,
    private _tableService: TableService,
  ) {
    this._destroy$ = new Subject<boolean>();

    this._loading = true;
    this._tableLoading = true;

    this._readOnlyVar = _authService.hasPermission(PermissionConstants.readOnlyUser);

    this._tableData = [];

    this.setTable();
  }

  public ngOnInit(): void {
    this._asyncTaskController.updateStatus(new AppStatus("info", "Ready"));

    this.loadTableStates();

    this._filterController.filterObservable.pipe(
      takeUntil(this._destroy$),
      switchMap(filter =>
        this.loadTableData(filter.monthPicked)
      )
    ).subscribe();
  }

  public ngOnDestroy(): void {
    if(this._taskId) {
      this._asyncTaskController.completeTask(this._taskId);
    }

    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }

  public saveState(state: TableStateModel): void {
    this._tableService.SaveTableStates(state).subscribe(obs => {
      if(!obs) {
        this._messageService.add({
          severity: "warning",
          summary: "Table State",
          detail: "Failed to update table state."
        });
      }
    }, error => {
      this._messageService.add({severity: "warning", summary: "Table State", detail: "Failed to update table state."});
    });
  }

  private loadTableData(monthPicked: Date): Observable<void> {
    this._tableLoading = true;

    this._taskId = this._asyncTaskController.startTask("Loading rates");

    this._ratesService.getRates(monthPicked).subscribe(obs => {
      if(this._tableLoading) {
        this._tableData = obs;
      }

      this._tableLoading = false;

      this._asyncTaskController.completeTask(this._taskId);
    }, error => {
      this._tableLoading = false;

      this._messageService.add({
        severity: "error",
        summary: "Load Data",
        detail: "Failed to load rates data."
      });

      this._asyncTaskController.completeTask(this._taskId);
    });

    return new Observable<void>();
  }

  private loadTableStates(): void {
    this._tableService.GetTableStates(this._tableState.name).subscribe(obs => {
      if(!obs) {
        this._loading = false;
        return;
      }

      let state: TableStateModel = JSON.parse(obs);
      if(state && state.name === this._tableState.name) {
        if(!state.groupedColumns) {
          state.groupedColumns = ["tariffCode"]
        } else if(!state.groupedColumns!.includes("tariffCode")) {
          let temp = state.groupedColumns.map(gc => gc);
          state.groupedColumns = ["tariffCode"];

          temp.forEach(c => {
            state.groupedColumns!.push(c);
          });
        }

        this._tableState = state;
      }
      this._loading = false;
    }, error => {
      this._loading = false;
      this._messageService.add({
        severity: "warning",
        summary: "Table State",
        detail: "Failed to restore Transport Request table state."
      });
    });
  }

  private setTable(): void {
    this._availableColumns = [
      {field: "haulierName", header: "Transporter", width: "175px", filter: true},
      {field: "serviceType", header: "Service Type", width: "180px", filter: true},
      {field: "tariffCode", header: "Tariff ID", width: "150px", filter: true},
      {field: "tariffName", header: "Tariff Name", width: "180px", filter: true},
      {field: "chargeName", header: "Charge Name", width: "180px", filter: true},
      {field: "chargeTypeName", header: "Charge Type", width: "180px", filter: true},
      {
        field: "priorMonthRate",
        header: "Previous Rate",
        width: "140px",
        filter: true,
        dataType: "number",
        filterConstraint: new ColumnFilterModel("number")
      },
      {
        field: "currentMonthRate",
        header: "Current Rate",
        width: "140px",
        filter: true,
        dataType: "number",
        filterConstraint: new ColumnFilterModel("number")
      },
      {
        field: "rateVariance",
        header: "Rate Variance",
        width: "140px",
        filter: true,
        dataType: "number",
        filterConstraint: new ColumnFilterModel("number")
      },
      {
        field: "variancePercent",
        header: "% Variance",
        width: "140px",
        filter: true,
        dataType: "number",
        filterConstraint: new ColumnFilterModel("number")
      }
    ];

    this._dataKey = "rowNumber";
    this._tableState = {
      name: "rates-management-state"
    };
  }
}
