import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {DriverRouteModel} from "../../../models/home/driver-route.model";

@Component({
  selector: "driver-routes",
  templateUrl: "driver-routes.html",
  styleUrls: ["driver-routes.css"]
})
export class DriverRoutesComponent implements OnInit, OnChanges {
  @Input() routes: DriverRouteModel[];
  @Input() edit: boolean;
  @Input() superUser: boolean;

  @Output() update: EventEmitter<DriverRouteModel[]>;

  public _message: string;
  public _routes: DriverRouteModel[];

  constructor() {
    this._routes = [];

    this._message = "";
    this.update = new EventEmitter<DriverRouteModel[]>();
  }

  public ngOnInit(): void {
    if(this.routes) {
      this._routes = [];

      this.routes.forEach(route => {
        this._routes.push(DriverRouteModel.Copy(route));
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.routes && changes.routes.currentValue) {
      this._routes = [];

      let routes: DriverRouteModel[] = changes.routes.currentValue;
      routes.forEach(route => {
        this._routes.push(DriverRouteModel.Copy(route));
      });
    }
  }

  public addRoute(): void {
    let id = 0;
    if(this._routes.length > 0) {
      id = this._routes[this._routes.length - 1].id + 1;
    }

    let route = new DriverRouteModel();
    route.id = id;

    this._routes.push(route);
  }

  public deleteRoute(routeId: number): void {
    let idx = this._routes.findIndex(r => r.id === routeId);
    if(idx === -1) {
      return;
    }

    this._routes.splice(idx, 1);
    this.saveRoutes();
  }

  public updateRoute(routeId: number, property: string, event: string): void {
    let idx = this._routes.findIndex(r => r.id === routeId);
    if(idx === -1) {
      return;
    }

    switch(property) {
      case 'origin':
        this._routes[idx].origin = event;
        break;

      case 'direction':
        this._routes[idx].direction = event;
        break;

      case 'destination':
        this._routes[idx].destination = event;
        break;

      case 'vehicleType':
        this._routes[idx].vehicleType = event;
        break;

      default:
    }
  }

  public saveRoutes(): void {
    for(let i = 0; i < this._routes.length; i++) {
      if(!this._routes[i].validate()) {
        this._message = "Invalid route: Origin, Direction, Destination, and Vehicle Type required";
        return;
      } else {
        this._message = "";
      }
    }

    this.update.emit(this._routes);
  }
}
