import { AuthService } from './auth.service';
export * from './auth.service';

import { AuthGuard } from './auth-guard.service';
export * from './auth-guard.service';

export const SERVICES = [
  AuthService,
  AuthGuard
];
