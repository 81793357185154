import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: "user-claim",
  templateUrl: "user-claim.html",
  styleUrls: ["user-claim.css"]
})
export class UserClaimComponent implements OnInit, OnDestroy {
  //Public and Private variables here

  //Constructor
  constructor() { }

  //Public and Private functions
  public ngOnInit(): void {

  }

  public ngOnDestroy(): void {

  }

}
