export class ClaimDataModel {
  transportRequestId: number;
  date: Date;
  issue: string;
  reason: string;
  otherReason: string;
  description: string;
  amount: number;
  sealNumber: string;
  claimStatus: string;
  isSealIntact: boolean;
  isPalletCorrectlyPacked: boolean;
  isProductInPallet: boolean;
  isCrateInGoodCondition: boolean;
  isPalletSlip: boolean;
  isLoadSheet: boolean;
  hasFile?: boolean;

  constructor() {
    //this.transportRequestId = 0;
    //this.date = new Date();
    //this.reason = "";
    //this.otherReason = "";
    //this.description = "";
    //this.amount = 10;
    //this.sealNumber = "";
    //this.issue = "";

    //this.isSealIntact = true;
    //this.isPalletCorrectlyPacked = true;
    //this.isProductInPallet = true;
    //this.isCrateInGoodCondition = true;
    //this.isPalletSlip = true;
    //this.isLoadSheet = true;
    //this.hasFile = false;
  }
}
