import { SystemConfigService } from './system-config.service';
export * from './system-config.service';

import { SystemBuildInfoService } from './system-build-info.service';
export * from './system-build-info.service';
 
import { ConfigService } from './config.service';
export * from './config.service';

export * from './signalR.service';

import { PushEventService } from './push-event.service';
export * from './push-event.service';

export const SERVICES = [
  SystemConfigService,
  SystemBuildInfoService,
  ConfigService,
  PushEventService
];
