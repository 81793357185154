export class ClaimSaveModel {
    claimId: number;
    claimUserId: number;
    transportRequestId: number;
    trCode: string;
    claimDetailsStatus: string;
    issue: string;
    reason: string;
    otherReason: string;
    description: string;
    amount: number;
    claimDate: Date;
    sealNumber: string;
    isSealIntact: boolean;
    isPalletCorrectlyPacked: boolean;
    isProductInPallet: boolean;
    isCrateInGoodCondition: boolean;
    isPalletSlip: boolean;
    isLoadSheet: boolean;
    hasFile?: boolean;
    userGroup: string;
    claimer: string;
    handler: string;
    rejectReason: string;
    incidentDate: Date;

  constructor() {

  }
}
