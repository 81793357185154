import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { AuthService } from '@slender/auth';
import { AsyncTaskController, AppStatus } from '@slender/foundation';

import { ConfigService } from '../../services';

@Component({
  selector: 'app-header',
  templateUrl: 'header.html',
  styleUrls: ['header.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent
{
  constructor(
    private _authService: AuthService,
    private _configService: ConfigService,
    private _asyncTaskController: AsyncTaskController,
    private _cd: ChangeDetectorRef)
  {
    console.debug("HeaderComponent ctor");
  }

  public ngOnInit()
  {
    this._asyncTaskController.updateStatus(new AppStatus('wait', `Loading...`));
  }

  private onSoftReload()
  {
  }
}
