import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import sha256 from "fast-sha256";
import { TextEncoder } from 'text-encoding-shim';

import { LogItem, LogFilterModel } from '../infrastructure';
import { SignalRService } from '../../../services';

@Injectable()
export class LoggingService extends SignalRService<LogItem>
{
  private _lastMessages = new Array(5).fill("");

  constructor(private _http: HttpClient)
  {
    super();
    console.debug("LoggingService ctor");
  }

  public getLogs(filterModel?: LogFilterModel): Observable<LogItem[]>
  {
    let params: any = {};

    if (filterModel)
    {
      filterModel.maxCount ? params.maxCount = filterModel.maxCount : 1;
      filterModel.startTime ? params.startTime = filterModel.startTime.toISOString() : null;
      filterModel.endTime ? params.endTime = filterModel.endTime.toISOString() : null;
      filterModel.type ? params.type = filterModel.type : "error";
      filterModel.source ? params.source = filterModel.source : null;
      filterModel.user ? params.user = filterModel.user : null;
      filterModel.message ? params.message = filterModel.message : null;
    }
    else
    {
      params = { maxCount: 50, type: "$info$warning$error" };
    }

    return this._http.get<string[]>('logs', { params: params })
      .pipe(map(logs => logs.map(logData => this.formatLogData(logData))));
  }

  public connect()
  {
    super.connect("/logs-hub", 'log-event');
  }

  protected onReceive(value: string)
  {
    this.broadcaster.broadcast(this.formatLogData(JSON.parse(value)));
  }

  private formatLogData(logData: any): LogItem
  {
    return new LogItem(
      logData['@t'],
      (logData['@l'] || "info").toLowerCase(),
      logData['User'] ? logData['User'] : "anonymous",
      logData['SourceContext'],
      logData['@m'] ? logData['@m'].split(" //", 1)[0] : logData['@mt'],
      logData['@x']);
  }

  public logInfo(info: Info)
  {
    if (this.isNew(info.message))
    {
      this._http.post('logs/log-info', info)
        .subscribe(res => { }, err => console.error(err));
    }
  }

  public logWarning(warning: Warning)
  {
    if (this.isNew(warning.message))
    {
      this._http.post('logs/log-warning', warning)
        .subscribe(res => { }, err => console.error(err));
    }
  }

  public logError(error: Error)
  {
    if (this.isNew(error.message))
    {
      this._http.post('logs/log-error', {
        name: error.name,
        message: error.message,
        stack: error.stack
      }).subscribe(res => { }, err => console.error(err));
    }
  }

  private isNew(data: string): boolean
  {
    let hash = this.hash(data);
    if (!this._lastMessages.includes(hash))
    {
      this._lastMessages.unshift(hash);
      this._lastMessages.pop();
      return true;
    }

    return false;
  }

  private hash(data: string): string
  {
    let uint8array = new TextEncoder("utf-8").encode(data);
    let hashArray = sha256(uint8array);

    let hash = "";
    for (let i = 0; i < hashArray.length; i++)
    {
      hash += ("0" + hashArray[i].toString(16)).slice(-2);
    }

    return hash;
  }
}

export interface Info
{
  message: string;
}

export interface Warning
{
  message: string;
  severity: 'low' | 'medium' | 'high';
}
