import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { LogItem, LogFormatModel } from '../infrastructure';

@Component({
  selector: 'slender-log-item',
  templateUrl: 'log-item.html',
  styleUrls: ['log-item.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogItemComponent
{
  @Input()
  public log: LogItem;

  @Input()
  public format: LogFormatModel;

  public showError: boolean;
}
