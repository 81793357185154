import { Injectable } from '@angular/core'
import { Broadcaster } from '@slender/core';
import { Session } from '../infrastructure';

@Injectable()
export class AuthController
{
  public verified = new Broadcaster<Session>();
  public loggedOut = new Broadcaster<Session>();

  constructor() { }
}
