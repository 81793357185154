import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';

//import { LogisticsModule } from '@logistics';

import { SlenderFoundationModule } from '@slender/foundation';
import { SlenderAdminModule } from '@slender/auth';
import { SlenderFlexModule } from "@slender/flex";
//import { SlenderDateRangePickerModule } from "@slender/date-range-picker";

//import { OpsiCloudCoreModule } from '@opsi-cloud/core';

import { COMPONENTS, PlatformLayoutComponent } from './components';
import { CONTROLLERS } from './controllers';
import { SERVICES } from './services';

export function SlenderPlatformModule(config: PlatformConfig)
{
  @NgModule({
    imports: [
      CommonModule,

      MatButtonModule,
      MatSelectModule,
      MatProgressSpinnerModule,
      MatMenuModule,

      //LogisticsModule,

      SlenderFoundationModule.forRoot(),
      SlenderAdminModule,
      SlenderFlexModule,
      //SlenderDateRangePickerModule,

      //OpsiCloudCoreModule,

      RouterModule.forChild([{
        path: '',
        component: PlatformLayoutComponent,
        children: config.appRoutes,
        loadChildren: config.lazyModule ? () => config.lazyModule : undefined
      }])
    ],
    declarations: [
      COMPONENTS
    ],
    providers: [
      CONTROLLERS,
      SERVICES
    ]
  })
  class PlatformModuleClass
  {
    constructor(@Optional() @SkipSelf() thisModule: PlatformModuleClass)
    {
      if (thisModule) { throw new Error("PlatformModule is already loaded. Import at root only."); }
    }
  }

  return PlatformModuleClass;
}

type PlatformConfig = {
  appRoutes?: Routes,
  lazyModule?: any
};
