import { Injectable } from '@angular/core'

import { ConfigService as BaseConfigService } from '@slender/foundation';

import { UserConfig } from '../infrastructure';

@Injectable()
export class ConfigService
{
  public systemData: any;
  public get userConfig(): UserConfig
  {
    if (!this._isInitialized || !(this._baseConfigService.userData instanceof UserConfig))
    {
      this._isInitialized = true;
      this._baseConfigService.userData = new UserConfig(this._baseConfigService.userData)
    }
    return this._baseConfigService.userData;
  }

  private _isInitialized: boolean;

  constructor(private _baseConfigService: BaseConfigService)
  {
    console.debug("Platform ConfigService ctor");
  }

  public saveSystem()
  {
    this._baseConfigService.saveSystem();
  }

  public saveUser()
  {
    this._baseConfigService.saveUser();
  }
}
