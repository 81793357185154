import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {AppLayoutComponent} from "./components";
import {ClaimsManagementComponent} from "./components/claims-management/claims-management.component";
import {HomeComponent} from "./components/home/home.component";
import {RatesManagementComponent} from "./components/rates-management/rates-management.component";
import {SiteManagementComponent} from "./components/site-management/site-management.component";
import {TripManagementComponent} from "./components/trip-management/trip-management.component";

export const routes: Routes = [
  {path: "", redirectTo: "/home", pathMatch: "full"},
  {
    path: "",
    component: AppLayoutComponent,
    children: [
      {path: "home", component: HomeComponent},
      {path: "trip-management", component: TripManagementComponent},
      {path: "rates-management", component: RatesManagementComponent},
      {path: "claims-management", component: ClaimsManagementComponent},
      {path: "site-management", component: SiteManagementComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
