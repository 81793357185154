import {DownloadController} from "./download.controller";
import {PrimaryFilter} from "./primary-filter";
import {PrimaryFilterController} from "./primary-filter.controller";

export {
  DownloadController, PrimaryFilter, PrimaryFilterController
}

export const CONTROLLERS = [
  DownloadController,
  PrimaryFilter,
  PrimaryFilterController
];
