import { NgModule, ErrorHandler, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouterModule, Route, Routes } from '@angular/router';

import { SlenderAuthModule, LoginComponent, AuthGuard } from '@slender/auth';

import { RootComponent, MainComponent } from './components';
import { CONTROLLERS, ErrorController, EventController } from './controllers';
import { SERVICES } from './services';

import { FEATURE_SERVICES, LoggingService } from './features';

export function SlenderBootModule(config: BootConfig)
{
  @NgModule({
    imports: [
      BrowserAnimationsModule,
      SlenderAuthModule,
      RouterModule.forRoot(rootRoutes(config), { useHash: true }),

      ...(config.importModules || []),
    ],
    declarations: [
      RootComponent,
      MainComponent
    ],
    providers: [
      {
        provide: 'BASE_URL',
        useFactory: () => document.getElementsByTagName('base')[0].href
      },
      {
        provide: ErrorHandler,
        useFactory: (eb: EventController, ls: LoggingService) => new ErrorController(eb, ls),
        deps: [
          EventController,
          LoggingService
        ]
      },
      CONTROLLERS,
      SERVICES,
      FEATURE_SERVICES
    ],
    bootstrap: [RootComponent]
  })
  class Module
  {
    constructor(@Optional() @SkipSelf() thisModule: Module)
    {
      if (thisModule) { throw new Error("SlenderBootModule is already loaded. Import at root only."); }
    }
  }

  return Module;
}

function rootRoutes(config: BootConfig)
{
  let rootPath: Route = {
    path: '',
    component: config.mainComponent,
    loadChildren: config.lazyModule ? () => config.lazyModule : undefined,
    children: config.appRoutes,
    canActivate: [AuthGuard]
  };

  return [
    rootPath,
    { path: 'pub/login', component: LoginComponent },
    { path: '**', redirectTo: '', pathMatch: 'full' }
  ]
}

type BootConfig = {
  importModules?: any[],
  lazyModule?: any,
  mainComponent?: any,
  appRoutes?: Routes
};
