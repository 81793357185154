import { Component, Input, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ErrorDetails } from './error-details';

@Component({
  selector: 'error-details',
  templateUrl: 'error-details.html',
  styleUrls: ['error-details.css'],
})
export class ErrorDetailsComponent
{
  @Input()
  public errorDetails: ErrorDetails;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ErrorDetails,
    @Optional() private _dialogRef: MatDialogRef<any>)
  {
    this.errorDetails = data;
  }

  public ngOnInit()
  {
  }
}
