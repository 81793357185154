export class UserConfig
{
  public state: UserState;
  public applets: { [key: string]: IAppletConfig };

  public readonly isLoaded: boolean;

  constructor(data: any)
  {
    if (!data)
    {
      throw new Error("Invalid configuration data to construct UserConfig");
    }

    this.state = new UserState(data.state);
    this.applets = data.applets || {};

    this.isLoaded = true;
  }
}

export class UserState
{
  public data: any;

  constructor(data: any)
  {
    this.data = data;
  }                          
}

export interface IAppletConfig 
{
  layout: any;
  state: any;
}
