import { Injectable } from '@angular/core'

import { AuthController } from '@slender/auth';

@Injectable()
export class ConfigService
{
  public systemData: any = {};
  public userData: any = {};

  private readonly _baseKey = "SlenderSystemConfig";
  private _userKey: string;

  constructor(private _authController: AuthController)
  {
    console.debug("ConfigService ctor");

    this.systemData = this.load(this._baseKey);
    _authController.verified.subscribe(session =>
    {
      this._userKey = `${this._baseKey}:${session.username}`;
      this.userData = this.load(this._userKey)
    });      
  }

  public load(key: string): any
  {
    let jsonData = localStorage.getItem(key);
    return jsonData ? JSON.parse(jsonData) : {};
  }

  public saveSystem()
  {
    localStorage.setItem(this._baseKey, JSON.stringify(this.systemData));
  }

  public saveUser()
  {
    localStorage.setItem(this._userKey, JSON.stringify(this.userData));
  }
}
