import {Injectable} from "@angular/core";
import {AuthController} from "@slender/auth";
import {Cookie} from "ng2-cookies";
import {BehaviorSubject, Observable} from "rxjs";
import {IFilter} from "../models/filter/filter.interface";
import {PrimaryFilter} from "./primary-filter";

@Injectable()
export class PrimaryFilterController {
  public filterObservable: Observable<IFilter>;

  private readonly FilterCookie = "PrimaryFilter";

  private _filterSource: BehaviorSubject<IFilter>;

  constructor(
    private _authController: AuthController
  ) {
    this._filterSource = new BehaviorSubject<IFilter>(this.getFilters());
    this.filterObservable = this._filterSource.asObservable();

     //this._authController.loggedOut.subscribe(() => {
     //  this.clearFilter();
     //});
  }

  public setFilter(filter: IFilter): void {
    let filterJson = JSON.stringify(filter);

    Cookie.set(this.FilterCookie, filterJson);
    this._filterSource.next(filter);
  }

  public getFilters(): IFilter {
    let filterCookie = Cookie.get(this.FilterCookie);

    if(filterCookie) {
      let filters = JSON.parse(filterCookie);

      return {
        dateRange: [
          new Date(filters.dateRange[0]),
          new Date(filters.dateRange[1])
        ],
        monthPicked: new Date(filters.monthPicked),
        textSearch: filters.textSearch
      };
    }

    return {
      dateRange: [
        new Date(new Date().setDate(new Date().getDate() - 1)),
        new Date(new Date().setDate(new Date().getDate() + 2))
      ],
      monthPicked: new Date(new Date().setDate(new Date().getDate() - 1)),
      textSearch: ""
    };
  }

  private clearFilter(): void {
    let filter = {
      dateRange: [
        new Date(new Date().setDate(new Date().getDate() - 1)),
        new Date(new Date().setDate(new Date().getDate() + 2))
      ],
      monthPicked: new Date(new Date().setDate(new Date().getDate() - 1)),
      textSearch: ""
    };

    this._filterSource.next(filter);

    Cookie.delete(this.FilterCookie);
  }
}
