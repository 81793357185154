import {IssueReasonDto} from "./issue-reason.dto";
import {IssueReasonInterface} from "./issue-reason.interface";
import { ClaimIssueReasonDto } from "../claim-issue-reason/claim-issue-reason.dto";
import { ClaimIssueReasonInterface } from "../claim-issue-reason/claim-issue-reason.interface";

export class IssueReasonModel {
  collectionArrival: IssueReasonInterface[];
  collectionDeparture: IssueReasonInterface[];
  deliveredWeight: IssueReasonInterface[];
  deliveryArrival: IssueReasonInterface[];
  deliveryDeparture: IssueReasonInterface[];
  dispatchedWeight: IssueReasonInterface[];
  distance: IssueReasonInterface[];
  tripReject: IssueReasonInterface[];
  claimIssue: ClaimIssueReasonInterface[];

  constructor(issueReasons?: IssueReasonDto[]) {
    this.collectionArrival = [];
    this.collectionDeparture = [];
    this.deliveredWeight = [];
    this.deliveryArrival = [];
    this.deliveryDeparture = [];
    this.dispatchedWeight = [];
    this.distance = [];
    this.tripReject = [];
    this.claimIssue = [];

    if(issueReasons) {
      this.generateIssues(issueReasons);
      this.generateClaimIssues(issueReasons);
    }
  }

  private createClaimIssueReason(id: number, issue?: string, reason?: string, isCloverClaimTransporter?: boolean): ClaimIssueReasonInterface {
    return {
      id: id,
      issue: issue ? issue : "",
      reason: reason ? [{ reason: reason }] : [{ reason: "" }],
      isCloverClaimTransporter: isCloverClaimTransporter ? isCloverClaimTransporter : false
    };
  }

  private createIssueReason(id: number, issue?: string, reason?: string): IssueReasonInterface {
    return {
      id: id,
      issue: issue ? issue : "",
      reason: reason ? [{reason: reason}] : [{reason: ""}]
    };
  }

  private generateIssues(issueReasons: IssueReasonDto[]): void {
    for(let issue of issueReasons) {
      if(issue.tripRejectReason) {
        this.tripReject.push(this.createIssueReason(issue.issueReasonId, "", issue.tripRejectReason)
        );
      }
      if(issue.distanceIssue) {
        let issueReason = this.distance.find(r => r.issue === issue.distanceIssue);

        if(issueReason && issue.distanceReason) {
          issueReason.reason.push({reason: issue.distanceReason});
        } else {
          this.distance.push(
            this.createIssueReason(issue.issueReasonId, issue.distanceIssue, issue.distanceReason)
          );
        }
      }
      if(issue.dispatchedWeightIssue) {
        let issueReason = this.dispatchedWeight.find(r => r.issue === issue.dispatchedWeightIssue);

        if(issueReason && issue.dispatchedWeightReason) {
          issueReason.reason.push({reason: issue.dispatchedWeightReason});
        } else {
          this.dispatchedWeight.push(
            this.createIssueReason(issue.issueReasonId, issue.dispatchedWeightIssue, issue.dispatchedWeightReason)
          );
        }
      }
      if(issue.deliveredWeightIssue) {
        let issueReason = this.deliveredWeight.find(r => r.issue === issue.deliveredWeightIssue);

        if(issueReason && issue.deliveredWeightReason) {
          issueReason.reason.push({reason: issue.deliveredWeightReason});
        } else {
          this.deliveredWeight.push(
            this.createIssueReason(issue.issueReasonId, issue.deliveredWeightIssue, issue.deliveredWeightReason)
          );
        }
      }
      if(issue.deliveryArrivalIssue) {
        let issueReason = this.deliveryArrival.find(r => r.issue === issue.deliveryArrivalIssue);

        if(issueReason && issue.deliveryArrivalReason) {
          issueReason.reason.push({reason: issue.deliveryArrivalReason});
        } else {
          this.deliveryArrival.push(
            this.createIssueReason(issue.issueReasonId, issue.deliveryArrivalIssue, issue.deliveryArrivalReason)
          );
        }
      }
      if(issue.deliveryDepartureIssue) {
        let issueReason = this.deliveryDeparture.find(r => r.issue === issue.deliveryDepartureIssue);

        if(issueReason && issue.deliveryDepartureReason) {
          issueReason.reason.push({reason: issue.deliveryDepartureReason});
        } else {
          this.deliveryDeparture.push(
            this.createIssueReason(issue.issueReasonId, issue.deliveryDepartureIssue, issue.deliveryDepartureReason)
          );
        }
      }
      if(issue.collectionArrivalIssue) {
        let issueReason = this.collectionArrival.find(r => r.issue === issue.collectionArrivalIssue);

        if(issueReason && issue.collectionArrivalReason) {
          issueReason.reason.push({reason: issue.collectionArrivalReason});
        } else {
          this.collectionArrival.push(
            this.createIssueReason(issue.issueReasonId, issue.collectionArrivalIssue, issue.collectionArrivalReason)
          );
        }
      }
      if(issue.collectionDepartureIssue) {
        let issueReason = this.collectionDeparture.find(r => r.issue === issue.collectionDepartureIssue);

        if(issueReason && issue.collectionDepartureReason) {
          issueReason.reason.push({reason: issue.collectionDepartureReason});
        } else {
          this.collectionDeparture.push(
            this.createIssueReason(issue.issueReasonId, issue.collectionDepartureIssue, issue.collectionDepartureReason)
          );
        }
      }
    }
  }

  private generateClaimIssues(claimIssueReasons: ClaimIssueReasonDto[]): void {
    for (let issue of claimIssueReasons) {

      if (issue.claimIssue) {
        let issueReason = this.claimIssue.find(r => r.issue === issue.claimIssue);

        if (issueReason && issue.claimIssue) {
          issueReason.reason.push({ reason: issue.claimReason! });
        } else {
          this.claimIssue.push(
            this.createClaimIssueReason(issue.issueReasonId, issue.claimIssue, issue.claimReason, issue.isCloverClaimTransporter)
          );
        }
      }
    }
  }
 
}
