import {Component, OnDestroy, OnInit} from "@angular/core";
import {AuthService} from "@slender/auth";
import {AppStatus, AsyncTaskController} from "@slender/foundation";
import {MessageService} from "primeng/api";
import {DialogService, DynamicDialogConfig} from "primeng/dynamicdialog";
import {Observable, Subject} from "rxjs";
import {switchMap, takeUntil} from "rxjs/operators";
import {PermissionConstants} from "../../constants/permission.constants";
import {PrimaryFilterController} from "../../controllers";
import {ClaimModel} from "../../models/claim.model";
import {ColumnDefinitionModel} from "../../models/table/column-definition.model";
import {ColumnFilterModel} from "../../models/table/column-filter.model";
import {TableStateModel} from "../../models/table/table-state.model";
import {ClaimService} from "../../services/claim.service";
import {TableService} from "../../services/table.service";

@Component({
  selector: "claims-management",
  templateUrl: "claims-management.html",
  styleUrls: ["claims-management.css"],
  providers: [DialogService, MessageService, DynamicDialogConfig]
})
export class ClaimsManagementComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean>;

  public _loading: boolean;
  public _readOnlyVar: boolean;
  public _cloverClaim: boolean;
  public _transporterClaim: boolean;

  // Main Table
  public _availableColumns: ColumnDefinitionModel[];
  public _dataKey: string;
  public _tableData: ClaimModel[];
  public _tableLoading: boolean;
  public _tableState: TableStateModel;

  private _taskId: string;

  constructor(
    private _authService: AuthService,
    private _asyncTaskController: AsyncTaskController,
    private _claimService: ClaimService,
    private _dialogService: DialogService,
    private _config: DynamicDialogConfig,
    private _filterController: PrimaryFilterController,
    private _messageService: MessageService,
    private _tableService: TableService
  ) {
    this._destroy$ = new Subject<boolean>();

    this._loading = true;
    this._tableLoading = true;

    this._readOnlyVar = _authService.hasPermission(PermissionConstants.readOnlyUser);
    this._cloverClaim = _authService.hasPermission(PermissionConstants.cloverClaim);
    this._transporterClaim = _authService.hasPermission(PermissionConstants.transporterClaim);

    this._tableData = [];
    this.setTable();
  }

  public ngOnInit(): void {
    this._asyncTaskController.updateStatus(new AppStatus("info", "Ready"));

    this.loadTableStates();

    this._filterController.filterObservable.pipe(
      takeUntil(this._destroy$),
      switchMap(filter =>
        this.loadTableData(filter.dateRange[0], filter.dateRange[1], filter.textSearch)
      )
    ).subscribe();

    //this.loadData(this._config.data.selectedRow);
  }

  //public onDataUpdate() {
  //  this.loadData(this._config.data.selectedRow);
  //}

  public onDataUpdate(row: string[]): void {

    let trCodes: string[];
    trCodes = row;
    if (!trCodes || trCodes.length === 0) {
      return;
    }

    this._taskId = this._asyncTaskController.startTask("Updating");

    this._claimService.getUpdatedClaims(
      this._filterController.getFilters().dateRange[0],
      this._filterController.getFilters().dateRange[1],
      trCodes
    ).subscribe(obs => {
      obs.forEach(model => {
        let index = this._tableData.findIndex(clm => clm.transportRequestCode === model.transportRequestCode);
        if (index >= 0) {
          this._tableData[index] = model;

          this._tableData = this._tableData.map(data => data);
        }

        this._asyncTaskController.completeTask(this._taskId);
      });
    }, error => {
      this._messageService.add({
        severity: "error",
        summary: "Updates",
        detail: "Failed to load updated claims."
      });

      this._asyncTaskController.completeTask(this._taskId);
    });
  } 

  public ngOnDestroy(): void {
    if(this._taskId) {
      this._asyncTaskController.completeTask(this._taskId);
    }

    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }

  public saveState(state: TableStateModel): void {
    this._tableService.SaveTableStates(state).subscribe(obs => {
      if(!obs) {
        this._messageService.add({
          severity: "warning",
          summary: "Table State",
          detail: "Failed to update table state."
        });
      }
    }, error => {
      this._messageService.add({severity: "warning", summary: "Table State", detail: "Failed to update table state."});
    });
  }

  private loadTableData(fromDate: Date, toDate: Date, filter: string): Observable<void> {
    this._tableLoading = true;

    this._taskId = this._asyncTaskController.startTask("Loading claims");

    this._claimService.getAllClaims(fromDate, toDate).subscribe(obs => {
      if(this._tableLoading) {
        this._tableData = obs;
      }

      this._tableLoading = false;

      this._asyncTaskController.completeTask(this._taskId);
    }, error => {
      this._tableLoading = false;

      this._messageService.add({
        severity: "error",
        summary: "Load Data",
        detail: "Failed to load claims data."
      });

      this._asyncTaskController.completeTask(this._taskId);
    });

    return new Observable<void>();
  }

  private loadTableStates(): void {
    this._tableService.GetTableStates(this._tableState.name).subscribe(obs => {
      if(!obs) {
        this._loading = false;
        return;
      }

      let state: TableStateModel = JSON.parse(obs);
      if(state && state.name === this._tableState.name) {
        this._tableState = state;
      }
      this._loading = false;
    }, error => {
      this._loading = false;
      this._messageService.add({
        severity: "warning",
        summary: "Table State",
        detail: "Failed to restore Transport Request table state."
      });
    });
  }

  private setTable(): void {
    this._availableColumns = [
      {field: "transportRequestCode", header: "Trip Code", width: "150px", filter: true},
      { field: "transportRequestStatus", header: "Trip Status", width: "150px", filter: true },
      { field: "claimStatus", header: "Claim", width: "150px", filter: true },
      { field: "clientName", header: "Client Name", width: "175px", filter: true },
      //{ field: "rejectReason", header: "Reject Reason", width: "175px", filter: true },
      { field: "tripClaim", header: "Claim ID", width: "150px", filter: true },
      //{field: "haulierCode", header: "Haulier Code", width: "150px", filter: true},
      {field: "haulierName", header: "Transporter Name", width: "175px", filter: true},
      { field: "origin", header: "Collection Location", width: "200px", filter: true },
      //{ field: "claimLodgedBy", header: "Claimer", width: "200px", filter: true},
      {field: "destination", header: "Delivery Location", width: "200px", filter: true},
      {
        field: "startDate",
        header: "Collection Date",
        width: "175px",
        filter: true,
        dataType: "date",
        filterConstraint: new ColumnFilterModel("date")
      }
    ];

    this._dataKey = "transportRequestId";
    this._tableState = {
      name: "claims-management-state"
    };
  }
}
