export class ShiftEntryModel {
  id: number;
  timeSlot: string;
  timeFrom: string;
  timeTo: string;

  constructor() {
    this.id = -1;
  }

  public static Copy(model: ShiftEntryModel): ShiftEntryModel {
    let shift = new ShiftEntryModel();

    shift.id = model.id;
    shift.timeSlot = model.timeSlot;
    shift.timeFrom = model.timeFrom;
    shift.timeTo = model.timeTo;

    return shift;
  }

  public validate(): boolean {
    if(!this.timeSlot) {
      return false;
    }
    if(!this.timeFrom) {
      return false;
    }
    if(!this.timeTo) {
      return false;
    }

    return true;
  }
}
