import { Component, Input, Host, ContentChild, AfterViewInit, Renderer2, ElementRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { CollapseBoxComponent } from './collapse-box.component';
import { Collapse_ICON_ANIMATION } from './collapse-box.animations';
import { CollapseHeaderContentDirective } from '../directives/collapse-header-content.directive';

@Component({
  selector: 'flx-collapse-header',
  templateUrl: 'collapse-box-header.html',
  styleUrls: ['collapse-box-header.css'],
  animations: [Collapse_ICON_ANIMATION],
  host: {
    'class': 'flx flx-collapse-header',
    '(click)': 'toggle()'
  },
  encapsulation: ViewEncapsulation.None
})
export class CollapseHeaderComponent implements AfterViewInit
{
  @ContentChild(CollapseHeaderContentDirective, { static: true, read: ElementRef }) headerContentElement: ElementRef;
  @ViewChild('rowIcon', { static: true }) rowIconElement: ElementRef;

  @Input()
  public headerContentOn: 'open' | 'close' | 'always' = 'close';

  constructor(
    @Host() private collapseBox: CollapseBoxComponent,
    private _renderer: Renderer2)
  {

  }

  ngAfterViewInit()
  {
    this.DisplayHeaderContent();
  }

  private DisplayHeaderContent()
  {
    if (this.headerContentElement && this.headerContentElement.nativeElement)
    {
      if (this.checkDisplayHeaderContent())
      {
        this._renderer.setStyle(this.headerContentElement.nativeElement, 'display', 'block');
      }
      else
      {
        this._renderer.setStyle(this.headerContentElement.nativeElement, 'display', 'none');
      }
    }
  }

  private checkDisplayHeaderContent()
  {
    let display = this.headerContentOn === 'always' ? true : false;

    if (!display)
    {
      let checkOpen = this.collapseBox.open && this.headerContentOn === 'open';
      let checkClose = this.collapseBox.close && this.headerContentOn === 'close';

      if (checkOpen)
      {
        display = true;
      }
      else if (checkClose)
        display = true;
      else
        display = false;

    }
    return display;
  }
  public toggle()
  {
    this.collapseBox.toggle();
    this.DisplayHeaderContent();
  }

  public stateChangeCompleted(event: any)
  {
    if (event && this.collapseBox.direction === 'row')
    {
      // this._renderer.setStyle(this.rowIconElement.nativeElement, 'transform-origin', '50% 1.8%');
    }

  }
}
