export class Session
{
  public username: string = "anonymous";
  public system: string = "";
  public companyKey: string = "";
  public fullName: string = "Anonymous Guest";
  public permissions: string;

  public get isAuthenticated()
  {
    return this.username !== "anonymous";
  }

  public static get anonymous()
  {
    return new Session();
  }
}
