import { trigger, animate, style, group, animateChild, query, stagger, transition, state } from '@angular/animations';

export const logoAnimation = trigger('logoAnim', [
  state('true', style({
    left: '13px',
    top: '3px',
    height: '44px'
  })),
  transition('*=>true', [animate('500ms ease')])
]);

export const headerAnimation = trigger('headerAnim', [
  state('true', style({
    backgroundColor: '#1600ba'
  })),
  transition('*=>true', [animate('500ms ease')])
]);

export const canvasAnimation = trigger('canvasAnim', [
  state('true', style({
    opacity: 0
    //transform: 'perspective(1000px) translateZ(-100px)'
  })),
  transition('*=>true', [animate('500ms ease-out')])
]);

export class CloudAnim 
{
  public canvas: HTMLCanvasElement;
  public logo: HTMLImageElement;

  public background: string;
  public colors: string[];

  public c: CanvasRenderingContext2D;
  public t: number;
  public particles: Particle[];
  public handle: number;

  constructor()
  {
    this.canvas = document.querySelector('canvas')!;
    this.logo = document.querySelector('img')!;

    this.background = '#052850';
    this.colors = ["#1600ba", "#fa6900", "#a7dbd8", "#e0e4cc", "#ff5b00"];

    this.c = this.canvas.getContext('2d')!;
    this.t = 0;

    addEventListener('resize', () =>
    {
      this.init();
    });
  }

  public init()
  {
    this.t = 0;
    this.particles = [];

    this.canvas.width = innerWidth;
    this.canvas.height = innerHeight;

    this.logo.style.right = `${(innerWidth / 2) - 15}px`;
    this.logo.style.bottom = `${(innerHeight / 2) - 55}px`;

    for (let i = 0; i < 250; i++)
    {
      const radius = (Math.random() * 7) + 2;
      this.particles.push(new Particle(
        this.c,
        this.canvas.width / 2,
        this.canvas.height / 2,
        radius,
        this.randomColorFrom(this.colors)));
    }

    cancelAnimationFrame(this.handle);
    this.animate();
  }

  public animate()
  {
    this.handle = requestAnimationFrame(() => this.animate());
    this.c.fillStyle = "#05285050";
    this.c.fillRect(0, 0, this.canvas.width, this.canvas.height);

    this.particles.forEach(particle =>
    {
      particle.update(this.t);
    });

    this.t++;
  }

  public stop()
  {
    cancelAnimationFrame(this.handle);
  }

  public randomIntFromRange(min: number, max: number)
  {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public randomColorFrom(colors: string[])
  {
    return colors[Math.floor(Math.random() * colors.length)];
  }
}

export class Particle
{
  public x: number;
  public y: number;
  public radius: { real: number; display: number; };
  public color: string;
  public radians: number;
  public orbit: any;
  public fling: any;
  public center: { x: number; y: number; };
  public velocity: number;
  public c: CanvasRenderingContext2D;

  constructor(c: CanvasRenderingContext2D,
    x: number, y: number, radius: number, color: string)
  {
    this.c = c;
    this.x = x;
    this.y = y;
    this.radius = {
      real: radius,
      display: 0
    };
    this.color = color;
    this.radians = Math.random() * Math.PI * 2;
    this.orbit = this.randomFromRange(0.001, 0.05);
    this.fling = this.randomFromRange(0.01, 0.3);

    this.center = { x: x, y: y };
  }

  public update(t: number) 
  {
    let lastPoint = { x: this.x, y: this.y };

    this.radius.display = this.radius.real * ((this.y - 200) / 300);
    this.orbit += this.fling;
    this.velocity = (1 / this.orbit) + ((this.y - 200) / 100000);
    this.radians += this.velocity;
    if (this.radians > Math.PI * 2)
    {
      this.radians = 0;
    }

    if (t == 100)
    {
      this.fling *= 300;
    }
    else if (t > 100 && this.fling > 0.01)
    {
      this.fling /= 1.07;
    }

    this.x = this.center.x + (this.orbit * Math.sin(this.radians));
    this.y = this.center.y + ((this.orbit / 5) * Math.cos(this.radians));

    this.draw();
  }

  public draw()
  {
    this.c.beginPath();
    this.c.arc(this.x, this.y, Math.max(this.radius.display, 0.1), 0, Math.PI * 2, false);
    this.c.fillStyle = this.color;
    this.c.fill();
    this.c.closePath();
  }

  public randomFromRange(min: number, max: number)
  {
    return Math.random() * (max - min) + min;
  }
}
