import {Component, OnInit} from "@angular/core";
import {Message} from "primeng/api";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {TransportRequestVehicleModel} from "../../models/transport-request-vehicle.model";
import {TransportRequestService} from "../../services/transport-request.service";

@Component({
  templateUrl: "vehicle-registration.html",
  styleUrls: ["vehicle-registration.css"]
})
export class VehicleRegistrationDialog implements OnInit {
  // Dialog Inputs
  private _transportRequestId: number;
  private _vehicleRegistration: string;

  public _availableVehicles: TransportRequestVehicleModel[];
  public _messages: Message[];
  public _loading: boolean;
  public _selectedVehicle: TransportRequestVehicleModel;

  constructor(
    private _config: DynamicDialogConfig,
    private _tripService: TransportRequestService,
    private _ref: DynamicDialogRef
  ) {
    this._availableVehicles = [];
    this._messages = [];
    this._loading = true;
  }

  public ngOnInit(): void {
    if(!this._config.data.transportRequestId) {
      return;
    }

    this._transportRequestId = this._config.data.transportRequestId;
    this._vehicleRegistration = this._config.data.vehicleRegistration;

    this.loadData();
  }

  public cancel(): void {
    this._ref.close();
  }

  public saveData(): void {
    if(!this._selectedVehicle) {
      this._messages.push({
        severity: "error",
        detail: "No vehicle selected"
      });

      return;
    }

    this._ref.close(this._selectedVehicle.vehicle);
  }

  private loadData(): void {
    this._tripService.getHaulierVehicles(this._transportRequestId).subscribe(obs => {
      if(!obs) {
        this._loading = false;
        return;
      }

      this._availableVehicles = obs;

      let idx = this._availableVehicles.findIndex(v => v.vehicle === this._vehicleRegistration);
      if(idx >= 0) {
        this._selectedVehicle = this._availableVehicles[idx];
      }

      this._loading = false;
    }, error => {
      this._messages.push({
        severity: "error",
        detail: "Failed to load vehicles"
      });

      this._loading = false;
    });
  }
}
