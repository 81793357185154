import {Component, OnDestroy, OnInit} from "@angular/core";
import {AuthService} from "@slender/auth";
import {AppStatus, AsyncTaskController} from "@slender/foundation";
import {MessageService} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {Observable, Subject} from "rxjs";
import {switchMap, takeUntil} from "rxjs/operators";
import {PermissionConstants} from "../../constants/permission.constants";
import {PrimaryFilterController} from "../../controllers";
import {ColumnDefinitionModel} from "../../models/table/column-definition.model";
import {ColumnFilterModel} from "../../models/table/column-filter.model";
import {TableStateModel} from "../../models/table/table-state.model";
import {TransportRequestUpdateModel} from "../../models/transport-request-update.model";
import {TransportRequestModel} from "../../models/transport-request.model";
import {TableService} from "../../services/table.service";
import {TransportRequestService} from "../../services/transport-request.service";
import {ShipmentManagementDialog} from "../shipment-management/shipment-management.dialog";

@Component({
  selector: "trip-management",
  templateUrl: "trip-management.html",
  styleUrls: ["trip-management.css"],
  providers: [DialogService, MessageService]
})
export class TripManagementComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean>;

  public _financeVar: boolean;
  public _loading: boolean;
  public _readOnlyVar: boolean;

  // Main Table
  public _availableColumns: ColumnDefinitionModel[];
  public _dataKey: string;
  public _tableData: TransportRequestModel[];
  public _tableLoading: boolean;
  public _tableStateMain: TableStateModel;

  // Sub Table
  public _tableStateSub: TableStateModel;

  private _task: string;

  constructor(
    private _authService: AuthService,
    private _asyncTaskController: AsyncTaskController,
    private _dialogService: DialogService,
    private _filterController: PrimaryFilterController,
    private _messageService: MessageService,
    private _tableService: TableService,
    private _tripService: TransportRequestService
  ) {
    this._destroy$ = new Subject<boolean>();

    this._loading = true;
    this._tableLoading = true;

    this._financeVar = _authService.hasPermission(PermissionConstants.financeUser);
    this._readOnlyVar = _authService.hasPermission(PermissionConstants.readOnlyUser);

    this._tableData = [];

    this.setTable();
  }

  public ngOnInit(): void {
    this._asyncTaskController.updateStatus(new AppStatus("info", "Ready"));

    this.loadTableStates();

    this._filterController.filterObservable.pipe(
      takeUntil(this._destroy$),
      switchMap(filter =>
        this.loadTableData(filter.dateRange[0], filter.dateRange[1], filter.textSearch)
      )
    ).subscribe();
  }

  public ngOnDestroy(): void {
    if(this._task) {
      this._asyncTaskController.completeTask(this._task);
    }

    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }

  public onCellUpdate(field: string, row: any): void {
    switch(field) {
      case "customComment4":
        this.saveTripComments(row);
        break;

      case "customComment6":
      case "customComment7":
      case "customComment9":
      case "plannedVehicleRegistration":
        this.saveTripRow(row);
        break;

      default:
    }
  }

  public onDataUpdate(trCodes: string[]): void {
    if(!trCodes || trCodes.length === 0) {
      return;
    }

    this._task = this._asyncTaskController.startTask("Updating");

    this._tripService.getUpdatedTransportRequests(
      this._filterController.getFilters().dateRange[0],
      this._filterController.getFilters().dateRange[1],
      trCodes
    ).subscribe(obs => {
      obs.forEach(model => {
        let index = this._tableData.findIndex(tr => tr.code === model.code);
        if(index >= 0) {
          this._tableData[index] = model;

          this._tableData = this._tableData.map(data => data);
        }

        this._asyncTaskController.completeTask(this._task);
      });
    }, error => {
      this._messageService.add({
        severity: "error",
        summary: "Updates",
        detail: "Failed to load updated transport requests."
      });

      this._asyncTaskController.completeTask(this._task);
    });
  }

  public displayShipments(row: TransportRequestModel): void {
    if(row.transportRequestId === undefined || row.transportRequestId == null) {
      this._messageService.add({
        severity: "error",
        summary: "Shipment Data",
        detail: "No shipment data loaded"
      });
      return;
    }

    const ref = this._dialogService.open(ShipmentManagementDialog, {
      data: {
        selectedRow: row
      },
      dismissableMask: true,
      header: `${row.code} Shipments`,
      height: "70%",
      width: "90%"
    });

    ref.onClose.subscribe(() => {
      this.onDataUpdate([`${row.transportRequestId}`]);
    }, error => {
      this._messageService.add({
        severity: "error",
        summary: "Trip Management",
        detail: "Failed to update trip"
      });
    });
  }

  public saveState(state: TableStateModel): void {
    this._tableService.SaveTableStates(state).subscribe(obs => {
      if(!obs) {
        this._messageService.add({
          severity: "warning",
          summary: "Table State",
          detail: "Failed to update table state."
        });
      }
    }, error => {
      this._messageService.add({severity: "warning", summary: "Table State", detail: "Failed to update table state."});
    });
  }

  private loadTableData(fromDate: Date, toDate: Date, filter: string): Observable<void> {
    this._tableLoading = true;

    this._task = this._asyncTaskController.startTask("Loading requests");

    this._tripService.getTransportRequests(fromDate, toDate, filter).subscribe(obs => {
      if(this._tableLoading) {
        this._tableData = obs;
      }

      this._tableLoading = false;

      this._asyncTaskController.completeTask(this._task);
    }, error => {
      this._tableLoading = false;

      this._messageService.add({
        severity: "error",
        summary: "Load Data",
        detail: "Failed to load transport requests."
      });

      this._asyncTaskController.completeTask(this._task);
    });

    return new Observable<void>();
  }

  private loadTableStates(): void {
    this._tableService.GetTableStates(this._tableStateMain.name).subscribe(obs => {
      if(!obs) {
        this._loading = false;
        return;
      }

      let state: TableStateModel = JSON.parse(obs);
      if(state && state.name === this._tableStateMain.name) {
        this._tableStateMain = state;
      }
      this._loading = false;
    }, error => {
      this._loading = false;
      this._messageService.add({
        severity: "warning",
        summary: "Table State",
        detail: "Failed to restore Transport Request table state."
      });
    });
  }

  private saveTripComments(row: TransportRequestModel): void {
    if(!row.transportRequestId) {
      return;
    }

    this._tripService.updateTransportRequestComments(row.transportRequestId, row.customComment4).subscribe(obs => {
      if(!obs) {
        this._messageService.add({
          severity: "warning",
          summary: "Update Comment",
          detail: "Trip comments failed to update."
        });
        return;
      }

      if(!obs.isError) {
        this._messageService.add({severity: "success", summary: "Update Comment", detail: obs.message});
        this.onDataUpdate([obs.code]);
      } else {
        this._messageService.add({severity: "error", summary: "Update Comment", detail: obs.message});
      }
    }, error => {
      this._messageService.add({
        severity: "error",
        summary: "Update Comment",
        detail: `Failed to update trip comments: ${error.message}.`
      });
    });
  }

  private saveTripRow(row: TransportRequestModel): void {
    if(!row.transportRequestId) {
      return;
    }

    let toUpdate: TransportRequestUpdateModel = {
      requestId: row.transportRequestId,
      vehicleRegistration: row.customComment6,
      driverName: row.customComment7,
      transporterReference: row.customComment9,
      plannedVehicleRegistration: row.plannedVehicleRegistration
    };

    this._tripService.updateTransportRequest(toUpdate).subscribe(obs => {
      if(!obs) {
        this._messageService.add({
          severity: "warning",
          summary: "Request Update",
          detail: "Transport request failed to update."
        });
        return;
      }

      if(!obs.isError) {
        this._messageService.add({severity: "success", summary: "Request Update", detail: obs.message});
        this.onDataUpdate([obs.code]);
      } else {
        this._messageService.add({severity: "error", summary: "Request Update", detail: obs.message});
      }
    }, error => {
      this._messageService.add({
        severity: "error",
        summary: "Request Update",
        detail: `Failed to update transport request: ${error.message}.`
      });
    });
  }

  private setTable(): void {
    this._availableColumns = [
      //{field: "claimStatus", header: "Claim Status", width: "150px", filter: true, visible: false},
      {
        field: "physicalStatusName",
        header: "Trip Status",
        width: "250px",
        filter: true
      },
      // {field: "codeClient", header: "Client Code", width: "140px", filter: true},
      {field: "code", header: "Trip Code", width: "130px", filter: true},
      {field: "srReference", header: "Reference Number", width: "350px", filter: true},
      // {field: "deliveryWindows", header: "Delivery Window", width: "180px", filter: true},
      { field: "ppcNotes", header: "Clover Note", width: "180px", filter: true },
      { field: "chepNumber", header: "Chep Document Number", width: "250px", filter: true },
      { field: "invoiceNumber", header: "Invoice Captured", width: "180px", filter: true },
      { field: "invoiceSubmitted", header: "Invoice Submitted", width: "180px", filter: true },
      {field: "poNumber", header: "PO Order Number", width: "160px", filter: true},
      {
        field: "startDate",
        header: "Collection Date",
        width: "160px",
        filter: true,
        dataType: "date",
        filterConstraint: new ColumnFilterModel("date")
      },
      {
        field: "deliveryArrival",
        header: "Delivery Date",
        width: "160px",
        filter: true,
        dataType: "date",
        filterConstraint: new ColumnFilterModel("date")
      },
      // {field: "originCode", header: "Origin Code", width: "100px", filter: true},
      {field: "originName", header: "Collection Location", width: "120px", filter: true},
      {field: "originAreaGroupName", header: "Collection Area", width: "100px", filter: true},
      // {field: "terminusCode", header: "Terminus Code", width: "150px", filter: true},
      {field: "terminusName", header: "Delivery Location", width: "160px", filter: true},
      {field: "terminusAreaGroupName", header: "Delivery Area", width: "160px", filter: true},
      {field: "haulierName", header: "Transporter Name", width: "140px", filter: true},
      // {
      //   field: "createdOn",
      //   header: "Created On",
      //   width: "120px",
      //   filter: true,
      //   dataType: "date",
      //   filterConstraint: new ColumnFilterModel("date")
      // },
      {field: "vehicleName", header: "Planned Vehicle", width: "130px", filter: true},
      {field: "vehicleClassName", header: "Vehicle Class", width: "130px", filter: true},
      {field: "tariffCode", header: "Tariff Code", width: "110px", filter: true},
      {field: "tariff", header: "Tariff Name", width: "110px", filter: true},
      {field: "plannedTariffAmount", header: "Tariff Amount", width: "180px", filter: false, dataType: "number"},
      // {field: "tariffNote", header: "Tariff Note", width: "200px", filter: true},
      // {field: "delNumber", header: "Del Number", width: "200px", filter: true},
      // {field: "plannedDistance", header: "Planned Distance", width: "180px", filter: false, dataType: "number"},
      {field: "customComment4", header: "Trip Comments", width: "160px", filter: true, editable: true},
      {
        field: "actualKms",
        header: "Actual KMs",
        width: "100px",
        filter: true,
        dataType: "number"
      },
      {field: "totalWeightPlanned", header: "Planned Trip Weight", width: "180px", filter: true, dataType: "number"},
      {
        field: "plannedVehicleRegistration",
        header: "Confirmed Vehicle",
        width: "160px",
        filter: true,
        editable: true,
        filterMaxLength: 10
      },
      {field: "customComment6", header: "Dispatched Vehicle", width: "160px", filter: true},
      {field: "customComment7", header: "Driver Name", width: "130px", filter: true, editable: true},
      // {field: "customComment9", header: "Transporter Reference", width: "200px", filter: true, editable: true},
      {field: "totalQtyPlanned", header: "Planned Trip Quantity", width: "100px", filter: true, dataType: "number"},
      {field: "tripComment", header: "Clover Trip Comments", width: "200px", filter: true}
    ];

    if(!this._authService.hasPermission(PermissionConstants.haulier)) {
      this._availableColumns.push({
        field: "poAmount",
        header: "PO Amount",
        width: "120px",
        filter: true,
        dataType: "number",
        filterConstraint: new ColumnFilterModel("number"),
        filterMaxLength: 2
      });
    }

    this._dataKey = "transportRequestId";
    this._tableStateMain = {
      name: "trip-management-state"
    };

    this._tableStateSub = {
      name: "shipment-management-state"
    };
  }
}
