import { Directive, ElementRef} from '@angular/core';

@Directive({
  selector: 'flx-collapse-header-content',
  host: {
    'class': 'flx-collapse-header-content'
  }
})
export class CollapseHeaderContentDirective
{
  constructor()
  {

  }
}