import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable()
export class HomeService {
  constructor(private _http: HttpClient) {
  }

  public GetHomeData(): Observable<any> {
    return this._http.get("/api/home");
  }

  public SaveHomeData(data: Object): Observable<Object> {
    let obj = {
      "data": JSON.stringify(data)
    };
    return this._http.post("/api/home", obj);
  }
}
