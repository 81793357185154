import { HttpClient, HttpEvent, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FileLocationModel } from "../models/file-data/file-location.model";

@Injectable()
export class FileService {
  constructor(private _http: HttpClient) {
  }

  public downloadFile(fileId: number): Observable<HttpEvent<Blob>> {
    let path = `/api/file/${fileId}`;

    return this._http.request(new HttpRequest(
      'GET',
      path,
      null,
      {
        responseType: 'blob'
      }
    ));
  }

  public getFiles(fileId: number, downType: string, shipment: boolean): Observable<FileLocationModel[]> {
    let path = `/api/file/${fileId}/${downType}/files`;

    let params = new HttpParams()
      .set("shipment", JSON.stringify(shipment))

    return this._http.get<FileLocationModel[]>(path, {params: params});
  }

  public totalFiles(shipmentId: number): Observable<number> {
    let path = `/api/file/${shipmentId}/files`;
    return this._http.get<number>(path);
  }


  public getDiversionReport(originSo: any, diversionSo: any): Observable<any> {
    let path = `/api/dw/${originSo}/${diversionSo}/DiversionReport`;
    return this._http.get(path, {responseType: "arraybuffer"});
  }

  public getReport(reportName: string, reportType: string, fromDate: Date, toDate: Date): Observable<any> {
    let path = `/api/dw/${reportName}/${reportType}/${fromDate}/${toDate}/Reports`;
    return this._http.get(path);
  }
}
