import {Component, EventEmitter, Output} from '@angular/core';

import {AuthService} from '../../services';

@Component({
  selector: 'slender-admin-panel',
  templateUrl: 'admin-panel.html',
  styleUrls: ['admin-panel.css'],
})
export class AdminPanelComponent
{
  @Output()
  public softReload = new EventEmitter<{ value: string }>();

  public userFullName: string;

  constructor(private _authService: AuthService) { }

  public ngOnInit()
  {
    this.userFullName = this._authService.currentSession.fullName;
    if(!this.userFullName || this.userFullName.length === 0 || this.userFullName === " ") {
      this.userFullName = this._authService.currentSession.username.replace("_", " ");
    }
  }

  private onAccount() { }

  private onSettings() { }

  private onSoftReload(e: any)
  {
    this.softReload.emit(e);
  }

  private onLogout()
  {
    this._authService.logout();
  }
}
