import { Component, Input, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, scan, tap } from 'rxjs/operators';

import { LogItem, LogFilterModel, LogFormatModel } from '../infrastructure';
import { LoggingService } from '../services/logging.service';

@Component({
  selector: 'slender-logs-view-live',
  templateUrl: 'logs-view-live.html',
  styleUrls: ['logs-view-live.css']
})
export class LogsViewLiveComponent
{
  @Input()
  public filter: LogFilterModel | null;

  @Input()
  public bufferMax = 100;

  @Input()
  public liveScroll = true;

  @Input()
  public format = new LogFormatModel();

  @ViewChild('liveLogsContainer', { static: true })
  public liveLogsContainer: ElementRef;

  public liveLogs$: Observable<LogItem[]>;

  constructor(private _loggingService: LoggingService) { }

  public ngOnInit()
  {
    //if (this.filter)
    //{
    //  let history = this._loggingService.getLogs(this.filter);
    //}
    this.liveLogs$ = this._loggingService.broadcaster
      .pipe(
        filter(log => log.isMatch(this.filter)),
        scan<LogItem, LogItem[]>((logs, log, i) =>
        {
          logs.push(log);
          if (logs.length > this.bufferMax)
          {
            logs.shift();
          }

          return logs;
        }, []),
        tap(() => this.autoScroll(this.liveLogsContainer.nativeElement)));

    this._loggingService.connect();
  }

  private autoScroll(el: HTMLElement)
  {
    if (this.liveScroll)
    {
      //let offset = el.scrollHeight - el.clientHeight - el.scrollTop;
      setTimeout(() =>
      {
        let scrollTop = el.scrollHeight - el.clientHeight;
        el.scrollTo({ top: scrollTop, behavior: 'smooth' });
      });
    }
  }
}
