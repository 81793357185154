import {TRPhysicalStatus} from "./enums";

export class TransportRequestModel {
  code: string;
  createdOn?: Date;
  financialStatusNameId?: number;
  finishDate?: Date;
  haulierId: number;
  originId?: number;
  physicalStatusNameId?: TRPhysicalStatus;
  reference: string;
  startDate?: Date;
  tariffId?: number;
  terminusId?: number;
  transportRequestId?: number;
  actTripId?: number;
  vehicleClassId?: number;
  vehicleId?: number;
  trLaneId?: number;
  spotRateAmount?: number;
  plannedTariffAmount?: number;
  userName: string;
  loginUser: string;
  physicalStatusName: string;
  financialStatusName: string;
  haulierName: string;
  tariffReference: string;

  originCode: string;
  originName: string;

  originSiteInfo1: string;
  originSiteInfo2: string;
  originSiteInfo3: string;

  terminusCode: string;
  terminusName: string;

  vehicleName: string;
  vehicleClassName: string;
  tariffCode: string;
  tariff: string;
  haulierCode: string;

  laneId?: number;

  totalQtyPlanned?: number;
  totalWeightPlanned?: number;
  totalQtyActual?: number;
  totalWeightActual?: number;
  qtyUtil?: number;
  weightUtil?: number;

  tripGroup: string;
  originAreaGroup: string;
  terminusAreaGroup: string;

  totalVolumePlanned?: number;
  totalUnit1Planned?: number;
  totalUnit2Planned?: number;
  totalUnit3Planned?: number;
  totalVolumeActual?: number;
  totalUnit1Actual?: number;
  totalUnit2Actual?: number;
  totalUnit3Actual?: number;

  clientId?: number;
  clientName: string;
  clientCode: string;
  arTariffId?: number;
  plannedArTariffAmount?: number;

  actualKms?: number;

  additionalInformation: string;

  plannedCollectDate?: Date;
  plannedDeliveryDate?: Date;
  actualCollectDate?: Date;
  actualDeliveryDate?: Date;

  customComment1: string;
  customComment2: string;
  customComment3: string;
  customComment4: string;
  customComment5: string;
  customComment6: string;
  customComment7: string;
  customComment8: string;
  customComment9: string;
  customComment10: string;
  customComment11: string;
  customComment12: string;
  customComment13: string;
  customComment14: string;
  customComment15: string;
  customComment16: string;
  customComment17: string;
  customComment18: string;

  originCityTown: string;
  terminusCityTown: string;
  originAreaGroupName: string;
  terminusAreaGroupName: string;

  poNumber: string;
  poAmount?: number;
  tariffAmount?: number;
  tariffNote: string;
  deliveryArrival?: Date;
  soNumber: string;
  delNumber: string;
  codeClient: string;
  ppcNotes: string;
  deliveryWindows: string;
  plannedDistance?: number;
  plannedVehicleRegistration: string;

  srReference: string;

  claimStatus: string;

  plannedQuantity: number;

  customFlag2: string;
  tripComment?: string;

  invoiceNumber?: string;
  invoiceSubmitted?: string;

  constructor() {
  }
}
