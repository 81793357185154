import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {ContactModel} from "../../../models/home/contact.model";
import {DriverDetailModel} from "../../../models/home/driver-details.model";
import {DriverRouteModel} from "../../../models/home/driver-route.model";

@Component({
  selector: "driver-detail",
  templateUrl: "driver-detail.html",
  styleUrls: ["driver-detail.css"]
})
export class DriverDetailComponent implements OnInit, OnChanges {
  @Input() drivers: DriverDetailModel[];
  @Input() superUser: boolean;
  @Output() update: EventEmitter<DriverDetailModel[]>;

  public _drivers: DriverDetailModel[];
  public _edit: boolean;

  constructor() {
    this._drivers = [];
    this._edit = false;

    this.update = new EventEmitter<DriverDetailModel[]>();
  }

  public ngOnInit(): void {
    if(this.drivers) {
      this._drivers = [];

      this.drivers.forEach(driver => {
        this._drivers.push(DriverDetailModel.Copy(driver));
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.drivers && changes.drivers.currentValue) {
      this._drivers = [];

      let drivers: DriverDetailModel[] = changes.drivers.currentValue;
      drivers.forEach(driver => {
        this._drivers.push(DriverDetailModel.Copy(driver));
      });
    }
  }

  public addDriver(): void {
    let id = 0;
    if(this._drivers.length > 0) {
      id = this._drivers[this._drivers.length - 1].contact.id + 1;
    }

    let driver = new DriverDetailModel();
    driver.contact.id = id;

    this._drivers.push(driver);
  }

  public deleteDriver(contactId: number): void {
    let idx = this._drivers.findIndex(d => d.contact.id === contactId);
    this._drivers.splice(idx, 1);
  }

  public updateDriver(contact: ContactModel): void {
    let idx = this._drivers.findIndex(d => d.contact.id === contact.id);
    this._drivers[idx].contact = contact;
  }

  public updateRoutes(driverId: number, routes: DriverRouteModel[]): void {
    let driverIdx = this._drivers.findIndex(d => d.contact.id === driverId);
    if(driverIdx === -1) {
      return;
    }

    this._drivers[driverIdx].routes = [];

    routes.forEach(route => {
      this._drivers[driverIdx].routes.push(DriverRouteModel.Copy(route));
    });
  }

  public editData(): void {
    this._edit = true;
  }

  public saveData(): void {
    let drivers: DriverDetailModel[] = [];

    this._drivers.forEach(driver => {
      let contact = ContactModel.Copy(driver.contact);
      if(contact.validate()) {
        let routes: DriverRouteModel[] = [];
        driver.routes.forEach(route => {
          let model = DriverRouteModel.Copy(route);
          if(model.validate()) {
            routes.push(model);
          }
        });

        let detail = new DriverDetailModel();
        detail.contact = contact;
        detail.routes = routes;

        drivers.push(detail);
      }
    });

    this.update.emit(drivers);
    this._edit = false;
  }

  public cancelEdit(): void {
    this._edit = false;

    if(!this.drivers) {
      return;
    }

    this._drivers = [];
    this.drivers.forEach(driver => {
      this._drivers.push(DriverDetailModel.Copy(driver));
    });
  }
}
