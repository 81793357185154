import {HaulierModel} from "./haulier.model";

export class UserHaulierModel {
  userId: number;
  loginName: string;
  hauliers: HaulierModel[];

  constructor() {
    this.hauliers = [];
  }

  public static Copy(model: UserHaulierModel): UserHaulierModel {
    let userHaulier = new UserHaulierModel();

    userHaulier.userId = model.userId;
    userHaulier.loginName = model.loginName;
    userHaulier.hauliers = model.hauliers.map(h => h);

    return userHaulier;
  }
}
