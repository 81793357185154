import { LogFilterModel } from './log-filter-model';

export class LogItem
{
  constructor(
    public timestamp: string,
    public type: string,
    public user: string,
    public source: string,
    public message: string,
    public error: string)
  {
    if (type == 'error' && !error)
    {
      this.error = message;
      this.message = message.slice(0, 100) + "...";
    }
  }

  public isMatch(filter: LogFilterModel | null): boolean
  {
    if (!filter) { return true; }

    let time = new Date(this.timestamp);
    return (
      !!filter.type && filter.type.includes(this.type) &&
      (!filter.startTime || time >= filter.startTime) &&
      (!filter.endTime || time <= filter.endTime) &&
      (!filter.user || this.user.includes(filter.user)) &&
      (!filter.source || this.source.includes(filter.source)) &&
      (!filter.message || this.message.includes(filter.message) ||
        this.error.includes(filter.message)));
  }
}
