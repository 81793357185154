import { Injectable } from '@angular/core'
import { Broadcaster } from '@slender/core';
import { addSeconds } from 'date-fns';

@Injectable()
export class TimeController
{
  public tick = new Broadcaster<Date>(true);

  public get now(): Date { return new Date(this._now); }
  private _now: Date;

  private _mode: 'real-time' | 'mock' = 'real-time';
  private _mockRate: number;
  private _timer: any;

  constructor()
  {
    this.setTickInterval();
  }

  public mock(time: Date, rate: number, interval: number)
  {
    this._mode = 'mock';
    this._now = time;
    this._mockRate = rate;
    this.setTickInterval(interval);
  }

  public clearMock()
  {
    this._mode = 'real-time';
    this.setTickInterval();
  }

  private setTickInterval(interval: number = 1000)
  {
    if (this._timer)
    {
      clearInterval(this._timer);
    }

    this._timer = setInterval(() =>
    {
      if (this._mode == 'real-time')
      {
        this._now = new Date();
      }
      else
      {
        this._now = addSeconds(this._now, this._mockRate);
      }
      this.tick.broadcast(this._now);
    }, interval);
  }
}
