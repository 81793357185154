import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {ContactModel} from "../../../models/home/contact.model";

@Component({
  selector: "contact-group",
  templateUrl: "contact-group.html",
  styleUrls: ["contact-group.css"]
})
export class ContactGroupComponent implements OnInit, OnChanges {
  @Input() contacts: ContactModel[];
  @Input() superUser: boolean;
  @Output() update: EventEmitter<ContactModel[]>;

  public _contacts: ContactModel[];
  public _edit: boolean;

  constructor() {
    this._contacts = [];
    this._edit = false;

    this.update = new EventEmitter<ContactModel[]>();
  }

  public ngOnInit(): void {
    if(this.contacts) {
      this._contacts = [];

      this.contacts.forEach(contact => {
        this._contacts.push(ContactModel.Copy(contact));
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.contacts && changes.contacts.currentValue) {
      this._contacts = [];

      let contacts: ContactModel[] = changes.contacts.currentValue;
      contacts.forEach(contact => {
        this._contacts.push(ContactModel.Copy(contact));
      });
    }
  }

  public deleteContact(contactId: number): void {
    let idx = this._contacts.findIndex(c => c.id === contactId);
    this._contacts.splice(idx, 1);
  }

  public updateContact(contact: ContactModel): void {
    let idx = this._contacts.findIndex(c => c.id === contact.id);
    this._contacts[idx] = contact;
  }

  public editData(): void {
    this._edit = true;
  }

  public addData(): void {
    let id = 0;
    if(this._contacts.length > 0) {
      id = this._contacts[this._contacts.length - 1].id + 1;
    }

    let contact = new ContactModel();
    contact.id = id;

    this._contacts.push(contact);
  }

  public saveData(): void {
    let contacts: ContactModel[] = [];

    this._contacts.forEach(contact => {
      let model = ContactModel.Copy(contact);

      if(model.validate()) {
        contacts.push(model);
      }
    });

    this.update.emit(contacts);
    this._edit = false;
  }

  public cancelEdit(): void {
    this._edit = false;

    if(!this.contacts) {
      return;
    }

    this._contacts = [];
    this.contacts.forEach(contact => {
      this._contacts.push(ContactModel.Copy(contact));
    });
  }
}
