import { Component, OnInit } from "@angular/core";
import { Message } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { IssueReasonModel } from "../../models/issue-reason/issue-reason.model";
import { ReasonInterface } from "../../models/issue-reason/reason.interface";
import { DialogResponseModel } from "../../models/response-status/dialog-response.model";
import { BriefService } from "../../services/brief.service";
import { TransportRequestService } from "../../services/transport-request.service";
import { ClaimService } from "../../services/claim.service";

@Component({
  templateUrl: "reject-claim.html",
  styleUrls: ["reject-claim.css"]
})
export class RejectClaimDialog implements OnInit {
  // Dialog Inputs
  private _trCode: string;
  private _trId: number;
  private _claimId: number | null;

  // Form data
  public _loading: boolean;
  public _saving: boolean;

  public _selectedReasson: string;
  public _issueReasons: IssueReasonModel;
  public _rejectReasons: ReasonInterface[];

  public _messages: Message[];

  constructor(
    private _config: DynamicDialogConfig,
    private _briefService: BriefService,
    private _trService: TransportRequestService,
    private _ref: DynamicDialogRef,
    private _claimService: ClaimService,
  ) {
    this._loading = true;
    this._saving = false;

    this._messages = [];

    this._issueReasons = new IssueReasonModel();
    this._rejectReasons = [];
  }

  public ngOnInit(): void {
   
    if (this._config.data.trId) {
      this._trId = this._config.data.trId;
    }

    if (this._config.data.claimId) {
      this._claimId = this._config.data.claimId;
    }

    this.loadReasons();
  }

  public cancelSubmit(): void {
    this._ref.close();
  }

  public onSubmit(): void {
    this._saving = true;

    this._claimService.changeClaimStatus(this._trId, 'Claim Rejected', this._selectedReasson, this._claimId).subscribe(obs => {
      if (!obs) {
        this._ref.close(false);
        return;
      }
      this._ref.close(this._saving);
    }, error => {
      this._messages.push({ severity: "error", detail: "Failed to update claim status" });
    });
 
  }

  private loadReasons(): void {
    this._briefService.getIssueReasons().subscribe(obs => {
      if (!obs) {
        return;
      }

      this._issueReasons = new IssueReasonModel(obs);

      this._issueReasons.tripReject.forEach(issueReason => {
        if (issueReason.reason) {
          issueReason.reason.forEach(reason => {
            this._rejectReasons.push(reason);
          });
        }
      });

      this._loading = false;
    }, error => {
      this._messages.push({ severity: "error", summary: "Reject", detail: "Failed to load reject reasons." });
      this._loading = false;
    });
  }
}
