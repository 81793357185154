import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";

@Component({
  selector: "note-editor",
  templateUrl: "note-editor.html",
  styleUrls: ["note-editor.css"]
})
export class NoteEditorComponent implements OnInit, OnChanges {
  @Input() notes: string;
  @Input() superUser: boolean;
  @Output() update: EventEmitter<string>;

  public _notes: string;
  public _edit: boolean;

  constructor() {
    this._notes = "";
    this._edit = false;

    this.update = new EventEmitter<string>();
  }

  public ngOnInit(): void {
    if(this.notes) {
      this._notes = this.notes;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.notes && changes.notes.currentValue) {
      this._notes = changes.notes.currentValue;
    }
  }

  public editData(): void {
    this._edit = true;
  }

  public saveData(): void {
    this.update.emit(JSON.stringify(this._notes));
    this._edit = false;
  }

  public cancelEdit(): void {
    this._edit = false;

    if(!this.notes) {
      return;
    }

    this._notes = this.notes;
  }
}
