import { Directive} from '@angular/core';

@Directive({
  selector: 'flx-collapse-header-title',
  host: {
    'class': 'flx-collapse-header-title'
  }
})
export class CollapseHeaderTitleDirective
{
  
  constructor()
  {
    
  }
}