import {Component} from "@angular/core";
import {AuthController} from "@slender/auth";
import {AppStatus, AsyncTaskController} from "@slender/foundation";
import {UserProfileService} from "../../services/user-profile.service";

@Component({
  templateUrl: "layout.html",
  styleUrls: ["layout.css"]
})
export class AppLayoutComponent {
  private userId: number;
  private saving: boolean;

  constructor(
    private _asyncTaskController: AsyncTaskController,
    private _authController: AuthController,
    private _userProfileService: UserProfileService
  ) {
    this.saving = false;

    _authController.loggedOut.subscribe(() => {
      if(this.saving) {
        return;
      }
      this.saving = true;

      this._userProfileService.setUserProfile(this.userId).subscribe(() => {
        this.clearUserProfiles();

        this.userId = 0;
        this.saving = false;
      });
    });

    this.loadUserProfiles();
  }

  public ngOnInit() {
    this._asyncTaskController.updateStatus(new AppStatus("info", "Ready"));
  }

  private clearUserProfiles(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  private loadUserProfiles(): void {
    this._userProfileService.getUserProfile().subscribe(obs => {
      if(!obs) {
        return;
      }

      if(obs.tripProfile) {
        localStorage.setItem(UserProfileService.tripTableKey, obs.tripProfile);
      }
      if(obs.shipmentProfile) {
        localStorage.setItem(UserProfileService.shipmentTableKey, obs.shipmentProfile);
      }
      if(obs.ratesProfile) {
        localStorage.setItem(UserProfileService.ratesTableKey, obs.ratesProfile);
      }
      if(obs.claimsProfile) {
        localStorage.setItem(UserProfileService.claimsTableKey, obs.claimsProfile);
      }

      this.userId = obs.userId;
    });
  }
}
