import { Component } from '@angular/core';

@Component({
  templateUrl: 'confirmation.html',
  styleUrls: ['confirmation.css']
})
export class ConfirmationComponent
{
  public message: string;

  constructor() { }

  private onInit(data: any)
  {
    this.message = data.message;
  }
}
