import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core'
import {Router} from '@angular/router';
import jsSHA from "jssha";
import {Cookie} from 'ng2-cookies/cookie';

import {Observable, of as observableOf} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {AuthController} from '../controllers';

import {AuthConfig, IAuthService, Session, SessionToken, User} from '../infrastructure';

@Injectable()
export class AuthService implements IAuthService
{
  public currentSession: Session;
  public get isAuthenticated() { return this.currentSession.isAuthenticated; };

  public config: AuthConfig;

  private _onVerifiedFn: (session: Session) => void;
  private _permissionCache: { [key: string]: boolean } = {};

  private readonly COOKIE_NAME = "SlenderAuthenticationToken";

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _authController: AuthController)
  {
    this.currentSession = Session.anonymous;
    this.config = new AuthConfig();
  }

  public login(system: string, username: string, password: string): Observable<any>
  {
    return this._http.post<SessionToken>('auth/login', { system, username, password }).pipe(
      tap(sessionToken => Cookie.set(this.COOKIE_NAME, sessionToken.token)));
  }

  public verify(): Observable<boolean>
  {
    if (Cookie.get(this.COOKIE_NAME))
    {
      return this._http.get<Session>('auth/verify').pipe(
        tap(session =>
        {
          this.currentSession = session;
          this._authController.verified.broadcast(session);
        }),
        map(() => true),
        catchError(err => this.gotoLogin()));
    }
    else
    {
      return this.gotoLogin();
    }
  }

  public logout()
  {
    this._http.get('auth/logout').subscribe(obs => {
      this.currentSession = Session.anonymous;
      this._permissionCache = {};
      Cookie.delete(this.COOKIE_NAME);

      this._authController.loggedOut.broadcast(this.currentSession);

      this.gotoLogin();
    }, error => {
      this.currentSession = Session.anonymous;
      this._permissionCache = {};
      Cookie.delete(this.COOKIE_NAME);

      this._authController.loggedOut.broadcast(this.currentSession);
      
      this.gotoLogin();
    });
  }

  public changePassword(newPasswordHash: string): Observable<any>
  {
    return this._http.post('auth/change-password', { newPasswordHash: newPasswordHash });
  }

  public hasPermission(permission: string): boolean
  {
    if (this._permissionCache[permission])
    {
      return true;
    }

    for (let pattern of this.currentSession.permissions)
    {
      if(pattern.toLowerCase() === permission.toLowerCase())
      {
        this._permissionCache[permission] = true;
        
        return true;
      }
    }

    return false;
  }
  
  public getUsername(): string {
    return this.currentSession.username.replace("_", " ");
  }

  public createUser(user: User, password: string): Observable<any>
  {
    var newUser: any = user;
    newUser.password = password;
    return this._http.post('auth/create-user', newUser);
  }

  public getCredentials(data: string): Observable<any>
  {
    return this._http.post<any>('auth/credentials', {username: data, password: ""});
  }

  public saltHash(data: string, salt: string): string
  {
    let shaObj = new jsSHA("SHA-512", "TEXT", {encoding: "UTF8"});

    shaObj.update(data);
    shaObj.update(salt);

    let saltedHash = shaObj.getHash("HEX");
    
    // let uint8array = new TextEncoder("utf-8")
    //   .encode("$3$2lC.K7/Zeno$" + data);
    //
    // let saltedHashArray = sha256(uint8array);
    //
    // let saltedHash = "";
    // for (let i = 0; i < saltedHashArray.length; i++)
    // {
    //   saltedHash += ("0" + saltedHashArray[i].toString(16)).slice(-2);
    // }

    return saltedHash;
  }

  private gotoLogin(): Observable<boolean>
  {
    this._router.navigate(['pub/login']);
    return observableOf(false);
  }
}
