import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {filter, map} from "rxjs/operators";

@Injectable()
export class JsonDateInterceptor implements HttpInterceptor {
  private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      filter(event => event instanceof HttpResponse && req.url.includes("api")),
      map((event: HttpResponse<any>) => {
        event = event.clone({
          body: this.convert(event.body)
        });

        return event;
      })
    );
  }

  private convert(body: any): any {
    if(body === null || body === undefined) {
      return body;
    }

    if(typeof body !== "object") {
      return body;
    }

    for(let key of Object.keys(body)) {
      let value = body[key];
      
      if(this.isIsoDateString(value)) {
        let timeZoneIndex = value.indexOf("Z");

        if(timeZoneIndex >= 0) {
          value = value.substring(0, timeZoneIndex);
        }

        body[key] = new Date(value);
      } else if(typeof value === "object") {
        body[key] = this.convert(value);
      }
    }

    return body;
  }

  private isIsoDateString(value: any): boolean {
    if(value === null || value === undefined) {
      return false;
    }

    if(typeof value === 'string') {
      return this._isoDateFormat.test(value);
    }
    return false;
  }
}
