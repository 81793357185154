import {FilterTypeModel} from "./filter-type.model";

export class ColumnFilterModel {
  dataType: string;
  filterType?: FilterTypeModel;
  value: any[];

  constructor(dataType: string) {
    this.dataType = dataType;

    if(this.dataType === "date") {
      this.value = [
        new Date(),
        new Date().setDate(new Date().getDate() + 1)
      ];
    } else {
      this.value = [
        "", ""
      ];
    }
  }
}
