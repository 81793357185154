import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Subject } from 'rxjs';

import { LogFilterModel } from '../infrastructure';

@Component({
  selector: 'slender-logs-filter',
  templateUrl: 'logs-filter.html',
  styleUrls: ['logs-filter.css']
})
export class LogsFilterComponent
{
  @Output()
  public change = new EventEmitter();

  public today = new Date();

  private _filterModel: LogFilterModel = { maxCount: 50, type: "$info$warning$error" };

  constructor() { }

  public onStartDateChange(event: { target: { value: Date; }; })
  {
    this._filterModel.startTime = event.target.value;
    this.change.emit({ value: this._filterModel });
  }

  public onTypeChange(event: { value: any; type: string; })
  {
    if (event.value)
    {
      this._filterModel.type += event.type;
    }
    else
    {
      this._filterModel.type = this._filterModel.type && this._filterModel.type.replace(event.type, "");
    }

    this.change.emit({ value: this._filterModel });
  }

  public onSourceChange(event: { target: { value: string; }; })
  {
    this._filterModel.source = event.target.value;
    this.change.emit({ value: this._filterModel });
  }

  public onMessageKeywordChange(event: { target: { value: string; }; })
  {
    this._filterModel.message = event.target.value;
    this.change.emit({ value: this._filterModel });
  }

  public onMaxCountChange(event: { target: { value: number; }; })
  {
    this._filterModel.maxCount = event.target.value;
    this.change.emit({ value: this._filterModel });
  }
}
