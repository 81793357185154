import { Injectable } from "@angular/core";

import { DialogProvider } from '@slender/modals';

import { ErrorDetailsComponent } from "./error-details.component";
import { ErrorDetails } from "./error-details";

@Injectable()
export class ErrorDetailsModalProvider
{
  constructor(private _dialogProvider: DialogProvider) { };

  public show(errorDetails: ErrorDetails)
  {
    this._dialogProvider.showDialog(ErrorDetailsComponent, errorDetails);
  }
}
