import { PlatformLayoutComponent } from './_layout/layout.component';
export { PlatformLayoutComponent }

import { HeaderComponent } from './header/header.component';
import { StatusbarComponent } from './statusbar/statusbar.component';

export const COMPONENTS = [
  PlatformLayoutComponent,
  HeaderComponent,
  StatusbarComponent
];
