import {Component, OnDestroy, OnInit} from "@angular/core";
import {MessageService} from "primeng/api";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HaulierModel} from "../../../models/haulier.model";
import {UserHaulierModel} from "../../../models/user-haulier.model";
import {UserService} from "../../../services/user.service";

@Component({
  selector: "user-haulier",
  templateUrl: "user-haulier.html",
  styleUrls: ["user-haulier.css"],
  providers: [MessageService]
})
export class UserHaulierComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean>;

  public _loadingHauliers: boolean;
  public _loadingUserHauliers: boolean;

  public _hauliers: HaulierModel[];
  public _users: UserHaulierModel[];

  public _availableHauliers: HaulierModel[];

  public _selectedIndex: number;
  public _selectedUser?: UserHaulierModel;

  constructor(
    private _messageService: MessageService,
    private _userService: UserService
  ) {
    this._destroy$ = new Subject<boolean>();

    this._loadingHauliers = true;
    this._loadingUserHauliers = true;

    this._availableHauliers = [];
    this._hauliers = [];
    this._users = [];

    this._selectedIndex = -1;
  }

  public ngOnInit(): void {
    this.loadUserHauliers();
    this.loadHauliers();
  }

  public ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }

  public onTabClose(event: number): void {
    this._selectedIndex = -1;
    this._selectedUser = undefined;
  }

  public onTabOpen(event: number): void {
    if(event > this._users.length) {
      return;
    }

    this._selectedIndex = event;
    this._selectedUser = UserHaulierModel.Copy(this._users[event]);

    this._availableHauliers = [];

    if(!this._selectedUser) {
      return;
    }

    this._hauliers.forEach(haulier => {
      let idx = this._selectedUser!.hauliers.findIndex(h => h.haulierId === haulier.haulierId);

      if(idx < 0) {
        this._availableHauliers.push(haulier);
      }
    });
  }

  public saveAssignments(): void {
    if(!this._selectedUser) {
      return;
    }

    this._userService.saveUserHauliers(this._selectedUser).subscribe(obs => {
      if(!obs) {
        return;
      }

      this._messageService.add({
        severity: "success",
        summary: "User Save",
        detail: "User haulier assignment updated"
      });

      let idx = this._users.findIndex(u => u.userId === obs.userId);
      if(idx >= 0) {
        this._users[idx] = obs;
      }
    }, error => {
      this._messageService.add({
        severity: "error",
        summary: "User Save",
        detail: "Failed to save user haulier assignment"
      });
    });
  }

  private loadUserHauliers(): void {
    this._loadingUserHauliers = true;

    this._userService.getUserHauliers().pipe(
      takeUntil(this._destroy$)
    ).subscribe(obs => {
      if(!obs) {
        this._messageService.add({
          severity: "error",
          summary: "Haulier Users",
          detail: "No haulier users found"
        });

        return;
      }

      this._users = obs;

      this._loadingUserHauliers = false;
    }, error => {
      this._messageService.add({
        severity: "error",
        summary: "Haulier Users",
        detail: "Failed to load user haulier assignments"
      });

      this._loadingUserHauliers = false;
    });
  }

  private loadHauliers(): void {
    this._loadingHauliers = true;

    this._userService.getHauliers().pipe(
      takeUntil(this._destroy$)
    ).subscribe(obs => {
      if(!obs) {
        this._messageService.add({
          severity: "error",
          summary: "Hauliers",
          detail: "No hauliers found"
        });

        return;
      }

      this._hauliers = obs;

      this._loadingHauliers = false;
    }, error => {
      this._messageService.add({
        severity: "error",
        summary: "Hauliers",
        detail: "Failed to load hauliers"
      });

      this._loadingHauliers = false;
    });
  }
}
