import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";

import {SlenderBootModule} from "@slender/foundation";
import {SlenderPlatformModule} from "@slender/platform";
import "zone.js";
import {AppModule} from "./app/app.module";
//import { enableProdMode } from "@angular/core";

let platformModule = SlenderPlatformModule({ lazyModule: AppModule });
let bootModule = SlenderBootModule({ lazyModule: platformModule });

//enableProdMode();

platformBrowserDynamic().bootstrapModule(bootModule);
