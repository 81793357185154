export enum FileType {
  none,
  brief,
  claim
}

export class FileTypeModel {
  name: string;
  code: string;

  constructor() {
  }

  public static getBriefTypes(): FileTypeModel[] {
    return [
      {name: "POD", code: "Pod"},
      {name: "Invoice", code: "Invoice"},
      {name: "Export", code: "Export"}
    ];
  }

  public static getClaimTypes(): FileTypeModel[] {
    return [
      {name: "Claim", code: "Claim"}
    ];
  }

  public static getName(code: string): string {
    let item = this.getBriefTypes().find(f => f.code === code);

    if (!item) {
      item = this.getClaimTypes().find(f => f.code === code);
      if (!item) {
        return "";
      }
    }

    return item.name;
  }
}
