import { Injectable, ErrorHandler, isDevMode } from '@angular/core'

import { EventController } from './event.controller'
import { LoggingService, ErrorDetails } from '../features';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ErrorController implements ErrorHandler
{
  constructor(
    private _eventBroadcaster: EventController,
    private _loggingService: LoggingService) { }

  public handleError(error: Error)
  {
    try
    {
      this._eventBroadcaster.error.broadcast(ErrorDetails.from(error));

      if (!(error instanceof HttpErrorResponse))
      {
        this._loggingService.logError(error);
      }
    }
    catch (e)
    {
      console.error(error);
    }

    if (isDevMode())
    {
      console.error(error);
    }
  }
}
