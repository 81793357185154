export class ContactModel {
  id: number;
  name: string;
  email: string;
  cell?: string;
  office: string;
  header?: string;

  constructor() {
    this.id = -1;
    this.name = "";
    this.email = "";
    this.cell = "";
    this.office = "";
    this.header = "";
  }

  public static Copy(model: ContactModel): ContactModel {
    let contact = new ContactModel();

    contact.id = model.id;
    contact.name = model.name;
    contact.email = model.email;
    contact.cell = model.cell;
    contact.office = model.office;
    contact.header = model.header;

    return contact;
  }

  public validate(): boolean {
    if(!this.name) {
      return false;
    }
    if(!this.email) {
      return false;
    }
    if(!this.office) {
      return false;
    }

    return true;
  }
}
