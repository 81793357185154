import { Component,Input } from '@angular/core';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
export const Collapse_BOX_ANIMATION =
  trigger('state', [
    state('closed', style({
      'flex-basis': '0',
      display: 'none',
      opacity : '0'
    })),
    state('open', style({
      'flex-basis': '*',
      opacity: '1',
      display: 'flex'
    })),
    transition('closed => open',
      animate(100, keyframes([
        style({ opacity: 0}),
        style({ opacity: 0.5}),
        style({ opacity: 1}),
      ])
      )
    ),
    transition('open => closed',
      animate(50, keyframes([
        style({ opacity: 1 }),
        style({ opacity: 0.5, 'flex-basis': '*' }),
        style({ opacity: 0 }),
      ])
      )
    )
  ]);

export const Collapse_ICON_ANIMATION = 
  trigger('state', [
    state('closed', style({
      transform: 'rotate(180deg)'    
    })),
    state('open', style({
    })),
    transition('closed => open',
      animate(100, keyframes([
        style({ transform: 'rotate(170deg)' }),
        style({ transform: 'rotate(90deg)' }),
        style({ transform: 'rotate(0deg)' }),
      ])
      )
    ),
    transition('open => closed',
      animate(100, keyframes([
        style({ transform: 'rotate(0deg)' }),
        style({ transform: 'rotate(90deg)' }),
        style({ transform: 'rotate(170deg)' }),
      ])
      )
    )
  ]);