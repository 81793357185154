import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {ContactModel} from "../../../models/home/contact.model";

@Component({
  selector: "contact-details",
  templateUrl: "contact-details.html",
  styleUrls: ["contact-details.css"]
})
export class ContactDetailsComponent implements OnInit, OnChanges {
  @Input() contact: ContactModel;
  @Input() edit: boolean;
  @Input() superUser: boolean;

  @Output() delete: EventEmitter<number>;
  @Output() update: EventEmitter<ContactModel>;

  public _contact: ContactModel;
  public _message: string;

  constructor() {
    this._contact = new ContactModel();
    this._message = "";

    this.delete = new EventEmitter<number>();
    this.update = new EventEmitter<ContactModel>();
  }

  public ngOnInit(): void {
    if(this.contact) {
      this._contact = ContactModel.Copy(this.contact);

      if(!this._contact.validate() && this.superUser) {
        this.edit = true;
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.contact && changes.contact.currentValue) {
      this._contact = ContactModel.Copy(changes.contact.currentValue);

      if(!this._contact.validate() && this.superUser) {
        this.edit = true;
      }
    }
  }

  public deleteContact(): void {
    this.delete.emit(this._contact.id);
  }

  public updateContact(property: string, event: string): void {
    switch(property) {
      case 'header':
        this._contact.header = event;
        break;

      case 'name':
        this._contact.name = event;
        break;

      case 'email':
        this._contact.email = event;
        break;

      case 'cell':
        this._contact.cell = event;
        break;

      case 'office':
        this._contact.office = event;
        break;

      default:
    }
  }

  public saveContact(): void {
    if(!this._contact.validate()) {
      this._message = "Invalid contact details: Name, Email, and Office number required";
      return;
    } else {
      this._message = "";
    }

    this.update.emit(this._contact);
  }
}
