import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {IssueReasonInterface} from "../../models/issue-reason/issue-reason.interface";
import {ReasonInterface} from "../../models/issue-reason/reason.interface";

@Component({
  selector: "issue-selector",
  templateUrl: "issue-selector.html",
  styleUrls: ["issue-selector.css"]
})
export class IssueSelectorComponent implements OnInit, OnChanges {
  @Input() issueReasons: IssueReasonInterface[];
  @Input() currentIssue: string;
  @Input() currentReason: string;
  @Input() currentAdditionalInformation: string;
  @Input() readOnly: boolean;

  @Output() additionalInfo: EventEmitter<string>;
  @Output() issue: EventEmitter<string>;
  @Output() reason: EventEmitter<string>;

  public _issueReasons: IssueReasonInterface[];
  public _availableReasons: ReasonInterface[];

  public _selectedIssue: IssueReasonInterface;
  public _selectedReason: ReasonInterface;

  public _additionalInfo: string;

  constructor() {
    this.issue = new EventEmitter<string>();
    this.reason = new EventEmitter<string>();
    this.additionalInfo = new EventEmitter<string>();

    this._issueReasons = [];
    this._availableReasons = [];
  }

  public ngOnInit(): void {
    if(this.issueReasons) {
      this._issueReasons = this.issueReasons;

      if(this._selectedIssue) {
        this._availableReasons = this._selectedIssue.reason.map(r => r);
      }
    }

    if(this.currentAdditionalInformation) {
      this._additionalInfo = this.currentAdditionalInformation;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.issueReasons && changes.issueReasons.currentValue) {
      this._issueReasons = changes.issueReasons.currentValue;

      if(this._issueReasons.length <= 0) {
        return;
      }

      if(this.currentIssue) {
        let selected = this._issueReasons.find(ir => ir.issue === this.currentIssue);
        if(selected) {
          this._selectedIssue = selected;

          this._availableReasons = this._selectedIssue.reason.map(r => r);
        }
      }

      if(this.currentReason) {
        let selected = this._availableReasons.find(i => i.reason === this.currentReason);

        if(selected) {
          this._selectedReason = selected;
        }
      }
    }
  }

  public onAdditionalInfoChange(event: any): void {
    this._additionalInfo = event;
    this.additionalInfo.emit(event);
  }

  public onIssueChange(event: IssueReasonInterface): void {
    if(!event) {
      this.issue.emit(undefined);
    } else {
      this.issue.emit(event.issue);

      this._availableReasons = event.reason.map(r => r);
    }
  }

  public onReasonChange(event: ReasonInterface): void {
    if(!event) {
      this.reason.emit(undefined);
    } else {
      this.reason.emit(event.reason);
    }
  }
}
