import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {UserProfileModel} from "../models/user-profile.model";

@Injectable()
export class UserProfileService {
  public static userTableKey = "user-management-state";
  public static tripTableKey = "trip-management-state";
  public static shipmentTableKey = "shipment-management-state";
  public static ratesTableKey = "rates-management-state";
  public static claimsTableKey = "claims-management-state";

  constructor(private _http: HttpClient) {
  }

  public getUserProfile(): Observable<UserProfileModel> {
    let path = "/api/profiles";
    return this._http.get<UserProfileModel>(path);
  }

  public setUserProfile(userId: number): Observable<any> {
    let path = "/api/profiles";
    return this._http.post(path, this.readUserProfile(userId));
  }

  private readUserProfile(userId: number): UserProfileModel {
    let userProfile = localStorage.getItem(UserProfileService.userTableKey);
    let tripProfile = localStorage.getItem(UserProfileService.tripTableKey);
    let shipmentProfile = localStorage.getItem(UserProfileService.shipmentTableKey);
    let ratesProfile = localStorage.getItem(UserProfileService.ratesTableKey);
    let claimsProfile = localStorage.getItem(UserProfileService.claimsTableKey);

    return {
      userId: userId,
      userProfile: userProfile ? userProfile : "",
      tripProfile: tripProfile ? tripProfile : "",
      shipmentProfile: shipmentProfile ? shipmentProfile : "",
      ratesProfile: ratesProfile ? ratesProfile : "",
      claimsProfile: claimsProfile ? claimsProfile : ""
    };
  }
}
