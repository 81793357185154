import { HttpErrorResponse } from "@angular/common/http";

export class ErrorDetails
{
  public title: string;
  public type: string;
  public code: string;
  public message: string;
  public stack?: string;

  public serverResponse: string;

  constructor() { }

  public static from(error: any)
  {
    if (error instanceof ErrorDetails)
    {
      return error;
    }

    let errorDetails = new ErrorDetails();
    if (typeof error == "string")
    {
      errorDetails.title = "An error has occured";
      errorDetails.type = "General Error";
      errorDetails.message = error;
    }
    else if (error instanceof Error)
    {
      errorDetails.title = "An application error has occured";
      errorDetails.type = "Application Error";
      errorDetails.message = error.message;
      errorDetails.stack = error.stack;
    }
    else if (error instanceof HttpErrorResponse)
    {
      errorDetails.title = "The server responded with an error";
      errorDetails.type = "Server Request Unsuccessful";
      errorDetails.message = `URL ${error.url} responded with status ${error.status} ${error.statusText}`;
      errorDetails.code = `${error.status.toString()} ${error.statusText}`;

      if (error.error)
      {
        if (typeof (error.error) === 'string')
        {
          errorDetails.serverResponse = error.error;
        }
        else if (typeof (error.error.text) === 'string')
        {
          errorDetails.serverResponse = error.error.text;
        }
        else 
        {
          errorDetails.serverResponse = JSON.stringify(error.error, null, "  ");
        }
      }
    }
    else 
    {
      errorDetails.title = "An unknown aplication error has occured";
      errorDetails.type = "Unknown Error";
      errorDetails.message = "Please refresh the page.";
      errorDetails.stack = error.stack;
    }

    return errorDetails;
  }
}
