import { Component, ViewEncapsulation } from '@angular/core';

import { AsyncTaskController, AppStatus } from '@slender/foundation';

@Component({
  templateUrl: 'layout.html',
  styleUrls: ['layout.css'],
  host: { style: "display:flex; flex-direction:column; width: 100vw; height: 100vh;" },
  encapsulation: ViewEncapsulation.None
})
export class PlatformLayoutComponent
{
  constructor(private _asyncTaskController: AsyncTaskController)
  {
    console.debug("LayoutComponent ctor");
  }

  public ngOnInit()
  {
    console.debug("Platform LayoutComponent init");
  }
}
