import {animate, state, style, transition, trigger} from "@angular/animations";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from "@angular/core";
import {AuthService} from "@slender/auth";
import {MenuItem, MessageService, SortMeta, TableState} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {Menu} from "primeng/menu";
import {MultiSelect} from "primeng/multiselect";
import {Table} from "primeng/table";
import {fromEvent, Observable, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {DialogResponseModel} from "../../models/response-status/dialog-response.model";
import {ColumnDefinitionModel} from "../../models/table/column-definition.model";
import {GroupMetaDataModel} from "../../models/table/group-meta-data.model";
import {TableStateModel} from "../../models/table/table-state.model";
import {TableService} from "../../services/table.service";
import {VehicleRegistrationDialog} from "../vehicle-registration/vehicle-registration.dialog";
import {ColumnFilterDialog} from "./column-filter/column-filter.dialog";
import Timer = NodeJS.Timer;

@Component({
  selector: "table-display",
  templateUrl: "table-display.html",
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  styleUrls: ["table-display.css"],
  providers: [DialogService]
})
export class TableDisplayComponent implements OnInit, OnChanges, OnDestroy {
  @Input() financeVar: boolean;
  @Input() readOnlyVar: boolean;

  @Input() cloverClaim: boolean;
  @Input() transporterClaim: boolean;

  // Main table
  @Input() availableColumns: ColumnDefinitionModel[];
  @Input() dataKey: string;
  @Input() stateModel: TableStateModel;
  @Input() tableData: any[];
  @Input() tableLoading: boolean;
  @Input() type: string;

  // Used for adding column for extra dialog
  @Input() expandable: boolean;

  @Input() get selectedColumns(): ColumnDefinitionModel[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: ColumnDefinitionModel[]) {
    // this._selectedColumns = this._availableColumns.filter(col => val.includes(col));

    let allColumns = this._availableColumns.map(col => col.field);
    let prevSelection = this._selectedColumns.map(col => col.field);
    let currentSelection = val.map(col => col.field);

    let newColumns = allColumns.filter(col => !prevSelection.includes(col) && currentSelection.includes(col));

    newColumns.forEach(col => {
      let defaultIdx = allColumns.indexOf(col);

      if(this.stateModel.state?.columnOrder) {
        let tmp = this.stateModel.state.columnOrder.indexOf(col);
        defaultIdx = tmp >= 0 ? tmp : defaultIdx;
      }

      let colIdx = val.findIndex(colModel => colModel.field === col);
      let column = val.splice(colIdx, 1);

      if(column.length > 0) {
        val.splice(defaultIdx, 0, column[0]);
      }
    });

    val.forEach(column => {
      if(column.filterConstraint) {
        column.filterConstraint.filterType = undefined;
        column.filterConstraint.value[0] = "";
        column.filterConstraint.value[1] = "";
      }
    });

    this._selectedColumns = val;

    this.updateStateColumnOrder();
    this.saveTableState();
  }

  @Output() updateTableData: EventEmitter<string[]>;
  @Output() updateTableCell: EventEmitter<{ field: string, row: any }>;
  @Output() updateSubTableData: EventEmitter<DialogResponseModel>;
  @Output() subTableExpand: EventEmitter<any>;
  @Output() updateState: EventEmitter<TableStateModel>;

  @ViewChildren("columnFilter") columnFilters: QueryList<ElementRef>;
  @ViewChild("container") container: ElementRef;
  @ViewChild("columnSelection") columnSelector: MultiSelect;
  @ViewChild("dt") table: Table;

  // Columns
  public _availableColumns: ColumnDefinitionModel[];
  public _selectedColumns: ColumnDefinitionModel[];
  // Data
  public _displayRow: any;
  public _selectedRows: any[];
  public _tableData: any[];
  // Frozen Columns
  public _frozenColumns: ColumnDefinitionModel[];
  public _frozenWidth: string;
  // Grouping
  public _columnGroup: ColumnDefinitionModel[];
  private _groupMetaData: GroupMetaDataModel[];
  // Header
  private _menuColumn: ColumnDefinitionModel;
  public _headerMenu: MenuItem[];
  // Resize Event
  private _resizeObservable$: Observable<Event>;
  private _resizeSubscription$: Subscription;
  // State
  public _scrollHeight: string;
  public _stateName: string;
  // Table Menu
  public _tableMenu: MenuItem[];
  // Timers
  private _cellTimeout: Timer;

  constructor(
    private _authService: AuthService,
    private _dialogService: DialogService,
    private _messageService: MessageService,
    private _tableService: TableService
  ) {
    this._stateName = "";

    ColumnFilterDialog.RegisterCustom();

    this._scrollHeight = "200px";

    this.updateTableData = new EventEmitter<string[]>();
    this.updateTableCell = new EventEmitter<{field: string, row: any}>();
    this.updateSubTableData = new EventEmitter<DialogResponseModel>();
    this.subTableExpand = new EventEmitter<any>();
    this.updateState = new EventEmitter<TableStateModel>();

    this._availableColumns = [];
    this._selectedColumns = [];

    this._frozenColumns = [];

    this._columnGroup = [];
    this._groupMetaData = [];

    this.expandable = false;
    this._tableData = [];
  }

  public ngOnInit(): void {
    // Current table
    if(!this.tableData) {
      return;
    }
    this._tableData = this.tableData;

    if(!this.availableColumns) {
      return;
    }
    this._availableColumns = this.availableColumns;

    this.loadStateModel();
    this.adjustFrozenWidth();
    this.generateTableMenu();
    this.generateHeaderMenu();

    // Subscribe to resize event
    this._resizeObservable$ = fromEvent(window, "resize");
    this._resizeSubscription$ = this._resizeObservable$
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.adjustScrollHeight();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.tableData) {
      this._tableData = changes.tableData.currentValue;

      if(this._columnGroup.length > 0) {
        this.sortColumnGrouping();
      }

      this.adjustScrollHeight();
      
      let first = 0;
      if (this.table) {
        first = this.table.first;
      }
      
      setTimeout(
        () => {this.table.first = first;},
        200
      );
    }
  }

  public ngOnDestroy(): void {
    this._resizeSubscription$.unsubscribe();
  }


  /** Table function **/

  public sortData(sortMeta: SortMeta[]): void {
    if(!this.table) {
      return;
    }

    let resort = false;
    let resortMeta: SortMeta[] = [];

    this._columnGroup.forEach(column => {
      let idx = sortMeta.findIndex(sm => sm.field === column.field);

      if(idx < 0) {
        resortMeta.push({
          field: column.field,
          order: 1
        });

        resort = true;
      } else {
        resortMeta.push(sortMeta[idx]);

        sortMeta.splice(idx, 0);
      }
    });

    if(sortMeta.length > 0 && resort) {
      sortMeta.forEach(model => {
        resortMeta.push(model);
      });
    }

    if(resort) {
      this.table.multiSortMeta = resortMeta;
      this.table.sortMultiple();
    }

    this.updateColumnGroupingMetaData();
  }

  public resetTable(resetSort: boolean = false): void {
    if(!this.table) {
      return;
    }

    /**
     * Reset table filters and paginator state.
     */
    let sortMeta = this.table.multiSortMeta

    this.table.reset();

    // Reset filters
    this._selectedColumns.forEach(column => {
      if(column.filterConstraint) {
        column.filterConstraint.filterType = undefined;
        column.filterConstraint.value[0] = "";
        column.filterConstraint.value[1] = "";
      }
    });

    this.columnFilters.forEach(item => {
      item.nativeElement.value = "";
    });

    if(!resetSort) {
      // Redo sorting
      if(sortMeta) {
        this.table.multiSortMeta = sortMeta;
        this.table.sortMultiple();
      }
    }
  }

  public getPageReport(state: any): string {
    let first = state.first;
    let rows = state.rows;
    let totalRecords = state.totalRecords;

    if(totalRecords === 0) {
      return "No records found.";
    }

    let report = `Showing ${(first + 1).toLocaleString()} to `;

    if(first + rows <= totalRecords) {
      report += `${(first + rows).toLocaleString()}`;
    } else {
      report += `${totalRecords.toLocaleString()}`;
    }

    report += ` of ${totalRecords.toLocaleString()} records.`;
    return report;
  }

  public displayTableMenu(event: any, menu: Menu): void {
    menu.toggle(event);
  }

  public exportData(type: string): void {
    if(!this.table) {
      return;
    }

    switch(type) {
      case "csv":
        this.table.exportCSV();
        break;

      default:
        return;
    }
  }

  public csvExportFunction(data: any): string {
    let value = data.data;

    if(value instanceof Date) {
      let temp = new Date(value);
      
      value = `${temp.getFullYear()}/${temp.getMonth() + 1}/${temp.getDate()} ${temp.getHours()}:${temp.getMinutes()}`;
    }

    return value;
  }

  private adjustScrollHeight(): void {
    if(!this.container) {
      this._scrollHeight = "200px";
      return;
    }

    let containerHeight = this.container.nativeElement.clientHeight;
    let captionHeight = 57;
    let headerHeight = 85;
    let filterHeight = 48;
    let paginatorHeight = 48;

    if(this._columnGroup.length > 0) {
      captionHeight += 67;
    }

    let scrollHeight = containerHeight - (captionHeight + headerHeight + filterHeight + paginatorHeight);

    if(scrollHeight < 75) {
      scrollHeight = 75;
    }

    this._scrollHeight = `${scrollHeight}px`;
  }

  private adjustFrozenWidth(): void {
    if(this._frozenColumns.length === 0) {
      this._frozenWidth = "0px";
      return;
    }

    let width = 0;
    this._frozenColumns.forEach(column => {
      let idx = column.width.indexOf("px");
      width += parseInt(column.width.substring(0, idx));
    });

    this._frozenWidth = `${width}px`;

    if(this.table) {
      let element = document.getElementById(this._frozenColumns[0].field + '_label');
      if(element) {
        element.click();

        element.click();
      }
    }
  }

  private sortColumnGrouping() {
    if(this._columnGroup.length === 0) {
      return;
    }

    this.table.multiSortMeta = [];

    this._columnGroup.forEach(column => {
      this.table.multiSortMeta.push({
        field: column.field,
        order: 1
      });
    });

    this.table.sortMultiple();
  }

  private addSelectedColumn(column: ColumnDefinitionModel): void {
    if(this.selectedColumns.findIndex(sc => sc.field === column.field) >= 0) {
      return;
    }

    let tmp = this.selectedColumns.map(sc => sc);

    tmp.push(column);
    this.selectedColumns = tmp;
  }

  private removeSelectedColumn(column: ColumnDefinitionModel): void {
    let idx = this.selectedColumns.findIndex(sc => sc.field === column.field);
    if(idx < 0) {
      return;
    }

    let tmp = this.selectedColumns.map(sc => sc);
    tmp.splice(idx, 1);

    this.selectedColumns = tmp;
  }

  private generateTableMenu(): void {
    this._tableMenu = [
      {
        label: "Export",
        items: [
          {
            label: "Export CSV",
            icon: "pi pi-download",
            command: () => this.exportData("csv")
          }
        ]
      },
      {
        label: "Table",
        items: [
          {
            label: "Reset",
            icon: "pi pi-refresh",
            command: () => {this.resetTable()}
          }
        ]
      }
    ]
  }


  /** State functions **/

  public onColumnReorder(columns: ColumnDefinitionModel[]): void {
    if(this.stateModel.state && this.stateModel.state.columnOrder) {
      this.stateModel.state.columnOrder = columns.map(col => col.field);
    }

    this.selectedColumns = columns;
  }

  public onColumnResize(element: any, delta: number): void {
    // Adjust width of selected columns
    let idx = this.selectedColumns.findIndex(col => col.field === element.id);
    if(idx >= 0) {
      let currentWidth = this.selectedColumns[idx].width;
      let width = parseInt(currentWidth.substring(0, currentWidth.indexOf("px"))) + delta;

      this.selectedColumns[idx].width = `${width}px`;
    }

    // Adjust width of frozen columns
    idx = this._frozenColumns.findIndex(col => col.field === element.id);

    if(idx < 0) {
      return;
    }

    let currentWidth = this._frozenColumns[idx].width;
    let width = parseInt(currentWidth.substring(0, currentWidth.indexOf("px"))) + delta;

    this._frozenColumns[idx].width = `${width}px`;

    this.adjustFrozenWidth();
  }

  public onStateSave(state: TableState): void {
    if(state.filters) {
      delete state.filters;
    }

    // Save local
    sessionStorage.setItem(this._stateName, JSON.stringify(state));

    this.saveTableState();
  }

  private loadStateModel(): void {
    if(this.stateModel) {
      this._stateName = this.stateModel.name;

      if(this.stateModel.selectedColumns) {
        this.selectedColumns = [];

        this.stateModel.selectedColumns.forEach(field => {
          let column = this._availableColumns.find(ac => ac.field === field);

          if(column) {
            this.selectedColumns.push(column);
          }
        });
      } else {
        this.selectedColumns = this._availableColumns;
      }

      if(this.stateModel.groupedColumns) {
        this._columnGroup = [];

        this.stateModel.groupedColumns.forEach(field => {
          let column = this._availableColumns.find(ac => ac.field === field);

          if(column) {
            this._columnGroup.push(column);
          }

          this.adjustScrollHeight();
        });
      }

      if(this.stateModel.frozenColumns) {
        this._frozenColumns = [];

        let widths: string[] = [];
        if(this.stateModel.state?.columnWidths) {
          widths = this.stateModel.state.columnWidths.split(",");
        }

        this.stateModel.frozenColumns.forEach(field => {
          let column = this._availableColumns.find(ac => ac.field === field);

          if(column) {
            this._frozenColumns.push(column);

            if(widths.length > 0) {
              let idx = this._frozenColumns.findIndex(col => col === column);

              this._frozenColumns[idx].width = `${widths[idx]}px`;
            }
          }
        });

        this.adjustFrozenWidth();
      }

      if(this.stateModel.state) {
        sessionStorage.setItem(this._stateName, JSON.stringify(this.stateModel.state));
      }
    }
  }

  private saveTableState(): void {
    if(this.selectedColumns.length === 0) {
      return;
    }

    let tableState: TableStateModel = {
      name: this._stateName
    };

    let sessionState = sessionStorage.getItem(this._stateName);
    if(sessionState) {
      tableState.state = JSON.parse(sessionState);
    }

    if(this.selectedColumns) {
      tableState.selectedColumns = this.selectedColumns.map(sc => sc.field);
    }

    if(this._columnGroup) {
      tableState.groupedColumns = this._columnGroup.map(cg => cg.field);
    }

    if(this._frozenColumns.length > 0) {
      tableState.frozenColumns = this._frozenColumns.map(fc => fc.field);
    }

    this.updateState.emit(tableState);
  }

  private updateStateColumnOrder(): void {
    let currentState = sessionStorage.getItem(this._stateName);
    if(!currentState) {
      return;
    }

    let state: TableState = JSON.parse(currentState);
    state.columnOrder = this._selectedColumns.map(sc => sc.field);

    sessionStorage.setItem(this._stateName, JSON.stringify(state));
  }


  /** Header functions **/

  public displayHeaderMenu(event: any, menu: Menu, column: ColumnDefinitionModel): void {
    this._menuColumn = column;
    menu.toggle(event);
  }

  public groupColumn(column: ColumnDefinitionModel, group: boolean): void {
    if(group) {
      this.addColumnToGrouping(column);
    } else {
      this.removeColumnFromGrouping(column);
    }
  }

  private generateHeaderMenu(): void {
    this._headerMenu = [
      {
        label: "Group",
        items: [
          {
            label: "Add to Grouping",
            icon: "pi pi-plus",
            command: () => {this.groupColumn(this._menuColumn, true)}
          },
          {
            label: "Remove from Grouping",
            icon: "pi pi-minus",
            command: () => {this.groupColumn(this._menuColumn, false)}
          }
        ]
      },
      {
        label: "Pin",
        items: [
          {
            label: "Pin",
            icon: "pi pi-lock",
            command: () => {this.addColumnToPin(this._menuColumn)}
          },
          {
            label: "Unpin",
            icon: "pi pi-lock-open",
            command: () => {this.removeColumnFromPin(this._menuColumn)}
          }
        ]
      },
      {
        label: "Sort",
        items: [
          {
            label: "Sort Ascending",
            icon: "pi pi-caret-up",
            command: () => {
              this.table.multiSortMeta = [{
                field: this._menuColumn.field,
                order: 1
              }];
              this.table.sortMultiple();
            }
          },
          {
            label: "Sort Descending",
            icon: "pi pi-caret-down",
            command: () => {
              this.table.multiSortMeta = [{
                field: this._menuColumn.field,
                order: -1
              }];
              this.table.sortMultiple();
            }
          }
        ]
      }
    ];
  }

  private addColumnToGrouping(column: ColumnDefinitionModel): void {
    if(this._columnGroup.indexOf(column) >= 0) {
      return;
    }

    this._columnGroup.push(column);

    this.adjustScrollHeight();

    this.sortColumnGrouping();
    this.saveTableState();
  }

  private removeColumnFromGrouping(column: ColumnDefinitionModel): void {
    let idx = this._columnGroup.findIndex(c => c.field === column.field);

    if(idx < 0) {
      return;
    }

    this.tableLoading = true;

    this._columnGroup.splice(idx, 1);

    this.adjustScrollHeight();

    if(this._columnGroup.length === 0) {
      this._groupMetaData = [];
    }

    this.sortColumnGrouping();
    this.saveTableState();

    this.tableLoading = false;
  }

  private updateColumnGroupingMetaData() {
    if(this._columnGroup.length === 0) {
      return;
    }

    let data = this.table.value;
    if(this.table.filteredValue) {
      data = this.table.filteredValue;
    }

    let rowGroupMetaData: GroupMetaDataModel[] = [];

    for(let dataIdx = 0; dataIdx < data.length; dataIdx++) {
      let group: string = "";
      let property: string = "";

      let row = data[dataIdx];
      for(let keyIdx = 0; keyIdx < this._columnGroup.length; keyIdx++) {
        let key = this._columnGroup[keyIdx].field;
        group += `${key};`;

        let tmp = row[key];
        if(!tmp) {
          tmp = "na";
        }
        property += `${tmp};`;

        if(dataIdx === 0) {
          // @ts-ignore
          rowGroupMetaData[property] = {
            dataKey: group,
            index: 0,
            size: 1
          };
        } else {
          // @ts-ignore
          if(rowGroupMetaData[property]) {
            // @ts-ignore
            rowGroupMetaData[property].size++;
          } else {
            // @ts-ignore
            rowGroupMetaData[property] = {
              dataKey: group,
              index: dataIdx,
              size: 1
            };
          }
        }
      }
    }

    this._groupMetaData = rowGroupMetaData;
  }

  private addColumnToPin(column: ColumnDefinitionModel): void {
    if(this._frozenColumns.indexOf(column) >= 0) {
      return;
    }

    let idx = this.selectedColumns.findIndex(col => col.field === column.field);

    this._frozenColumns.push(this.selectedColumns[idx]);
    this.adjustFrozenWidth();

    this.removeSelectedColumn(column);

    this.saveTableState();
  }

  private removeColumnFromPin(column: ColumnDefinitionModel): void {
    let idx = this._frozenColumns.findIndex(fc => fc.field === column.field);

    if(idx < 0) {
      return;
    }

    this._frozenColumns.splice(idx, 1);
    this.adjustFrozenWidth();

    this.addSelectedColumn(column);

    this.saveTableState();
  }


  /** Row level functions **/

  public displayTableDialog(row: any) {
    this._displayRow = row;

    this.subTableExpand.emit(this._displayRow);
  }

  public getGroupMetData(row: any, column: ColumnDefinitionModel): GroupMetaDataModel | null {
    if(this._columnGroup.length === 0 || !this._groupMetaData) {
      return null;
    }

    let keys = this._columnGroup.slice(0, this._columnGroup.indexOf(column) + 1);
    let property = "";

    for(let i = 0; i < keys.length; i++) {
      let tmp = row[keys[i].field];
      if(!tmp) {
        tmp = "na";
      }

      property += `${tmp};`;
    }

    // @ts-ignore
    return this._groupMetaData[property];
  }

  public getRowSpanSize(rowIndex: number, column: ColumnDefinitionModel, rowData: any): number {
    let metaData = this.getGroupMetData(rowData, column);

    if(!metaData) {
      return 1;
    }

    if(rowIndex >= metaData.index && rowIndex < (metaData.index + metaData.size)) {
      let firstRow = this.table.first;
      let span = metaData.index + metaData.size - rowIndex;

      if(metaData.index === rowIndex) {
        return metaData.size;
      } else if(span === (metaData.size - (firstRow - metaData.index))) {
        return span;
      }
    }

    return 0;
  }


  /** Cell level functions **/

  public cellUpdate(field: string, event: any): void {
    if(this._cellTimeout) {
      clearTimeout(this._cellTimeout);
    }

    if(field === "plannedVehicleRegistration") {
      this.updateTableCell.emit({
        field: field,
        row: event
      });

      return;
    }

    this._cellTimeout = setTimeout(() => {
      this.updateTableCell.emit({
        field: field,
        row: event
      });
    }, 3000);
  }

  public formatDate(value: Date, withTime: boolean = true): string {
    if(value === undefined || value === null) {
      return "";
    }

    let day = this.padNumber(value.getDate(), 2);
    let month = this.padNumber(value.getMonth() + 1, 2);
    let year = this.padNumber(value.getFullYear(), 4);
    let hours = this.padNumber(value.getHours(), 2);
    let minutes = this.padNumber(value.getMinutes(), 2);

    if(withTime) {
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    } 
    
    return `${day}/${month}/${year}`;
  }

  public formatNumber(value: number): string {
    if(value === undefined || value === null) {
      return "";
    }

    return value.toFixed(2);
  }

  private padNumber(value: number, width: number): string {
    let stringVal = value + '';
    return stringVal.length >= width ? stringVal : new Array(width - stringVal.length + 1).join('0') + stringVal;
  }


  /** Filter functions **/

  public editFilter(column: ColumnDefinitionModel): void {
    const ref = this._dialogService.open(ColumnFilterDialog, {
      data: {
        currentFilter: column.filterConstraint
      },
      header: `${column.header} Filter Type`,
      width: "30%"
    });

    ref.onClose.subscribe(obs => {
      if(!obs) {
        return;
      }

      column.filterConstraint = obs;

      if(column.filterConstraint?.filterType) {
        if(column.filterConstraint.filterType.type.toLowerCase().indexOf("between") < 0) {
          this.table.filter(
            column.filterConstraint?.value[0],
            column.field,
            column.filterConstraint?.filterType.type
          );
        } else {
          this.table.filter(
            column.filterConstraint?.value,
            column.field,
            column.filterConstraint?.filterType.type
          );
        }
      } else {
        this.table.filter(
          "",
          column.field,
          "contains"
        );
      }
    });
  }

  public filterData(): void {
    if(this._columnGroup.length > 0) {
      this.updateColumnGroupingMetaData();
    }
  }

  public filterLabel(column: ColumnDefinitionModel): string {
    if(!column.filterConstraint?.filterType) {
      return "...";
    }

    let label: string;

    if(column.filterConstraint.filterType.type !== "between" && column.filterConstraint.filterType.type !== "dateBetween") {
      label = `${column.filterConstraint.filterType.symbol}`;

      if(column.filterConstraint.dataType === "date" && column.filterConstraint.value) {
        label += ` ${this.formatDate(column.filterConstraint.value[0], false)}`;
      } else {
        label += ` ${column.filterConstraint.value[0]}`;
      }
    } else {
      if(column.filterConstraint.dataType === "date") {
        label = `${this.formatDate(column.filterConstraint.value[0], false)} - `;
        label += `${this.formatDate(column.filterConstraint.value[1], false)}`;
      } else {
        label = `${column.filterConstraint.value[0]} - ${column.filterConstraint.value[1]}`;
      }
    }

    return label;
  }
  

  /** Custom control functions **/
  // TODO: --- Add custom controls here ---

  public dialogResponse(rowData: any, response: DialogResponseModel): void {
    if(!response) {
      return;
    }

    let refreshIds: string[] = [];
    let failedUpdates: string[] = [];
    let successfulUpdates: string[] = [];

    response.responses.forEach(model => {
      if(model.isError) {
        failedUpdates.push(model.message);
      } else {
        successfulUpdates.push(model.message);
        refreshIds.push(model.code);
      }
    });

    if(successfulUpdates.length === response.responses.length) {
      this._messageService.add({severity: "success", summary: response.responseType, detail: "All updates successfully completed."});
    } else {
      failedUpdates.forEach(model => {
        this._messageService.add({severity: "error", summary: response.responseType, detail: model});
      });
    }

    this.updateTableData.emit(refreshIds);
  }

  public subDialogResponse(response: DialogResponseModel): void {
    this.updateSubTableData.emit(response);
  }

  public validateVehicleRegistration(registration: string): boolean {
    if(registration.length < 6 || registration.length > 8) {
      return false;
    }

    let format = /^[a-zA-Z0-9]*$/;
    if(!format.test(registration)) {
      return false;
    }

    return true;
  }

  public vehicleRegistrationEdit(rowData: any): boolean {
    switch (rowData.physicalStatusName.toLowerCase()) {
      case "published":
      case "accepted":
      case "haulier confirmed":
        return false;
      default:
        return true;
    }
  }

  public displayVehicleSelection(rowData: any): void {
    if(!rowData.transportRequestId) {
      return;
    }

    const ref = this._dialogService.open(VehicleRegistrationDialog, {
      data: {
        transportRequestId: rowData.transportRequestId,
        vehicleRegistration: rowData.plannedVehicleRegistration
      },
      dismissableMask: true,
      header: `${rowData.code} Vehicle`,
      width: "30%"
    });

    ref.onClose.subscribe(obs => {
      if(!obs) {
        return;
      }

      rowData.plannedVehicleRegistration = obs;
      this.cellUpdate("plannedVehicleRegistration", rowData);
    });
  }
}
