import {ContactModel} from "./contact.model";
import {DriverRouteModel} from "./driver-route.model";

export class DriverDetailModel {
  contact: ContactModel;
  routes: DriverRouteModel[];

  constructor() {
    this.contact = new ContactModel();
    this.routes = [];
  }

  public static Copy(model: DriverDetailModel): DriverDetailModel {
    let contact = ContactModel.Copy(model.contact);

    let routes: DriverRouteModel[] = [];
    model.routes.forEach(route => {
      routes.push(DriverRouteModel.Copy(route));
    });

    let driverDetail = new DriverDetailModel();
    driverDetail.contact = contact;
    driverDetail.routes = routes;

    return driverDetail;
  }

  public validate(): boolean {
    if(!this.contact.validate()) {
      return false;
    }
    this.routes.forEach(route => {
      if(!route.validate()) {
        return false;
      }
    });

    return true;
  }
}
