import { Component, Input, Optional, Inject, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'slr-dialog-container',
  templateUrl: 'dialog-container.html',
  styleUrls: ['dialog-container.css']
})
export class DialogContainerComponent
{
  @Input()
  public title: string;

  @Input()
  public titleIcon: 'none' | 'info' | 'warn' | 'error' | 'action';

  @Input()
  public commitButtonTitle: string;

  @Input()
  public cancelButtonTitle: string;

  @Output()
  public init = new EventEmitter();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() private _dialogRef: MatDialogRef<DialogContainerComponent>)
  { }

  public ngOnInit()
  {
    if (this.data)
    {
      this.title = this.title || this.data.title || "Dialog";
      this.titleIcon = this.titleIcon || this.data.titleIcon || 'none';
      this.commitButtonTitle = this.commitButtonTitle || this.data.commitButtonTitle || "OK";
      this.cancelButtonTitle = this.cancelButtonTitle || this.data.cancelButtonTitle || "Cancel";
      this.init.emit(this.data || null);
    }
    else
    {
      this.title = this.title || "Dialog";
      this.init.emit();
    }
  }
}
