import {Component, OnInit} from "@angular/core";
import {NavigationStart, Router} from "@angular/router";
import {AuthService} from "@slender/auth";
import {PermissionConstants} from "../../constants/permission.constants";


@Component({
  selector: "navigation",
  templateUrl: "navigation.html",
  styleUrls: ["navigation.css"]
})
export class NavigationComponent implements OnInit {
  public _showFilter: boolean;
  public _showRatesFilter: boolean;

  public _superUser: boolean;
  public _claimUser: boolean;

  constructor(
    private _authService: AuthService,
    private _route: Router
  ) {
    this._showFilter = false;
    this._showRatesFilter = false;

    this._superUser = false;
    PermissionConstants.superUser.forEach(permission => {
      this._superUser = this._superUser || _authService.hasPermission(permission);
    });

    this._claimUser = false;
    this._claimUser = _authService.hasPermission(PermissionConstants.transporterClaim) || _authService.hasPermission(PermissionConstants.cloverClaim);

    this.showNavigationFilters();
  }

  public ngOnInit(): void {
  }

  // This method allows the navigation to show where it is suppose to show
  // It makes sure where and which search filter is shown
  private showNavigationFilters(): void {
    this._route.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        this.onRouteRefresh(event.url);
      } else {
        this.onRouteRefresh(this._route.url);
      }
    });
  }

  private onRouteRefresh(routeUrl: string): void {
    // Remove filters from Home
    this._showFilter = !(routeUrl === "/home" || routeUrl === "/site-management");

    // Determine which filters to show
    this._showRatesFilter = routeUrl === '/rates-management';
  }
}
