import {AppLayoutComponent} from "./_layout/layout.component";
import {AcceptRejectTripCellComponent} from "./accept-reject-trip-cell/accept-reject-trip-cell.component";
import {AcceptTripDialog} from "./accept-trip/accept-trip.dialog";
import {TripClaimDialog } from "./trip-claim-dialog/trip-claim-dialog.component";
import { TripClaimDialogStatusCellComponent } from "./trip-claim-dialog-status-cell/trip-claim-dialog-status-cell.component";
import {ClaimsManagementComponent} from "./claims-management/claims-management.component";
import {DialogMessageComponent} from "./dialog-message/dialog-message.component";
import {FileDownloadDialog} from "./file-download/file-download.dialog";
import {FileItemComponent} from "./file-item/file-item.component";
import {FileUploadDialog} from "./file-upload/file-upload.dialog";
import {ContactDetailsComponent} from "./home/contact-details/contact-details.component";
import {ContactGroupComponent} from "./home/contact-group/contact-group.component";
import {DriverDetailComponent} from "./home/driver-detail/driver-detail.component";
import {DriverRoutesComponent} from "./home/driver-routes/driver-routes.component";
import {HomeComponent} from "./home/home.component";
import {NoteEditorComponent} from "./home/note-editor/note-editor.component";
import {ShiftTableComponent} from "./home/shift-table/shift-table.component";
import {IssueSelectorComponent} from "./issue-selector/issue-selector.component";
import {NavigationComponent} from "./navigation/navigation.component";
import {PrimaryFilterComponent} from "./primary-filter/primary-filter.component";
import {RatesManagementComponent} from "./rates-management/rates-management.component";
import {RejectTripDialog} from "./reject-trip/reject-trip.dialog";
import {ShipmentBriefDialog} from "./shipment-brief/shipment-brief.dialog";
import {ShipmentManagementDialog} from "./shipment-management/shipment-management.dialog";
import {ShipmentStatusCellComponent} from "./shipment-status-cell/shipment-status-cell.component";
import {SiteManagementComponent} from "./site-management/site-management.component";
import {UserHaulierComponent} from "./site-management/user-haulier/user-haulier.component";
import {UserClaimComponent } from "./site-management/user-claim/user-claim.component";
import {ColumnFilterDialog} from "./table-display/column-filter/column-filter.dialog";
import {ColumnGroupComponent} from "./table-display/column-group/column-group.component";
import {TableDisplayComponent} from "./table-display/table-display.component";
import {TripManagementComponent} from "./trip-management/trip-management.component";
import { VehicleRegistrationDialog } from "./vehicle-registration/vehicle-registration.dialog";
import { TripClaimStatusCellComponent } from "./trip-claim-status-cell/trip-claim-status-cell.component";
import { ClaimBriefDialog } from "./claim-brief/claim-brief.dialog";
import { RejectClaimDialog } from "./reject-claim/reject-claim.dialog";

export {AppLayoutComponent};

export const COMPONENTS: any[] = [
  AcceptTripDialog,
  AcceptRejectTripCellComponent,
  AppLayoutComponent,
  ClaimsManagementComponent,
  TripClaimDialog,
  TripClaimDialogStatusCellComponent,
  ColumnFilterDialog,
  ColumnGroupComponent,
  ContactDetailsComponent,
  ContactGroupComponent,
  DialogMessageComponent,
  DriverDetailComponent,
  DriverRoutesComponent,
  FileDownloadDialog,
  FileItemComponent,
  FileUploadDialog,
  HomeComponent,
  IssueSelectorComponent,
  NavigationComponent,
  NoteEditorComponent,
  PrimaryFilterComponent,
  RatesManagementComponent,
  RejectTripDialog,
  ShiftTableComponent,
  ShipmentBriefDialog,
  ShipmentManagementDialog,
  ShipmentStatusCellComponent,
  SiteManagementComponent,
  TableDisplayComponent,
  TripManagementComponent,
  UserHaulierComponent,
  UserClaimComponent,
  VehicleRegistrationDialog,
  TripClaimStatusCellComponent,
  ClaimBriefDialog,
  RejectClaimDialog
];
