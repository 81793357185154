import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { COMPONENTS } from './components';
import { SERVICES } from './services';
import { CONTROLLERS } from './controllers';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,

    MatInputModule,
    MatButtonModule
  ],
  declarations: [COMPONENTS],
  providers: [SERVICES, CONTROLLERS]
})
export class SlenderAuthModule { }
