import {Component, OnInit} from "@angular/core";
import {Message} from "primeng/api";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FileType, FileTypeModel} from "../../models/file-data/file-type.model";
import {FileService} from "../../services/file.service";

@Component({
  templateUrl: "file-upload.html",
  styleUrls: ["file-upload.css"]
})
export class FileUploadDialog implements OnInit {
  private _isUndelivered: boolean;
  private _tripId: boolean;
  private _stringIds: string;

  public _fileTypes: FileTypeModel[];
  public _selectedFileType: FileTypeModel;

  public _displayUpload: boolean;
  public _uploadedFiles: any[];

  public _messages: Message[];

  private readonly _baseUrl: string;
  public _url: string;

  private _uploaded: boolean;

  constructor(
    private _config: DynamicDialogConfig,
    private _fileService: FileService,
    private _ref: DynamicDialogRef
  ) {
    this._messages = [];

    this._displayUpload = false;
    this._uploadedFiles = [];

    this._baseUrl = "/api/file";
    this._url = this._baseUrl;

    this._uploaded = false;
  }

  public ngOnInit(): void {
    if(!this._config.data.type) {
      this._messages.push({severity: "error", detail: "No type supplied."});
      this._ref.close();
    }

    if(this._config.data.type === FileType.brief) {
      this._fileTypes = FileTypeModel.getBriefTypes();
    } else if(this._config.data.type === FileType.claim) {
      this._fileTypes = FileTypeModel.getClaimTypes();
    }

    this._tripId = false;

    if (this._config.data.shipmentIds && this._config.data.type === FileType.brief) {
      this._stringIds = this._config.data.shipmentIds;
    }else if (this._config.data.tripId && this._config.data.type === FileType.claim) {
      this._stringIds = this._config.data.tripId;
      this._tripId = true;
      this
    }else {
      this._messages.push({severity: "error", detail: "Invalid shipment."});
      this._ref.close();
    }

    this._isUndelivered = false;
    if(this._config.data.isUndelivered) {
      this._isUndelivered = this._config.data.isUndelivered;
    }
  }

  public closeDialog(): void {
    this._ref.close(this._uploaded);
  }

  public onFileTypeChange(event: FileTypeModel): void {
    if(!event) {
      this._displayUpload = false;
      return;
    }

    this._selectedFileType = event;
    this._displayUpload = true;

    this._url = encodeURI(`${this._baseUrl}/${this._stringIds}/${this._selectedFileType.code}?isUndelivered=${this._isUndelivered}&tripId=${this._tripId}`);
  }

  public onError(event: File[]): void {
    event.forEach(model => {
      this._messages.push({severity: "error", detail: `Failed to upload: ${model.name}.`});
    });
  }

  public onUpload(event: File[]): void {
    event.forEach(model => {
      this._messages.push({severity: "success", detail: `${model.name} successfully uploaded.`})
    });

    this._uploaded = true;
  }
}
