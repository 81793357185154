import {Component, OnInit} from "@angular/core";
import {Message} from "primeng/api";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {IssueReasonModel} from "../../models/issue-reason/issue-reason.model";
import {ReasonInterface} from "../../models/issue-reason/reason.interface";
import {DialogResponseModel} from "../../models/response-status/dialog-response.model";
import {BriefService} from "../../services/brief.service";
import {TransportRequestService} from "../../services/transport-request.service";

@Component({
  templateUrl: "reject-trip.html",
  styleUrls: ["reject-trip.css"]
})
export class RejectTripDialog implements OnInit {
  // Dialog Inputs
  private _trCode: string;
  private _trId: number;

  // Form data
  public _loading: boolean;
  public _saving: boolean;

  public _selected: ReasonInterface;
  public _issueReasons: IssueReasonModel;
  public _rejectReasons: ReasonInterface[];

  public _messages: Message[];

  constructor(
    private _config: DynamicDialogConfig,
    private _briefService: BriefService,
    private _trService: TransportRequestService,
    private _ref: DynamicDialogRef
  ) {
    this._loading = true;
    this._saving = false;

    this._messages = [];

    this._issueReasons = new IssueReasonModel();
    this._rejectReasons = [];
  }

  public ngOnInit(): void {
    if(this._config.data.trCode) {
      this._trCode = this._config.data.trCode;
    }
    if(this._config.data.trId) {
      this._trId = this._config.data.trId;
    }

    this.loadReasons();
  }

  public cancelSubmit(): void {
    this._ref.close();
  }

  public onSubmit(): void {
    this._saving = true;

    this._trService.changeTransportRequestStatus(this._trCode, 0, 2070, this._selected.reason).subscribe(obs => {
      let dialogResponse = new DialogResponseModel("Reject");
      dialogResponse.addResponseRange(obs);

      this._ref.close(dialogResponse);
    }, error => {
      this._messages.push({severity: "error", detail: `Failed to update trip status. Error: ${error.message}.`});

      this._saving = false;
    });
  }

  private loadReasons(): void {
    this._briefService.getIssueReasons().subscribe(obs => {
      if(!obs) {
        return;
      }

      this._issueReasons = new IssueReasonModel(obs);

      this._issueReasons.tripReject.forEach(issueReason => {
        if(issueReason.reason) {
          issueReason.reason.forEach(reason => {
            this._rejectReasons.push(reason);
          });
        }
      });

      this._loading = false;
    }, error => {
      this._messages.push({severity: "error", summary: "Reject", detail: "Failed to load reject reasons."});
      this._loading = false;
    });
  }
}
