export class BriefAModel {
  transportRequestId: number;
  shipmentId: number;
  shipmentIds: string;
  vehicleRegistration?: string;
  tripStartKilometers: number;
  tripEndKilometers: number;
  actualDistance?: number;
  dispatchWeightVariance: number;
  driverName?: string;
  actualDeliveredWeight: number;

  constructor() {
    this.transportRequestId = 0;
    this.shipmentId = 0;
    this.shipmentIds = "";
    this.tripStartKilometers = 0;
    this.tripEndKilometers = 0;
    this.actualDistance = 0;
    this.dispatchWeightVariance = 0;
    this.actualDeliveredWeight = 0;
  }
}
