export class LogFilterModel
{
  public maxCount?: number;

  public startTime?: Date;
  public endTime?: Date;

  public type?: string;
  public user?: string;
  public source?: string;
  public message?: string;
}
