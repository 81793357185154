export class BriefActualDetailsModel {
  tripId: number;
  tripName: string;
  shipmentId: number;
  shipmentIds: string;

  actualCollectionArrivalDate: Date;
  actualCollectionDepartureDate: Date;
  actualDeliveryArrivalDate: Date;
  actualDeliveryDepartureDate: Date;
  invoiceSubmitted?: string;
  chepNumber?: string;

  constructor() {
    this.tripId = 0;
    this.tripName = "";
    this.shipmentId = 0;
    this.shipmentIds = "";

    this.actualCollectionArrivalDate = new Date(Date.now());
    this.actualCollectionDepartureDate = new Date(Date.now());

    this.actualDeliveryArrivalDate = new Date(Date.now());
    this.actualDeliveryDepartureDate = new Date(Date.now());
  }
}
