export class User
{
  public username: string;
  public organizationIds: string[];
  public partitionIds: string[];
  public name: string;
  public lastName: string;

  public get isValid(): boolean
  {
    return !!this.username &&
      !!this.name &&
      !!this.lastName
  }
}
