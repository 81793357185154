import {HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable()
export class DownloadController {
  constructor() {
  }

  public downloadFile(response: HttpResponse<Blob>): boolean {
    if(!response.body || response.body.size === 0) {
      return false;
    }

    let fileName = "DownloadedFile";
    let content = response.headers.get("Content-Disposition");
    if(content) {
      let idx = content.indexOf("filename");
      if(idx >= 0) {
        fileName = content.substring(idx + "filename=".length);
        idx = fileName.indexOf(";");
        fileName = fileName.substring(0, idx);
      }
    }

    let downloadedFile = new Blob([response.body], {type: response.body.type});

    let a = document.createElement("a");
    a.setAttribute("style", "display: none");

    document.body.appendChild(a);

    a.download = fileName ? fileName : "File";
    a.href = URL.createObjectURL(downloadedFile);
    a.target = "_blank";
    a.click();

    document.body.removeChild(a);

    return true;
  }
}
