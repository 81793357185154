import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FileLocationModel} from "../../models/file-data/file-location.model";
import {FileTypeModel} from "../../models/file-data/file-type.model";

@Component({
  selector: "file-item",
  templateUrl: "file-item.html",
  styleUrls: ["file-item.css"]
})
export class FileItemComponent implements OnInit {
  @Input() file: FileLocationModel;
  @Output() selected: EventEmitter<boolean>;

  public _checked: boolean;

  constructor() {
    this.selected = new EventEmitter<boolean>();

    this._checked = false;
  }

  public ngOnInit(): void {
  }

  public fileSelected(event: any): void {
    this.selected.emit(event);
  }

  public getTypeName(code: string): string {
    return FileTypeModel.getName(code);
  }
}
