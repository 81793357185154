import { SplitterBoxComponent } from "./splitter-box.component";
export * from "./splitter-box.component";

import { CollapseBoxComponent } from "./collapse-box.component";
import { CollapseHeaderComponent } from "./collapse-box-header.component";

export const COMPONENTS = [
  SplitterBoxComponent,
  CollapseBoxComponent,
  CollapseHeaderComponent
];
