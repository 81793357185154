import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from '../../services';
import {canvasAnimation, CloudAnim, headerAnimation, logoAnimation} from './animations';

@Component({
  templateUrl: 'login.html',
  styleUrls: ['login.css'],
  animations: [logoAnimation, headerAnimation, canvasAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent
{
  public status: string;
  public statusMessage: string;
  public disableLoginButton: boolean;

  public changePasswordMode: boolean;
  public newPasswordModel: any = {};

  public saltHash = this._authService.saltHash;
  public enablePasswordChange: boolean;

  public loggingIn = false;

  private _cloudAnim: CloudAnim;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _cd: ChangeDetectorRef) { }

  public ngOnInit()
  {
    this.enablePasswordChange = this._authService.config.enablePasswordChange;
    // this._cloudAnim = new CloudAnim();
    // this._cloudAnim.init();
  }

  public onLogin(usernameField: string, password: string)
  {
    let usernameFields = usernameField.split('@');
    let username = usernameFields[0];
    let system = usernameFields[1];

    //this.loggingIn = true;

    this.disableLoginButton = true;
    this._authService.getCredentials(username).subscribe(
      success =>
      {
        this._authService.login(system, username, this.saltHash(password, success)).subscribe(
          success => this.handleLoginSuccess(),
          err =>
          {
            if (err.status == 401 || err.status == 403)
            {
              this.setInvalid("Invalid login, please try again...");
            }
            else
            {
              this.setInvalid("Server Error: Please contact support!")
            }
          }
        )
      },
      err =>
      {
        if (err.status == 404)
        {
          this.setInvalid("Invalid login, please try again...");
        }
        else
        {
          this.setInvalid("Server Error: Please contact support!")
        }
      }
    );

    // this._authService.login(system, username, password).subscribe(
    //   success => this.handleLoginSuccess(),
    //   err =>
    //   {
    //     if (err.status == 401 || err.status == 403)
    //     {
    //       this.setInvalid("Invalid login, please try again...");
    //     }
    //     else
    //     {
    //       this.setInvalid("Server Error: Please contact support!")
    //     }
    //   });
  }

  public handleLoginSuccess()
  {
    if (this.changePasswordMode)
    {
      this._authService.changePassword(this.newPasswordModel.a).subscribe();
    }

    // this._cloudAnim.stop();
    this.loggingIn = true;
    this._cd.markForCheck();

    setTimeout(() => this._router.navigate(['/']), 500);
  }

  public setInvalid(message: string)
  {
    this.status = 'invalid';
    this.statusMessage = message;
    this._cd.markForCheck();

    setTimeout(() =>
    {
      this.status = 'retry';
      this.disableLoginButton = false;
      this.loggingIn = false;
      this._cd.markForCheck();
    }, 3000);
  }

  public isNewPasswordValid()
  {
    return !this.newPasswordModel.a || !this.newPasswordModel.b ||
      this.newPasswordModel.a == this.newPasswordModel.b;
  }
}
