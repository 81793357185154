import {Component, Input, OnInit} from "@angular/core";
import { PrimaryFilter } from "../../controllers";
import { DatePipe } from '@angular/common';
import { MessageService } from "primeng/api";


@Component({
  selector: "primary-filter",
  templateUrl: "primary-filter.html",
  styleUrls: ["primary-filter.css"],
  providers: [DatePipe, MessageService]
})
export class PrimaryFilterComponent implements OnInit {
  @Input() showRatesFilter: boolean;

  public _dateRange: Date[];
  public _monthPicked: Date;
  public _maxDate: Date;
  public _textSearch: string;

  constructor(
    private _filter: PrimaryFilter,
    private _datePipe: DatePipe,
    private _messageService: MessageService,
  ) {
    this._dateRange = _filter.dateRange;
    this._monthPicked = _filter.monthPicked;
    this._textSearch = _filter.textSearch;
  }

  public ngOnInit(): void {

  }

  public onFilterChange(): void {
    this.checkDateRange();
    this.checkMonthPicked();
    this.getMaxDate();

    this._filter.dateRange = this._dateRange;
    this._filter.monthPicked = this._monthPicked;
    this._filter.textSearch = this._textSearch;

  }
    
  public onFilterClear(): void {
    this._textSearch = "";
  }

  private checkDateRange(): void {
    if(!this._dateRange) {
      this._dateRange = [new Date()];
    }

    if(!this._dateRange[1]) {
      let newDate = new Date(this._dateRange[0]);
      newDate.setDate(newDate.getDate() + 1);

      this._dateRange[1] = newDate;
    }
  }

  private checkMonthPicked(): void {
    if(!this._monthPicked) {
      this._monthPicked = new Date();
    }
  }

  public getMaxDate(): void {
    let newDate = new Date(this._dateRange[0]);
    newDate.setDate(newDate.getDate() + 180);

    this._maxDate = newDate; 
    this.monthLimit();

  }

  public monthLimit(): void {
    let limitDate = new Date(this._maxDate);
    if (this._dateRange[1] > limitDate) {
      this._messageService.add({
        severity: "error",
        summary: "Date Range Info",
        detail: "Date range is restricted to 6 Months only per selection. Maximum date is until " + this._datePipe.transform(limitDate, 'dd-MM-yyyy') + " which is already loaded.",
        life: 10000,
        closable: false
        
      });
      this._dateRange[1] = limitDate;
    }
  }
} 
