import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { COMPONENTS } from './components';
import { PROVIDERS } from './providers';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule
  ],
  declarations: [COMPONENTS],
  entryComponents: [COMPONENTS],
  providers: [PROVIDERS],
  exports: [COMPONENTS]
})
export class SlenderModalsModule { }
