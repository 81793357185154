import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { DownloadController } from "../../controllers";
import { FileType } from "../../models/file-data/file-type.model";
import { DialogResponseModel } from "../../models/response-status/dialog-response.model";
import { ShipmentDetailModel } from "../../models/shipment-detail.model";
import { FileService } from "../../services/file.service";
import { TransportRequestService } from "../../services/transport-request.service";
import { FileDownloadDialog } from "../file-download/file-download.dialog";
import { FileUploadDialog } from "../file-upload/file-upload.dialog";
import { ShipmentBriefDialog } from "../shipment-brief/shipment-brief.dialog";

@Component({
  selector: "shipment-status-cell",
  templateUrl: "shipment-status-cell.html",
  styleUrls: ["shipment-status-cell.css"]
})
export class ShipmentStatusCellComponent implements OnInit {
  @Input() financeVar: boolean;
  @Input() readOnlyVar: boolean;
  @Input() row: ShipmentDetailModel;

  @Output() responseEmitter: EventEmitter<DialogResponseModel>;

  public _updating: boolean;

  private _isPublished: boolean;

  constructor(
    private _downloadController: DownloadController,
    private _dialogService: DialogService,
    private _fileService: FileService,
    private _messageService: MessageService,
    private _trService: TransportRequestService
  ) {
    this._updating = false;
    this._isPublished = false;

    this.responseEmitter = new EventEmitter<DialogResponseModel>();
  }

  public ngOnInit(): void {
    if(this.row) {
      this._isPublished = this.row.physicalStatusName === "Dispatched";
    }
  }

  public podShipment(): void {
    let detailCodes = [];
    detailCodes.push(`${this.row.shipmentIds};TR${this.row.transportRequestId}`);

    this.updateShipmentState(detailCodes, 4100);
  }

  public showBrief(): void {
    let requiresPo = this.row.requiresPo === 'T' || !this.row.requiresPo;

    if(requiresPo && !this.row.poNumber) {
      return;
    }

    const ref = this._dialogService.open(ShipmentBriefDialog, {
      closable: false,
      data: {
        transportDetails: this.row,
        readOnlyVar: false
      },
      dismissableMask: true,
      header: "Confirm Planned Information",
      contentStyle: {"max-height": "90%", "overflow": "auto"},
      height: "95%",
      width: "95%"
    });

    ref.onClose.subscribe(obs => {
      this.responseEmitter.emit(obs);
    });
  }

  public undeliverShipment(): void {
    let detailCodes = [];
    detailCodes.push(`${this.row.shipmentIds};TR${this.row.transportRequestId}`);

    this.updateShipmentState(detailCodes, 4080);
  }

  public downloadFile(): void {
    let shipmentId = this.row.shipmentId;

    const ref = this._dialogService.open(FileDownloadDialog, {
      data: {
        type: FileType.brief,
        fileId: shipmentId,
        shipment: true
      },
      header: "File Download",
      width: "75%"
    });
  }

  public uploadFile(): void {
    let shipmentId = this.row.shipmentIds;
    let isUndelivered = this.row.isUndelivered;

    const ref = this._dialogService.open(FileUploadDialog, {
      closable: false,
      data: {
        type: FileType.brief,
        shipmentIds: shipmentId,
        isUndelivered: isUndelivered
      },
      header: "File Upload",
      width: "85%"
    });

    ref.onClose.subscribe((obs: boolean) => {
      let dialogResponse = new DialogResponseModel("Upload");
      dialogResponse.addResponse({
        code: this.row.transportRequestId.toString(),
        isError: false,
        message: ""
      });

      if(!obs) {
        return;
      }

      this._updating = true;

      let detailCodes = [];
      detailCodes.push(`${this.row.shipmentIds};TR${this.row.transportRequestId}`);

      this._trService.changeShipmentStatus(detailCodes, 4090).subscribe(obs => {
        this.responseEmitter.emit(dialogResponse);
      });
    });
  }

  private updateShipmentState(detailCodes: string[], statusId: number): void {
    this._updating = true;

    this._trService.changeShipmentStatus(detailCodes, statusId).subscribe(obs => {
      if(!obs) {
        this._updating = false;
        return;
      }

      let response = new DialogResponseModel("Shipment");
      response.addResponseRange(obs);

      this.responseEmitter.emit(response);

      this._updating = false;
    }, error => {
      this._messageService.add(
        {
          severity: "error",
          summary: "Update Shipment",
          detail: `Failed to update shipment status: ${error}`
        });

      this._updating = false;
    });
  }
}
