export class ShipmentDetailModel {
  rowNumber: number;
  srReference: string;
  schDistance?: number;
  schEmptyLeg?: number;

  originSiteName: string;
  destinationSiteName: string;

  transportRequestId: number;
  shipmentId: number;

  shActualCollectDate?: Date;
  shActualDeliverDate?: Date;
  shLoadingDate?: Date;
  shDeliveryDate?: Date;

  srCode: string;
  srComment1: string;
  srComment2: string;
  srComment3: string;
  srComment5: string;

  shCode: string;
  shComment1: string;
  shComment2: string;
  shComment3: string;
  shComment5: string;
  shComment8: string;
  shPhysicalStatusId: number;
  physicalStatusName: string;

  shTotalWeightPlanned: number;
  shTotalWeightActual: number;

  collectionArrival?: Date;
  collectionDeparture?: Date;
  deliveryArrival?: Date;
  deliveryDeparture?: Date;

  totalWeightPlanned?: number;
  productClassCode: string;
  actualKms?: number;
  totalQtyPlanned?: number;
  totalQtyActual?: number;

  trComment4: string;
  trComment6: string;
  trComment7: string;
  trComment15: string;
  trComment16: string;

  actualCollectionArrival?: Date;
  actualCollectionDeparture?: Date;
  actualDeliveryArrival?: Date;
  actualDeliveryDeparture?: Date;

  actualDistanceIssue: string;
  actualDistanceReason: string;
  actualDistanceAddInfo: string;
  dispatchWeightIssue: string;
  dispatchWeightReason: string;
  dispatchWeightAddInfo: string;
  deliveredWeightIssue: string;
  deliveredWeightReason: string;
  deliveredWeightAddInfo: string;
  collectionArrivalIssue: string;
  collectionArrivalReason: string;
  collectionArrivalAddInfo: string;
  collectionDepartIssue: string;
  collectionDepartReason: string;
  collectionDepartAddInfo: string;
  deliveryArrivalIssue: string;
  deliveryArrivalReason: string;
  deliveryArrivalAddInfo: string;
  deliveryDepartIssue: string;
  deliveryDepartReason: string;
  deliveryDepartAddInfo: string;

  srAdditionalInformation: string;

  originAddress: string;
  destinationAddress: string;

  totalShipmentWeightPlanned?: number;
  dispatchedWeight?: number;
  unitWeight?: number;
  shpComment6: number;
  plannedWeight?: number;
  plannedDistance: number;

  originMapPointName: string;
  originMapPointCode: string;
  originAreaName: string;
  originAreaGroupName: string;

  terminusMapPointName: string;
  terminusMapPointCode: string;
  terminusAreaName: string;
  terminusAreaGroupName: string;

  poNumber?: string;
  requiresPo?: string;

  hasFile: boolean;
  briefCompleted: boolean;
  isUndelivered: boolean;

  tariffAmount?: number;

  shipmentIds: string;
  invoiceSubmitted?: string;
  chepNumber?: string;

}
