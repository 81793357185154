import { EventController } from './event.controller';
export * from './event.controller';

import { ErrorController } from './error.controller';
export * from './error.controller';

import { AsyncTaskController } from './async-task.controller';
export * from './async-task.controller';

import { TimeController } from './time.controller';
export * from './time.controller';

export const CONTROLLERS = [
  EventController,
  ErrorController,
  AsyncTaskController,
  TimeController
];
