import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//import { COMPONENTS } from './components';
//import { SERVICES } from './services';
//import { CONTROLLERS } from './controllers';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  providers: [
    //CONTROLLERS,
    //SERVICES
  ]
})
export class SlenderCoreModule { }
