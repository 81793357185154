import {Component, OnInit} from "@angular/core";
import {Message} from "primeng/api";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {AcceptedRequestModel} from "../../models/accepted-request.model";
import {DialogResponseModel} from "../../models/response-status/dialog-response.model";
import {TransportRequestVehicleModel} from "../../models/transport-request-vehicle.model";
import {TransportRequestService} from "../../services/transport-request.service";

@Component({
  templateUrl: "accept-trip.html",
  styleUrls: ["accept-trip.css"]
})
export class AcceptTripDialog implements OnInit {
  // Dialog Inputs
  private _haulierId: number;
  private _trCode: string;
  private _trId: number;
  private _vehicleRegistration: string;
  public _tripVehicleRegistrations: TransportRequestVehicleModel[];

  // Form data
  public _acceptedData: AcceptedRequestModel[];
  public _loading: boolean;
  public _selectedVehicle: TransportRequestVehicleModel;

  public _messages: Message[];
  public _saving: boolean;

  constructor(
    private _config: DynamicDialogConfig,
    private _tripService: TransportRequestService,
    private _ref: DynamicDialogRef
  ) {
    this._loading = true;
    this._saving = false;

    this._acceptedData = [];
    this._messages = [];
  }

  public ngOnInit(): void {
    if(this._config.data.trCode) {
      this._trCode = this._config.data.trCode;
    }
    if(this._config.data.trId) {
      this._trId = this._config.data.trId;
    }
    if(this._config.data.haulierId) {
      this._haulierId = this._config.data.haulierId;
    }
    if(this._config.data.vehicleRegistration) {
      this._vehicleRegistration = this._config.data.vehicleRegistration;
    }

    this.loadTripVehicles();
    this.loadDetails();
  }

  public cancelSubmit(): void {
    this._ref.close();
  }

  public onAcceptDateChange(id: number, value: Date): void {
    let idx = this._acceptedData.findIndex(d => d.id = id);
    if(idx === -1) {
      return;
    }

    this._acceptedData[idx].plannedDate = value;
  }

  public onSubmit(): void {
    this._saving = true;

    let dialogResponse = new DialogResponseModel("Confirm");

    this._acceptedData.forEach(data => {
      data.vehicleRegistration = this._selectedVehicle.vehicle;
      data.vehicleId = this._selectedVehicle.vehicleId;
    });

    this._tripService.saveAcceptedDetails(this._acceptedData).subscribe(obs => {
      if(!obs.isError) {
        this._tripService.changeTransportRequestStatus(this._trCode, this._haulierId, 2100, "").subscribe(obs => {
          dialogResponse.addResponseRange(obs);

          this._ref.close(dialogResponse);
        }, error => {
          this._messages.push({severity: "error", detail: `Failed to update trip status. Error: ${error.message}.`});
        });

        this._saving = false;

        return;
      }
      dialogResponse.addResponse(obs);

      this._ref.close(dialogResponse);
    }, error => {
      this._messages.push({severity: "error", detail: `Failed to save details. Error: ${error}.`});

      this._saving = false;
    });
  }

  private loadDetails(): void {
    this._tripService.getTransportRequestDetails(this._trId).subscribe(obs => {
      this._acceptedData = [];

      for(let i = 0; i < obs.length; i++) {
        let data = this._acceptedData.find(ad => ad.customerName === obs[i].terminusMapPointName);
        if(data) {
          data.shipmentId += `-${obs[i].shipmentId}`;
          continue;
        }

        let shipmentData: AcceptedRequestModel = {
          id: i,
          transportRequestId: obs[i].transportRequestId,
          shipmentId: `${obs[i].shipmentId}`,
          salesOrder: "",
          vehicleRegistration: this._vehicleRegistration,
          vehicleId: -1,
          customerName: obs[i].terminusMapPointName,
          plannedDate: obs[i].shDeliveryDate
        };

        this._acceptedData.push(shipmentData);
      }

      this._loading = false;
    }, error => {
      this._messages.push({severity: "error", summary: "Accept", detail: "Failed to load shipment details."});
      this._loading = false;
    });
  }

  public onVehicleChange(event: TransportRequestVehicleModel): void {
    if(event) {
      this._selectedVehicle = event;
    }
  }

  private loadTripVehicles(): void {
    this._tripService.getHaulierVehicles(this._trId).subscribe(obs => {
      if(!obs) {
        return;
      }
      this._tripVehicleRegistrations = obs;

      let idx = this._tripVehicleRegistrations.findIndex(v => v.vehicle === this._vehicleRegistration);
      if(idx >= 0) {
        this._selectedVehicle = this._tripVehicleRegistrations[idx];
      }
    }, error => {
      this._messages.push({severity: "error", detail: `Failed to load trip vehicles. Error: ${error.message}.`});
    });
  }
}
