import { NgModule, ModuleWithProviders, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';

import { DialogProvider } from '@slender/modals';

import { ErrorDetailsComponent, ErrorDetailsModalProvider } from './error-details';
import { LOGS_COMPONENTS } from './logs';

@NgModule({
  imports: [
    CommonModule,

    MatFormFieldModule, MatInputModule,
    MatButtonModule, MatButtonToggleModule,
    MatCheckboxModule, MatRadioModule, MatSelectModule,
    MatDialogModule,
    MatDatepickerModule, MatNativeDateModule
  ],
  declarations: [
    ErrorDetailsComponent,
    LOGS_COMPONENTS
  ],
  entryComponents: [
    ErrorDetailsComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-ZA' }
  ],
  exports: [
    ErrorDetailsComponent,
    LOGS_COMPONENTS
  ]
})
export class SlenderFoundationModule
{
  public static forRoot(): ModuleWithProviders<SlenderFoundationModule>
  {
    if (!SlenderFoundationModule._singleton)
    {
      SlenderFoundationModule._singleton = {
        ngModule: SlenderFoundationModule,
        providers: [
          DialogProvider,
          ErrorDetailsModalProvider
        ]
      };
    }

    return SlenderFoundationModule._singleton;
  }

  private static _singleton: ModuleWithProviders;
}
