import { Injectable } from '@angular/core'

import { SignalRService } from './signalR.service';

@Injectable()
export class PushEventService extends SignalRService<string>
{
  public connect()
  {
    super.connect("/events-hub");
  }

  public on(channel: string, fn: (value: any) => void)
  {
    this._hubConnection.on(channel, fn);
  }
}
