import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {AcceptedRequestModel} from "../models/accepted-request.model";
import {UpdateStatusResponseModel} from "../models/response-status/update-status-response.model";
import {ShipmentDetailModel} from "../models/shipment-detail.model";
import {TransportRequestUpdateModel} from "../models/transport-request-update.model";
import {TransportRequestVehicleModel} from "../models/transport-request-vehicle.model";
import {TransportRequestModel} from "../models/transport-request.model";

@Injectable()
export class TransportRequestService {
  constructor(private _http: HttpClient) {
  }

  public getTransportRequests(fromDate: Date, toDate: Date, filter?: string): Observable<TransportRequestModel[]> {
    let path = `/api/transportrequests`;

    let params = new HttpParams()
      .set("fromDate", fromDate.toISOString())
      .set("toDate", toDate.toISOString())
      .set("filter", filter ? filter : "");

    return this._http.get<TransportRequestModel[]>(path, {params: params});
  }

  public getUpdatedTransportRequests(fromDate: Date, toDate: Date, trCodes: string[]): Observable<TransportRequestModel[]> {
    let path = `/api/transportrequests/${trCodes}/updated`;

    let params = new HttpParams()
      .set("fromDate", fromDate.toISOString())
      .set("toDate", toDate.toISOString());

    return this._http.get<TransportRequestModel[]>(path, {params: params});
  }

  public getTransportRequestDetails(transportRequestId: number): Observable<ShipmentDetailModel[]> {
    let path = `/api/transportrequests/${transportRequestId}/details`;

    return this._http.get<ShipmentDetailModel[]>(path);
  }

  public getHaulierVehicles(transportRequestId: number): Observable<TransportRequestVehicleModel[]>
  {
    let path = `/api/transportrequests/${transportRequestId}/haulierVehicles`;
    return this._http.get<TransportRequestVehicleModel[]>(path);
  }

  public saveAcceptedDetails(data: AcceptedRequestModel[]): Observable<UpdateStatusResponseModel> {
    let path = `/api/transportrequests/accept`;
    return this._http.post<UpdateStatusResponseModel>(path, data);
  }

  public changeTransportRequestStatus(trCodes: string, haulierId: number, statusId: number, reason: string): Observable<UpdateStatusResponseModel[]> {
    let path = `/api/transportrequests/${trCodes}/${statusId}/status/${haulierId}`;

    let params = new HttpParams()
      .set("reason", JSON.stringify(reason));

    return this._http.post<UpdateStatusResponseModel[]>(path, "", {params: params});
  }

  public changeShipmentStatus(shipmentCodes: string[], statusId: number): Observable<UpdateStatusResponseModel[]> {
    let codes = shipmentCodes.join(",");

    let path = `/api/transportrequests/${codes}/${statusId}/shipmentStatus`;
    return this._http.post<UpdateStatusResponseModel[]>(path, "");
  }

  public updateTransportRequest(update: TransportRequestUpdateModel): Observable<UpdateStatusResponseModel> {
    let path = `/api/transportrequests/${update.requestId}/update`;
    return this._http.post<UpdateStatusResponseModel>(path, update);
  }

  public updateTransportRequestComments(transportRequestId: number, comment: string): Observable<UpdateStatusResponseModel> {
    let path = `/api/transportrequests/${transportRequestId}/comments`;

    let body = [
      comment
    ];

    return this._http.post<UpdateStatusResponseModel>(path, body);
  }


  public addComments(comments: string): Observable<any> {
    let path = `/api/TR/AddComments`;
    return this._http.post(path, comments);
  }

  public captureBriefActuals(briefActuals: any): Observable<any> {
    let path = `/api/TR/AddBriefActuals`;
    return this._http.post(path, briefActuals);
  }

  public getExportAll(exportSettings: any): Observable<any> {
    let path = `/api/TR/Export`;
    return this._http.post(path, exportSettings);
  }

  public getHaulierRatings(transportRequestId: number): Observable<any> {
    let path = `/api/TR/${transportRequestId}/GetHaulierRatings`;
    return this._http.get(path);
  }

  public getIsHaulierUser(username: string): Observable<string> {
    let path = `/api/TR/${username}/GetIsHaulierUser`;
    return this._http.get<string>(path);
  }

  public getTransportRequestShipmentLineItems(shipmentId: number): Observable<any> {
    let path = `/api/TR/${shipmentId}/GetTRShipmentLineItems`;
    return this._http.get(path);
  }

  public getTransportRequestShipments(transportRequestId: number): Observable<any> {
    let path = `/api/TR/${transportRequestId}/GetTRShipments`;
    return this._http.get(path);
  }

  public getTransportVouchers(transportRequestId: number): Observable<any> {
    let path = `/api/TR/${transportRequestId}/GetTransportVouchers`;
    return this._http.get(path);
  }

  public postAcceptBid(haulierId: number, tariffId: number, transportRequest: number, haulierRatingId: number): Observable<boolean> {
    let path = `/api/TR/${haulierId}/${tariffId}/${transportRequest}/${haulierRatingId}/AcceptBid`;
    return this._http.post<boolean>(path, "");
  }

  public postHaulierBid(haulierCode: string, amount: string, trCodes: string[]): Observable<boolean> {
    let path = `/api/TR/${haulierCode}/${amount}/${trCodes}/PushBid`;
    return this._http.post<boolean>(path, "");
  }

  public saveTripRow(tripRow: any): Observable<any> {
    let path = `/api/TR/AddTripRow`;
    return this._http.post(path, tripRow);
  }

  public testService(): Observable<any> {
    let path = `/api/TR/testService`;
    return this._http.get(path);
  }

  public runApProcess(trCodes: string): Observable<any> {
    let path = `/api/TR/${trCodes}/APProcess`;
    return this._http.post(path, "");
  }
}
