import { Component, OnInit } from "@angular/core";
import { AuthService } from "@slender/auth";
import { Message } from "primeng/api";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { PermissionConstants } from "../../constants/permission.constants";
import { DownloadController } from "../../controllers";
import { BriefAModel } from "../../models/brief-a.model";
import { BriefActualDetailsModel } from "../../models/brief-actual-details.model";
import { BriefExplanationModel } from "../../models/brief-explanation.model";
import { FileType } from "../../models/file-data/file-type.model";
import { IssueReasonModel } from "../../models/issue-reason/issue-reason.model";
import { DialogResponseModel } from "../../models/response-status/dialog-response.model";
import { ShipmentDetailModel } from "../../models/shipment-detail.model";
import { BriefService } from "../../services/brief.service";
import { FileService } from "../../services/file.service";
import { TransportRequestService } from "../../services/transport-request.service";
import { FileDownloadDialog } from "../file-download/file-download.dialog";
import { FileUploadDialog } from "../file-upload/file-upload.dialog";

@Component({
  templateUrl: "shipment-brief.html",
  styleUrls: ["shipment-brief.css"],
  providers: [DialogService]
})
export class ShipmentBriefDialog implements OnInit {
  public invalidTransportDetails: boolean;
  public _transportDetails: ShipmentDetailModel;

  public _briefA: BriefAModel;
  public _briefActualDetails: BriefActualDetailsModel;
  public _briefExplanations: BriefExplanationModel;
  private _productClassType: string;

  private _enableUpload: boolean;
  private _enableDownload: boolean;
  private _isUndelivered: boolean;
  public _shipmentHasFile: boolean;

  public _issueReasons: IssueReasonModel;

  public _actualDistanceVariance: number;
  private readonly _distanceVariancePercentage: number;

  public _deliveredWeightVariance: number;
  private _deliveredWeightVariancePercent: number;
  public _weightThreshold: number;
  private readonly _weightVariancePercentage: number;

  public _colArrivalMsg: string;
  public _colArrDiffMin: number;
  private _colArrDayDiffMin: number;
  public _isCollectionArrivalDateValid: boolean;
  public _isCollDelArrivalDateValid: boolean;
  public _isCollectionArrivalDate24Hours: boolean;
  private _collectionArrivalDateCheck: boolean;
  public _isCollectionDepartureDateValid: boolean;

  public _colDepartMsg: string;
  public _colDepartDiffMin: number;
  private _colDepartDayDiffMin: number;
  public _isCollDelDepartDateValid: boolean;
  public _isCollectionDepartureDate24Hours: boolean;
  private _collectionDepartureDateCheck: boolean;

  public _delArrivalMsg: string;
  public _delArrDiffMin: number;
  private _delArrDayDiffMin: number;
  public _isDeliveryArrivalDate24Hours: boolean;
  private _deliveryArrivalDateCheck: boolean;
  public _isDeliveryArrivalDateValid: boolean;
  public _isDelColArrivalDateValid: boolean;

  public _delDepartureMsg: string;
  public _delDepartDiffMin: number;
  private _delDepartDayDiffMin: number;
  public _isDeliveryDepartureDateValid: boolean;
  private _isDelColDepartDateValid: boolean;
  private _isDeliveryDepartureDate24Hours: boolean;
  private _deliveryDepartureDateCheck: boolean;

  public _loading: boolean;
  public _messages: Message[];
  public _readOnlyVar: boolean;
  public _saving: boolean;

  constructor(
    private _authService: AuthService,
    private _briefService: BriefService,
    private _config: DynamicDialogConfig,
    private _downloadController: DownloadController,
    private _dialogService: DialogService,
    private _fileService: FileService,
    private _ref: DynamicDialogRef,
    private _trService: TransportRequestService
  ) {
    this._loading = true;
    this._saving = false;
    this._messages = [];
    this._enableDownload = false;

    this._readOnlyVar = _authService.hasPermission(PermissionConstants.readOnlyUser);
    this.invalidTransportDetails = false;

    this._issueReasons = new IssueReasonModel();

    this._distanceVariancePercentage = 0.8;
    this._weightVariancePercentage = 0.1;

    this._actualDistanceVariance = 0;

    this._deliveredWeightVariance = 0;
    this._deliveredWeightVariancePercent = 0.5;
    this._weightThreshold = 0;

    this._collectionDepartureDateCheck = true;
    this._collectionArrivalDateCheck = true;
    this._deliveryDepartureDateCheck = true;
    this._deliveryArrivalDateCheck = true;
    this._isCollectionArrivalDate24Hours = true;
    this._isDeliveryArrivalDate24Hours = true;
    this._isCollectionDepartureDate24Hours = true;
    this._isDeliveryDepartureDate24Hours = true;

    this._colArrDayDiffMin = 0;
    this._colDepartDayDiffMin = 0;
    this._delArrDayDiffMin = 0;
    this._delDepartDayDiffMin = 0;
  }

  public ngOnInit(): void {
    if(this._config.data.transportDetails) {
      this._transportDetails = this._config.data.transportDetails;
      this.invalidTransportDetails = false;
    } else {
      this.invalidTransportDetails = true;
    }

    this.loadData();

    this.onStartKmChange(this._briefA.tripStartKilometers);
    this.onEndKmChange(this._briefA.tripEndKilometers);

    if(this._transportDetails.dispatchedWeight) {
      this.onDispatchedWeightChange(this._transportDetails.dispatchedWeight);
    }
    this.onDeliveredWeightChange(this._briefA.actualDeliveredWeight);

    this.onCollectionArrivalDateChange(this._briefActualDetails.actualCollectionArrivalDate);
    this.onCollectionDepartureDateChange(this._briefActualDetails.actualCollectionDepartureDate);
    this.onDeliveryArrivalDateChange(this._briefActualDetails.actualDeliveryArrivalDate);
    this.onDeliveryDepartureDateChange(this._briefActualDetails.actualDeliveryDepartureDate);
  }

  public cancelSubmit(): void {
    this._ref.close();
  }

  /**
   * Calculate minimum/maximum date range for specified date.
   * @param current
   * @return    Date[]    0 - minimum date, 1 - maximum date.
   */
  public calculateMinMaxDate(current: Date): Date[] {
    let defaultMax = new Date();
    let defaultMin = new Date();
    defaultMax.setDate(defaultMax.getDate() + 365);
    defaultMin.setDate(defaultMin.getDate() - 365);

    let maxDate = current ? new Date(current) : defaultMax;
    let minDate = current ? new Date(current) : defaultMin;

    maxDate.setDate(maxDate.getDate() + 30);
    minDate.setDate(minDate.getDate() - 30);

    return [
      minDate,
      maxDate
    ];
  }

  public issueReasonsSupplied(): boolean {
    if(this._actualDistanceVariance >= 15 && (!this._briefExplanations.actualDistanceIssue || !this._briefExplanations.actualDistanceReason)) {
      return false;
    }

    if(this._transportDetails.collectionArrival && this._colArrDiffMin > 6
      && (!this._briefExplanations.collectionArrivalIssue || !this._briefExplanations.collectionArrivalReason)
    ) {
      return false;
    }

    if(this._transportDetails.collectionDeparture && this._colDepartDiffMin > 6
      && (!this._briefExplanations.collectionDepartureIssue || !this._briefExplanations.collectionDepartureReason)
    ) {
      return false;
    }

    if(this._transportDetails.deliveryArrival && this._delArrDiffMin > 6
      && (!this._briefExplanations.deliveryArrivalIssue || !this._briefExplanations.deliveryArrivalReason)
    ) {
      return false;
    }

    if(this._transportDetails.deliveryDeparture && this._delDepartDiffMin > 6
      && (!this._briefExplanations.deliveryDepartureIssue || !this._briefExplanations.deliveryDepartureReason)
    ) {
      return false;
    }

    return true;
  }

  public onSubmit(): void {
    this.onCollectionArrivalDateChange(this._briefActualDetails.actualCollectionArrivalDate);
    this.onCollectionDepartureDateChange(this._briefActualDetails.actualCollectionDepartureDate);
    this.onDeliveryArrivalDateChange(this._briefActualDetails.actualDeliveryArrivalDate);
    this.onDeliveryDepartureDateChange(this._briefActualDetails.actualDeliveryDepartureDate);

    if (!this._briefActualDetails.invoiceSubmitted || !this._briefActualDetails.chepNumber) {
      return;
    }

    if(this._collectionDepartureDateCheck && this._collectionArrivalDateCheck && this._deliveryDepartureDateCheck
      && this._deliveryArrivalDateCheck && this._isCollectionArrivalDate24Hours && this._isDeliveryArrivalDate24Hours
      && this._isCollectionDepartureDate24Hours && this._isDeliveryDepartureDate24Hours
    ) {
      this._enableUpload = false;
      this._saving = true;

      this._briefService.captureBriefA(this._briefA).subscribe(obs => {
        let dialogResponse = new DialogResponseModel("Brief");
        dialogResponse.addResponse(obs);

        this._briefService.captureBriefExplanations(this._briefExplanations).subscribe(obs => {
          dialogResponse.addResponse(obs);

          this._briefService.captureBriefActuals(this._briefActualDetails).subscribe(obs => {
            dialogResponse.addResponse(obs);

            this._saving = false;
            this._messages.push({
              severity: "success",
              detail: "Feedback saved"
            });

            this._transportDetails.briefCompleted = true;
          }, error => {
            this._messages.push({severity: "error", detail: "Failed to update brief actuals."});
            this._saving = false;
          })
        }, error => {
          this._messages.push({severity: "error", detail: "Failed to update brief explanations."});
          this._saving = false;
        });
      }, error => {
        this._messages.push({severity: "error", detail: "Failed to update brief."});
        this._saving = false;
      });
    }
  }

  public onActualDistanceChange(event: number): void {
    this._briefA.actualDistance = event;
    this._actualDistanceVariance = Math.abs(this._transportDetails.plannedDistance - this._briefA.actualDistance);
  }

  public onCollectionArrivalDateChange(event: Date): void {
    if(!event) {
      return;
    }

    this._briefActualDetails.actualCollectionArrivalDate = event;

    if(this._transportDetails.collectionArrival) {
      this._colArrDiffMin = this.getTimeDifference(
        new Date(this._transportDetails.collectionArrival),
        this._briefActualDetails.actualCollectionArrivalDate
      );
      this._colArrDayDiffMin = this.getTimeDifference(
        this._briefActualDetails.actualCollectionDepartureDate,
        this._briefActualDetails.actualCollectionArrivalDate
      );
    }

    this._isCollectionArrivalDateValid = this.validateDepartureDate(this._briefActualDetails.actualCollectionDepartureDate, this._briefActualDetails.actualCollectionArrivalDate);
    this._isCollDelArrivalDateValid = this.validateDepartureDate(this._briefActualDetails.actualDeliveryArrivalDate, this._briefActualDetails.actualCollectionArrivalDate);

    if(this._colArrDayDiffMin < 24) {
      this._colArrivalMsg = "";
      this._isCollectionArrivalDate24Hours = true;
      this._collectionArrivalDateCheck = true;
    } else {
      this._colArrivalMsg = "Collection arrival time slot is more than 24 hours.\n";
      this._isCollectionArrivalDate24Hours = false;
      this._collectionArrivalDateCheck = false;
    }

    if(this._isCollectionArrivalDateValid && this._isCollDelArrivalDateValid) {
      this._isCollectionDepartureDateValid = true;
      this._collectionArrivalDateCheck = true;
    } else {
      if(!this._isCollDelArrivalDateValid) {
        this._colArrivalMsg += "\nCollection arrival date cannot be greater than delivery arrival date.\n";
      }
      if(!this._isCollectionArrivalDateValid) {
        this._colArrivalMsg += "\nCollection arrival date cannot be greater than departure date.\n";
      }

      this._collectionArrivalDateCheck = false;
    }
  }

  public onCollectionDepartureDateChange(event: Date): void {
    if(!event) {
      return;
    }

    this._briefActualDetails.actualCollectionDepartureDate = event;

    if(this._transportDetails.collectionDeparture) {
      this._colDepartDiffMin = this.getTimeDifference(
        new Date(this._transportDetails.collectionDeparture),
        this._briefActualDetails.actualCollectionDepartureDate
      );
      this._colDepartDayDiffMin = this.getTimeDifference(
        this._briefActualDetails.actualCollectionDepartureDate,
        this._briefActualDetails.actualCollectionArrivalDate
      );
    }

    this._isCollectionDepartureDateValid = this.validateDepartureDate(this._briefActualDetails.actualCollectionDepartureDate, this._briefActualDetails.actualCollectionArrivalDate);
    this._isCollDelDepartDateValid = this.validateDepartureDate(this._briefActualDetails.actualDeliveryDepartureDate, this._briefActualDetails.actualCollectionDepartureDate);

    if(this._colDepartDayDiffMin < 24) {
      this._colDepartMsg = "";
      this._isCollectionDepartureDate24Hours = true;
      this._collectionDepartureDateCheck = true;
    } else {
      this._colDepartMsg = "Collection departure time slot is more than 24 hours.\n";
      this._isCollectionDepartureDate24Hours = false;
      this._collectionDepartureDateCheck = false;
    }

    if(this._isCollectionDepartureDateValid && this._isCollDelDepartDateValid) {
      this._isCollectionArrivalDateValid = true;
      this._collectionDepartureDateCheck = true;
    } else {
      if(!this._isCollDelDepartDateValid) {
        this._colDepartMsg += "\nCollection departure date cannot be greater than delivery departure date.\n";
      }
      if(!this._isCollectionDepartureDateValid) {
        this._colDepartMsg += "\nCollection departure date cannot be earlier than arrival date.\n";
      }

      this._collectionDepartureDateCheck = false;
    }

    this.onCollectionArrivalDateChange(this._briefActualDetails.actualCollectionArrivalDate);
  }

  public onDeliveredWeightChange(event: number): void {
    this._briefA.actualDeliveredWeight = event;

    if(this._transportDetails.plannedWeight) {
      this._deliveredWeightVariance = Math.abs(this._transportDetails.plannedWeight - this._briefA.actualDeliveredWeight);
      if(this._productClassType.toLowerCase() === "bulk") {
        this._weightThreshold = Math.abs(this._transportDetails.plannedWeight * this._weightVariancePercentage);
      } else {
        this._weightThreshold = 45;
      }

      this._deliveredWeightVariancePercent = this._transportDetails.plannedWeight * this._weightVariancePercentage;
    }
  }

  public onDeliveryArrivalDateChange(event: Date): void {
    if(!event) {
      return;
    }

    this._briefActualDetails.actualDeliveryArrivalDate = event;

    if(this._transportDetails.deliveryArrival) {
      this._delArrDiffMin = this.getTimeDifference(
        new Date(this._transportDetails.deliveryArrival),
        this._briefActualDetails.actualDeliveryArrivalDate
      );
      this._delArrDayDiffMin = this.getTimeDifference(
        this._briefActualDetails.actualDeliveryDepartureDate,
        this._briefActualDetails.actualDeliveryArrivalDate
      );
    }

    this._isDeliveryArrivalDateValid = this.validateDepartureDate(this._briefActualDetails.actualDeliveryDepartureDate, this._briefActualDetails.actualDeliveryArrivalDate);
    this._isDelColArrivalDateValid = this.validateDepartureDate(this._briefActualDetails.actualDeliveryArrivalDate, this._briefActualDetails.actualCollectionArrivalDate);

    if(this._delArrDayDiffMin < 24) {
      this._delArrivalMsg = "";
      this._isDeliveryArrivalDate24Hours = true;
      this._deliveryArrivalDateCheck = true;
    } else {
      this._delArrivalMsg = "Delivery arrival time slot is more than 24 hours.\n";
      this._isDeliveryArrivalDate24Hours = false;
      this._deliveryArrivalDateCheck = false;
    }

    if(this._isDeliveryArrivalDateValid && this._isDelColArrivalDateValid) {
      this._isDeliveryDepartureDateValid = true;
      this._deliveryArrivalDateCheck = true;
    } else {
      if(!this._isDelColArrivalDateValid) {
        this._delArrivalMsg += "\nCollection arrival date cannot be greater than delivery arrival date.\n";
      }
      if(!this._isDeliveryArrivalDateValid) {
        this._delArrivalMsg += "\nDelivery departure date cannot be earlier than arrival date\n";
      }

      this._deliveryArrivalDateCheck = false;
    }
  }

  public onDeliveryDepartureDateChange(event: Date): void {
    if(!event) {
      return;
    }

    this._briefActualDetails.actualDeliveryDepartureDate = event;

    if(this._transportDetails.deliveryDeparture) {
      this._delDepartDiffMin = this.getTimeDifference(
        new Date(this._transportDetails.deliveryDeparture),
        this._briefActualDetails.actualDeliveryDepartureDate
      );
      this._delDepartDayDiffMin = this.getTimeDifference(
        this._briefActualDetails.actualDeliveryDepartureDate,
        this._briefActualDetails.actualDeliveryArrivalDate
      );
    }

    this._isDeliveryDepartureDateValid = this.validateDepartureDate(this._briefActualDetails.actualDeliveryDepartureDate, this._briefActualDetails.actualDeliveryArrivalDate);
    this._isDelColDepartDateValid = this.validateDepartureDate(this._briefActualDetails.actualDeliveryDepartureDate, this._briefActualDetails.actualCollectionDepartureDate);

    if(this._delDepartDayDiffMin < 24) {
      this._delDepartureMsg = "";
      this._isDeliveryDepartureDate24Hours = true;
      this._deliveryDepartureDateCheck = true;
    } else {
      this._delDepartureMsg = "Collection departure time slot is more than 24 hours.\n";
      this._isDeliveryArrivalDate24Hours = false;
      this._deliveryDepartureDateCheck = false;
    }

    if(this._isDeliveryDepartureDateValid && this._isDelColDepartDateValid) {
      this._isDeliveryArrivalDateValid = true;
      this._deliveryDepartureDateCheck = true;
    } else {
      if(this._isDelColDepartDateValid) {
        this._delDepartureMsg += "\nCollection departure date cannot be greater than delivery departure date.\n";
      }
      if(this._isDeliveryDepartureDateValid) {
        this._delDepartureMsg += "\nDelivery departure date cannot be earlier than arrival date.\n";
      }

      this._deliveryDepartureDateCheck = false;
    }

    this.onDeliveryArrivalDateChange(this._briefActualDetails.actualDeliveryArrivalDate);
  }

  public onDispatchedWeightChange(event: number): void {
    this._transportDetails.dispatchedWeight = event;

    if(this._transportDetails.plannedWeight) {
      if(this._productClassType.toLowerCase() === "bulk") {
        this._weightThreshold = Math.abs(this._transportDetails.plannedWeight * this._weightVariancePercentage);
      } else {
        this._weightThreshold = 45;
      }
    }
  }

  public onEndKmChange(event: number): void {
    this._briefA.tripEndKilometers = event;

    this._briefA.actualDistance = Math.abs(event - this._briefA.tripStartKilometers);
    this.onActualDistanceChange(this._briefA.actualDistance);
  }

  public onStartKmChange(event: number): void {
    this._briefA.tripStartKilometers = event;

    this._briefA.actualDistance = Math.abs(this._briefA.tripEndKilometers - event);
    this.onActualDistanceChange(this._briefA.actualDistance);
  }

  public showDownload(): void {
    let shipmentId = this._transportDetails.shipmentId;

    const ref = this._dialogService.open(FileDownloadDialog, {
      data: {
        type: FileType.brief,
        fileId: shipmentId,
        shipment: true
      },
      header: "File Download",
      width: "75%"
    });
  }

  public showUpload(): void {
    let shipmentId = this._transportDetails.shipmentIds;
    let isUndelivered = this._transportDetails.isUndelivered;

    const ref = this._dialogService.open(FileUploadDialog, {
      closable: false,
      data: {
        type: FileType.brief,
        shipmentIds: shipmentId,
        isUndelivered: isUndelivered
      },
      header: "File Upload",
      width: "85%"
    });

    ref.onClose.subscribe((obs: boolean) => {
      if(!obs) {
        return;
      }

      let detailCodes = [];
      detailCodes.push(`${this._transportDetails.shipmentIds};TR${this._transportDetails.transportRequestId}`);

      this._trService.changeShipmentStatus(detailCodes, 4090).subscribe(() => {
        // this._ref.close(dialogResponse);
        this._enableDownload = true;
      }, error => {
        this._messages.push({severity: "error", detail: "Failed to update shipment status"});
      });
    });
  }

  public getTimeDifference(startDate: Date, endDate: Date): number {
    let oneDay = (60 * 60 * 1000);

    return Math.round(
      Math.abs(
        startDate.getTime() - endDate.getTime()
      ) / oneDay
    );
  }

  public validateDepartureDate(endDate: Date, startDate: Date): boolean {
    if(endDate && startDate) {
      return endDate >= startDate;
    } else {
      return true;
    }
  }

  private loadData(): void {
    this.loadIssues();

    // Populate brief models
    this._briefA = new BriefAModel();
    this._briefActualDetails = new BriefActualDetailsModel();
    this._briefExplanations = new BriefExplanationModel();

    this._isUndelivered = this._transportDetails.isUndelivered;
    this._enableUpload = !this._isUndelivered;

    this._productClassType = this._transportDetails.productClassCode;

    this._briefA.transportRequestId = this._transportDetails.transportRequestId;
    this._briefA.shipmentId = this._transportDetails.shipmentId;
    this._briefA.shipmentIds = this._transportDetails.shipmentIds;

    this._briefExplanations.tripId = this._transportDetails.transportRequestId;
    this._briefExplanations.shipmentId = this._transportDetails.shipmentId;
    this._briefExplanations.shipmentIds = this._transportDetails.shipmentIds;
    this._briefExplanations.userName = this._authService.getUsername();

    this._briefActualDetails.tripId = this._transportDetails.transportRequestId;
    this._briefActualDetails.tripName = this._transportDetails.shCode;
    this._briefActualDetails.shipmentId = this._transportDetails.shipmentId;
    this._briefActualDetails.shipmentIds = this._transportDetails.shipmentIds;

    this._briefActualDetails.invoiceSubmitted = this._transportDetails.invoiceSubmitted;
    this._briefActualDetails.chepNumber = this._transportDetails.chepNumber;

    if(this._transportDetails.actualCollectionArrival) {
      this._enableUpload = false;
      this._briefActualDetails.actualCollectionArrivalDate = new Date(this._transportDetails.actualCollectionArrival);

      if(this._transportDetails.collectionArrival) {
        this._colArrDiffMin = this.getTimeDifference(
          this._briefActualDetails.actualCollectionArrivalDate,
          new Date(this._transportDetails.actualCollectionArrival)
        );
      }
    }
    if(this._transportDetails.actualCollectionDeparture) {
      this._briefActualDetails.actualCollectionDepartureDate = new Date(this._transportDetails.actualCollectionDeparture);

      if(this._transportDetails.collectionDeparture) {
        this._colDepartDiffMin = this.getTimeDifference(
          this._briefActualDetails.actualCollectionDepartureDate,
          new Date(this._transportDetails.collectionDeparture)
        );
      }
    }
    if(this._transportDetails.actualDeliveryArrival) {
      this._briefActualDetails.actualDeliveryArrivalDate = new Date(this._transportDetails.actualDeliveryArrival);

      if(this._transportDetails.deliveryArrival) {
        this._delArrDiffMin = this.getTimeDifference(
          this._briefActualDetails.actualDeliveryArrivalDate,
          new Date(this._transportDetails.deliveryArrival)
        );
      }
    }
    if(this._transportDetails.actualDeliveryDeparture) {
      this._briefActualDetails.actualDeliveryDepartureDate = new Date(this._transportDetails.actualDeliveryDeparture);

      if(this._transportDetails.deliveryDeparture) {
        this._delDepartDiffMin = this.getTimeDifference(
          this._briefActualDetails.actualDeliveryDepartureDate,
          new Date(this._transportDetails.deliveryDeparture)
        );
      }
    }

    let plannedWeight = this._transportDetails.plannedWeight ? this._transportDetails.plannedWeight : 0;
    let dispatchedWeight = this._transportDetails.dispatchedWeight ? this._transportDetails.dispatchedWeight : 0;
    this._briefA.dispatchWeightVariance = Math.abs(
      plannedWeight - dispatchedWeight
    );

    if(this._isUndelivered) {
      this._shipmentHasFile = false;
    } else {
      this._shipmentHasFile = this._transportDetails.hasFile;
    }

    this._briefA.driverName = this._transportDetails.trComment7;

    this._briefA.vehicleRegistration = this._transportDetails.trComment6;

    this._briefA.actualDistance = this._transportDetails.actualKms;
    this._briefA.tripStartKilometers = parseFloat(this._transportDetails.trComment15);
    this._briefA.tripEndKilometers = parseFloat(this._transportDetails.trComment16);
    this._briefA.actualDeliveredWeight = this._transportDetails.shpComment6;
    this._deliveredWeightVariance = Math.abs(plannedWeight - this._briefA.actualDeliveredWeight);

    this._briefExplanations.actualDistanceIssue = this._transportDetails.actualDistanceIssue;
    this._briefExplanations.actualDistanceReason = this._transportDetails.actualDistanceReason;
    this._briefExplanations.actualDistanceAddInfo = this._transportDetails.actualDistanceAddInfo;

    this._briefExplanations.dispatchWeightIssue = this._transportDetails.dispatchWeightIssue;
    this._briefExplanations.dispatchWeightReason = this._transportDetails.dispatchWeightReason;
    this._briefExplanations.dispatchWeightAddInfo = this._transportDetails.dispatchWeightAddInfo;

    this._briefExplanations.deliveredWeightIssue = this._transportDetails.deliveredWeightIssue;
    this._briefExplanations.deliveredWeightReason = this._transportDetails.deliveredWeightReason;
    this._briefExplanations.deliveredWeightAddInfo = this._transportDetails.deliveredWeightAddInfo;

    this._briefExplanations.collectionArrivalIssue = this._transportDetails.collectionArrivalIssue;
    this._briefExplanations.collectionArrivalReason = this._transportDetails.collectionArrivalReason;
    this._briefExplanations.collectionArrivalAddInfo = this._transportDetails.collectionArrivalAddInfo;

    this._briefExplanations.collectionDepartureIssue = this._transportDetails.collectionDepartIssue;
    this._briefExplanations.collectionDepartureReason = this._transportDetails.collectionDepartReason;
    this._briefExplanations.collectionDepartureAddInfo = this._transportDetails.collectionDepartAddInfo;

    this._briefExplanations.deliveryArrivalIssue = this._transportDetails.deliveryArrivalIssue;
    this._briefExplanations.deliveryArrivalReason = this._transportDetails.deliveryArrivalReason;
    this._briefExplanations.deliveryArrivalAddInfo = this._transportDetails.deliveryArrivalAddInfo;

    this._briefExplanations.deliveryDepartureIssue = this._transportDetails.deliveryDepartIssue;
    this._briefExplanations.deliveryDepartureReason = this._transportDetails.deliveryDepartReason;
    this._briefExplanations.deliveryDepartureAddInfo = this._transportDetails.deliveryDepartAddInfo;

    this._loading = false;
  }

  private loadIssues(): void {
    this._briefService.getIssueReasons().subscribe(obs => {
      if(!obs) {
        return;
      }
      this._issueReasons = new IssueReasonModel(obs);
    }, error => {
      this._messages.push(
        {severity: "error", detail: "Failed to load issue/reasons"}
      );
    });
  }

  // Only AlphaNumeric
  public keyPressAlphaNumeric(event: any) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
