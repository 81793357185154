import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable()
export class ExpeditorService {
  constructor(private _http: HttpClient) {
  }

  public getExpediterData(fromDate: Date, toDate: Date, pageNumber: number, pageSize: number, filter: string, multiFilter: string): Observable<any> {
    let path = `/api/em/Expeditor`;

    let queryString = `fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    queryString += `&columnFilters=${encodeURIComponent(filter)}`;
    queryString += `multiFilter=${encodeURIComponent(multiFilter)}`;

    return this._http.get(path, {params: {param: queryString}});
  }

  public getLatenessDateBoundaries(params: any): Observable<any> {
    let path = `/api/em/GetLatenessDates`;
    return this._http.post(path, params);
  }

  public getStatusAndReasons() {
    let path = `/api/em/StatusReasons`;
    return this._http.get(path);
  }

  public getTripMapDetails(transportRequestId: number): Observable<any> {
    let path = `/api/em/MapDetails?transportRequestID=${transportRequestId}`;
    return this._http.get(path);
  }

  public getUpdatedData(transportRequestId: number): Observable<any> {
    let path = `/api/em/ExpeditorByTR?TransportRequestID=${transportRequestId}`;
    return this._http.get(path);
  }

  public saveChangeLog(data: any): Observable<any> {
    let path = `/api/em/SaveChangeLogInfo`;
    return this._http.post(path, data);
  }

  public SaveExpediterChanges(data: any): Observable<any> {
    let path = `/api/em/SaveExpeditorInfo`;
    return this._http.post(path, data);
  }
}
