import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "@slender/auth";
import { AppStatus, AsyncTaskController } from "@slender/foundation";
import { MessageService } from "primeng/api";
import { DialogService, DynamicDialogConfig } from "primeng/dynamicdialog";
import { Observable, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { PermissionConstants } from "../../constants/permission.constants";
import { PrimaryFilterController } from "../../controllers";
import { ClaimModel } from "../../models/claim.model";
import { ClaimSaveModel } from "../../models/claim-save.model";
import { ColumnDefinitionModel } from "../../models/table/column-definition.model";
import { ColumnFilterModel } from "../../models/table/column-filter.model";
import { TableStateModel } from "../../models/table/table-state.model";
import { ClaimService } from "../../services/claim.service";
import { TableService } from "../../services/table.service";

@Component({
  selector: "trip-claim-dialog",
  templateUrl: "trip-claim.html",
  styleUrls: ["trip-claim.css"],
  providers: [DialogService]
})
export class TripClaimDialog implements OnInit
{
  private _destroy$: Subject<boolean>;

  public _loading: boolean;
  public _readOnlyVar: boolean;
  public _cloverClaim: boolean;
  public _transporterClaim: boolean;

  // Main Table
  public _availableColumns: ColumnDefinitionModel[];
  public _dataKey: string;
  public _tableData: ClaimSaveModel[];
  public _tableLoading: boolean;
  public _tableState: TableStateModel;

  private _taskId: string;
  private _claimRequest: ClaimSaveModel;

  constructor(
    private _authService: AuthService,
    private _asyncTaskController: AsyncTaskController,
    private _claimService: ClaimService,
    private _dialogService: DialogService,
    private _config: DynamicDialogConfig,
    private _filterController: PrimaryFilterController,
    private _messageService: MessageService,
    private _tableService: TableService
  ) {
    this._destroy$ = new Subject<boolean>();

    this._loading = true;
    this._tableLoading = true;

    this._readOnlyVar = _authService.hasPermission(PermissionConstants.readOnlyUser);
    this._cloverClaim = _authService.hasPermission(PermissionConstants.cloverClaim);
    this._transporterClaim = _authService.hasPermission(PermissionConstants.transporterClaim);

    this._tableData = [];
    this.setTable();
  }

  public ngOnInit(): void {
    this._asyncTaskController.updateStatus(new AppStatus("info", "Ready"));

    this.loadTableStates();

    this.loadTableData(this._config.data.claimDetails);
  }

  public onDataUpdate(tripId: number): void {

    let _trID: number;
    _trID = tripId;
  


    this._taskId = this._asyncTaskController.startTask("Updating");

    this.loadUpdateTableData(_trID);

    this._asyncTaskController.completeTask(this._taskId);
    
  } 

  public ngOnDestroy(): void {
    if (this._taskId) {
      this._asyncTaskController.completeTask(this._taskId);
    }

    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }

  public saveState(state: TableStateModel): void {
    this._tableService.SaveTableStates(state).subscribe(obs => {
      if (!obs) {
        this._messageService.add({
          severity: "warning",
          summary: "Table State",
          detail: "Failed to update table state."
        });
      }
    }, error => {
      this._messageService.add({ severity: "warning", summary: "Table State", detail: "Failed to update table state." });
    });
  }

  private loadTableData(row: ClaimSaveModel): void {
    if (row.transportRequestId === undefined || row.transportRequestId === null) {
      this._tableData = [];
      return;
    }
    this._tableLoading = true;

    this._taskId = this._asyncTaskController.startTask("Loading claims");

    //this._claimRequest = row;
    this.loadUpdateTableData(row.transportRequestId);
  }

  private loadUpdateTableData(tripId: number): void {

    this._claimService.loadClaimSavedData(tripId,0)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(obs => {
        this._tableLoading = false;

        if (!obs || obs.length === 0) {
          this._tableData = [];
          return;
        }

        this._tableData = obs.map(o => o);

        this._asyncTaskController.completeTask(this._taskId);
      }, error => {
        this._tableLoading = false;
        this._tableData = [];

        this._asyncTaskController.completeTask(this._taskId);
      });
  }

  private loadTableStates(): void {
    this._tableService.GetTableStates(this._tableState.name).subscribe(obs => {
      if (!obs) {
        this._loading = false;
        return;
      }

      let state: TableStateModel = JSON.parse(obs);
      if (state && state.name === this._tableState.name) {
        this._tableState = state;
      }
      this._loading = false;
    }, error => {
      this._loading = false;
      this._messageService.add({
        severity: "warning",
        summary: "Table State",
        detail: "Failed to restore Transport Request table state."
      });
    });
  }

  private setTable(): void {
    this._availableColumns = [
      { field: "claimId", header: "Claim ID", width: "150px", filter: true },
      { field: "claimer", header: "Claimed By", width: "200px", filter: true },
      { field: "handler", header: "Handled By", width: "150px", filter: true },
      //{ field: "transportRequestStatus", header: "Trip Status", width: "150px", filter: true },
      { field: "claimDetailsStatus", header: "Claim Status", width: "150px", filter: true },
      { field: "userGroup", header: "User Group", width: "175px", filter: true },
      { field: "rejectReason", header: "Reject Reason", width: "175px", filter: true },     
      ////{field: "haulierCode", header: "Haulier Code", width: "150px", filter: true},
      //{ field: "haulierName", header: "Transporter Name", width: "175px", filter: true },
      //{ field: "origin", header: "Collection Location", width: "200px", filter: true },
      //{ field: "destination", header: "Delivery Location", width: "200px", filter: true },
      {
        field: "incidentDate",
        header: "Incident Date",
        width: "175px",
        filter: true,
        dataType: "date",
        filterConstraint: new ColumnFilterModel("date")
      },
      {
        field: "claimDate",
        header: "Claim Lodge Date",
        width: "175px",
        filter: true,
        dataType: "date",
        filterConstraint: new ColumnFilterModel("date")
      }
    ];

    this._dataKey = "transportRequestId";
    this._tableState = {
      name: "claims-management-state-details"
    };
  }
}
