import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class SystemConfigService 
{
  constructor(private _http: HttpClient) { }

  public get(section: string): Observable<any>
  {
    return this._http.get(`config/system/${section}`);
  }
}
