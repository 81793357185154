import { HttpEventType } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Message } from "primeng/api";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { DownloadController } from "../../controllers";
import { FileLocationModel } from "../../models/file-data/file-location.model";
import { FileType, FileTypeModel } from "../../models/file-data/file-type.model";
import { FileService } from "../../services/file.service";

@Component({
  templateUrl: "file-download.html",
  styleUrls: ["file-download.css"],
  providers: [DialogService]
})
export class FileDownloadDialog implements OnInit {
  public _loading: boolean;
  public _messages: Message[];

  public _fileTypes: FileTypeModel[];
  public _selectedType: FileTypeModel;

  private _availableFiles: FileLocationModel[];
  public _displayedFiles: FileLocationModel[];
  public _selectedFiles: FileLocationModel[];

  constructor(
    private _config: DynamicDialogConfig,
    private _fileService: FileService,
    private _downloadController: DownloadController,
    private _ref: DynamicDialogRef
  ) {
    this._loading = true;
    this._messages = [];

    this._fileTypes = [];
    this._fileTypes.push({
      name: "All",
      code: "all"
    });

    this._availableFiles = [];
    this._displayedFiles = [];
    this._selectedFiles = [];
  }

  public ngOnInit(): void {
    if(!this._config.data.type) {
      this._messages.push({severity: "error", detail: "No type supplied."});
      this._ref.close();
    }

    if(this._config.data.type === FileType.brief) {
      FileTypeModel.getBriefTypes().forEach(model => {
        this._fileTypes.push(model);
      });
    } else if(this._config.data.type === FileType.claim) {
      FileTypeModel.getClaimTypes().forEach(model => {
        this._fileTypes.push(model);
      });
    }

    if (this._config.data.type === FileType.claim) {
      if (this._config.data.claimId) {
        this.loadFiles(this._config.data.claimId, 'exist');
      } else if (this._config.data.claimId === 0) {
        this.loadFiles(this._config.data.fileId, 'new');
      } else {  
        this._ref.close();
      } 
    } else {
      if (this._config.data.fileId) {
        this.loadFiles(this._config.data.fileId, 'exist');
      } else {
        this._ref.close();
      }
    }
  }

  public closeDialog(): void {
    this._ref.close();
  }

  public download(): void {
    this.downloadFiles(this._selectedFiles);
  }

  public downloadAll(): void {
    this.downloadFiles(this._displayedFiles);
  }

  public fileSelection(file: FileLocationModel, event: boolean): void {
    let idx = this._selectedFiles.findIndex(m => m.locationId === file.locationId);

    if(event && idx < 0) {
      this._selectedFiles.push(file);
    } else if(!event && idx >= 0) {
      this._selectedFiles.splice(idx, 1);
    }
  }

  public typeChange(event: FileTypeModel): void {
    if(!event) {
      this._selectedType = this._fileTypes[0];
    } else {
      this._selectedType = event;
    }

    if(!event || event.code === "all") {
      this._displayedFiles = this._availableFiles;
      return;
    }

    this._displayedFiles = [];
    this._availableFiles.forEach(model => {
      if(model.documentType === event.code) {
        this._displayedFiles.push(model);
      }
    });
  }

  private downloadFiles(files: FileLocationModel[]): void {
    files.forEach(file => {
      this._fileService.downloadFile(file.locationId).subscribe(obs => {
        if(obs.type === HttpEventType.Response) {
          if(!this._downloadController.downloadFile(obs)) {
            this._messages.push({severity: "error", detail: `Failed to download: ${file.fileName}.`});
            return;
          }
        }
      }, error => {
        this._messages.push({severity: "error", detail: `Failed to download: ${file.fileName}.`});
      })
    });
  }

  private loadFiles(fileId: number, downType: string): void {
    let shipment = !!this._config.data.shipment;

    this._fileService.getFiles(fileId, downType, shipment).subscribe(obs => {
      if(!obs) {
        this._messages.push({severity: "error", detail: "No files found."});

        this._loading = false;
      }

      this._availableFiles = obs;
      this._displayedFiles = this._availableFiles;

      this._loading = false;
    }, error => {
      this._messages.push({severity: "error", detail: "No files found."});

      this._loading = false;
    });
  }
}
