import {UpdateStatusResponseModel} from "./update-status-response.model";

export class DialogResponseModel {
  responseType: string;
  responses: UpdateStatusResponseModel[];

  constructor(type: string) {
    this.responseType = type;
    this.responses = [];
  }

  public addResponse(response: UpdateStatusResponseModel): void {
    this.responses.push(response);
  }

  public addResponseRange(responses: UpdateStatusResponseModel[]): void {
    responses.forEach(response => {
      this.responses.push(response);
    });
  }
}
