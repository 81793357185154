export class PermissionConstants {
  // Super user
  public static superUser = ["inhance system administrator", "super", "haulierwebsuper"];

  public static readOnlyUser = "ReadOnlyUser";
  public static financeUser = "FinanceController";
  public static haulier = "all haulier web";

  public static cloverClaim = "CloverClaim";
  public static transporterClaim = "TransporterClaim";
}
