export class ClaimModel {
  clientId: number;
  clientName: string;

  transportRequestId: number;
  transportRequestCode: string;
  transportRequestStatus: string;

  //claimStatus: string;

  haulierName: string;
  haulierCode: string;

  origin: string;
  destination: string;

  startDate: Date;

  //rejectReason: string;

  tripClaim: string;

  //claimLodgedBy: string;

  //userGroup: string;

  constructor() {
  }
}
