import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RateModel} from "../models/rate.model";

@Injectable()
export class RatesService {
  constructor(private _http: HttpClient) {
  }

  public getRates(filterDate: Date): Observable<RateModel[]>
  {
    let path = `/api/rates`;

    let params = new HttpParams()
      .set("date", filterDate.toISOString());

    return this._http.get<RateModel[]>(path, {params: params});
  }


  public exportAll(settings: any): Observable<any> {
    let path = `/api/RM/Export`;
    return this._http.post(path, settings);
  }
}
