import { Component, Input, Output, EventEmitter, HostListener, ElementRef, ViewChild, ContentChildren, Renderer2 } from '@angular/core';

@Component({
  selector: 'flx-splitter-box',
  templateUrl: 'splitter-box.html',
  styleUrls: ['splitter-box.css']
})
export class SplitterBoxComponent
{
  @Input()
  public direction: 'column' | 'row' = 'column';

  @Input()
  public viewBasis: number;

  @Input()
  public renderType: 'flex' | 'px' = 'flex';

  @Output()
  public changed = new EventEmitter<SplitterChangeEvent>();

  @Output()
  public change = new EventEmitter<SplitterChangeEvent>();

  public elA: HTMLElement;
  public elS: HTMLElement;
  public elB: HTMLElement;

  public elAWidth: number;
  public elAHeight: number;
  public elBWidth: number;
  public elBHeight: number;

  private _dragging: boolean;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2)
  { }

  public ngAfterViewInit()
  {
    if (this._elementRef.nativeElement.children.length == 3)
    {
      this.elA = this._elementRef.nativeElement.children[0];
      this.elS = this._elementRef.nativeElement.children[1];
      this.elB = this._elementRef.nativeElement.children[2];
    }
    else
    {
      throw new Error("flx-splitter-box requires two content elements with flx-splitter-box-item-a and -b");
    }

    this._renderer.setStyle(this._elementRef.nativeElement, "flex-direction", this.direction == "column" ? "row" : "column");

    if (this.viewBasis)
    {
      this.updateView();
    }
  }

  public onSplitterMouseDown(e: MouseEvent)
  {
    e.preventDefault();
    this._dragging = true;
  }

  @HostListener('document:mousemove', ['$event'])
  private onMouseMove(e: MouseEvent)
  {
    if (this._dragging)
    {
      let container = this._elementRef.nativeElement;
      if (this.direction == 'column')
      {
        this.elAWidth = (e.x - container.offsetLeft);
        this.elBWidth = container.offsetWidth - this.elAWidth - this.elS.offsetWidth;

        this.viewBasis = ((e.x - container.offsetLeft) / container.offsetWidth) * 100;
      }
      else if (this.direction == 'row')
      {
        this.elAHeight = (e.y - container.offsetTop);
        this.elBHeight = container.offsetHeight - this.elAHeight - this.elS.offsetHeight;

        this.viewBasis = ((e.y - container.offsetTop) / container.offsetHeight) * 100;
      }

      this.updateView();
    }
  }

  @HostListener('document:mouseup')
  private onMouseUp()
  {
    if (this._dragging)
    {
      this._dragging = false;
      this.changed.emit(this.getEventData());
    }
  }

  private updateView()
  {
    //console.log("Splitter Update View");

    if (this.renderType == 'flex')
    {
      this._renderer.setStyle(this.elA, 'flex', this.viewBasis + " 1 0");
      this._renderer.setStyle(this.elB, 'flex', (100 - this.viewBasis) + " 1 0");
    }
    else if (this.direction == 'column')
    {
      if (!this.elAWidth && !this.elBWidth)
      {
        this.elAWidth = this._elementRef.nativeElement.offsetWidth * (this.viewBasis / 100);
        this.elBWidth = this._elementRef.nativeElement.offsetWidth - this.elAWidth;
      }

      this._renderer.setStyle(this.elA, 'width', this.elAWidth + "px");
      this._renderer.setStyle(this.elB, 'width', this.elBWidth + "px");
    }
    else if (this.direction == 'row')
    {
      if (!this.elAHeight && !this.elBHeight)
      {
        this.elAHeight = this._elementRef.nativeElement.offsetHeight * (this.viewBasis / 100);
        this.elBHeight = this._elementRef.nativeElement.offsetHeight - this.elAHeight;
      }

      this._renderer.setStyle(this.elA, 'height', this.elAHeight + "px");
      this._renderer.setStyle(this.elB, 'height', this.elBHeight + "px");
    }

    this.change.emit(this.getEventData());
  }

  private getEventData()
  {
    let data = {
      viewBasis: this.viewBasis,
      panelA: {
        width: this.elA.offsetWidth,
        height: this.elA.offsetHeight
      },
      panelB: {
        width: this.elB.offsetWidth,
        height: this.elB.offsetHeight
      }
    };

    return data;
  }
}

export type SplitterChangeEvent = {
  viewBasis: number,
  panelA: { width: number, height: number },
  panelB: { width: number, height: number }
}
