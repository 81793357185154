import {Component, OnDestroy, OnInit} from "@angular/core";
import {AuthService} from "@slender/auth";
import {AsyncTaskController} from "@slender/foundation";
import {Message} from "primeng/api";
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {PermissionConstants} from "../../constants/permission.constants";
import {ShipmentDetailModel} from "../../models/shipment-detail.model";
import {ColumnDefinitionModel} from "../../models/table/column-definition.model";
import {ColumnFilterModel} from "../../models/table/column-filter.model";
import {TableStateModel} from "../../models/table/table-state.model";
import {TransportRequestModel} from "../../models/transport-request.model";
import {TableService} from "../../services/table.service";
import {TransportRequestService} from "../../services/transport-request.service";

@Component({
  templateUrl: "shipment-management.html",
  styleUrls: ["shipment-management.css"],
  providers: [DialogService]
})
export class ShipmentManagementDialog implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean>;

  public _availableColumns: ColumnDefinitionModel[];
  public _dataKey: string;
  public _tableData: ShipmentDetailModel[];
  public _tableLoading: boolean;
  public _tableState: TableStateModel;

  public _financeVar: boolean;
  public _loading: boolean;
  public _readOnlyVar: boolean;

  public _messages: Message[];

  private _taskId: string;
  private _transportRequest: TransportRequestModel;

  constructor(
    private _authService: AuthService,
    private _asyncTaskController: AsyncTaskController,
    private _config: DynamicDialogConfig,
    private _ref: DynamicDialogRef,
    private _tableService: TableService,
    private _tripService: TransportRequestService
  ) {
    this._destroy$ = new Subject<boolean>();

    this._tableLoading = true;

    this._financeVar = _authService.hasPermission(PermissionConstants.financeUser);
    this._readOnlyVar = _authService.hasPermission(PermissionConstants.readOnlyUser);

    this._tableData = [];
    this._messages = [];

    this.setTable();
  }

  public ngOnInit(): void {
    this.loadTableStates();

    this.loadData(this._config.data.selectedRow);
  }

  public ngOnDestroy(): void {
    if(this._taskId)
    {
      this._asyncTaskController.completeTask(this._taskId);
    }

    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }

  public loadTableStates(): void {
    this._tableService.GetTableStates(this._tableState.name).subscribe(obs => {
      if(!obs) {
        this._loading = false;
        return;
      }

      let state: TableStateModel = JSON.parse(obs);
      if(state && state.name === this._tableState.name) {
        this._tableState = state;
      }

      this._tableLoading = false;
    });
  }

  public onCellUpdate(field: string, row: ShipmentDetailModel): void {
    switch(field) {
      case "trComment4":
        this.saveTripComments(row);
        break;

      default:
    }
  }

  public onDataUpdate() {
    this.loadData(this._config.data.selectedRow);
  }

  public saveState(state: TableStateModel): void {
    this._tableService.SaveTableStates(state).subscribe();
  }

  private loadData(row: TransportRequestModel): void {
    if(row.transportRequestId === undefined || row.transportRequestId === null) {
      this._tableData = [];
      return;
    }

    this._tableLoading = true;

    this._taskId = this._asyncTaskController.startTask("Loading shipments");

    this._transportRequest = row;

    this._tripService.getTransportRequestDetails(row.transportRequestId)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(obs => {
        this._tableLoading = false;

        if(!obs || obs.length === 0) {
          this._tableData = [];
          return;
        }

        this._tableData = obs.map(o => o);

        this._tableData.forEach(row => {
          row.tariffAmount = this._transportRequest.plannedTariffAmount;
        });

        this._asyncTaskController.completeTask(this._taskId);
      }, error => {
        this._tableLoading = false;
        this._tableData = [];

        this._asyncTaskController.completeTask(this._taskId);
      });
  }

  private saveTripComments(row: ShipmentDetailModel): void {
    if(!row.transportRequestId) {
      return;
    }

    this._tripService.updateTransportRequestComments(row.transportRequestId, row.trComment4).subscribe(obs => {
      this._messages.push({
        severity: "success",
        detail: "Comments updated successfully"
      });

      this._tableData.forEach(model => {
        model.trComment4 = row.trComment4;
      });
    }, error => {
      this._messages.push({
        severity: "error",
        detail: "Failed to update comments"
      })
    });
  }

  private setTable(): void {
    this._availableColumns = [
      {
        field: "physicalStatusName",
        header: "Trip Status",
        width: "200px",
        filter: true
      },
      // {field: "srCode", header: "SR Code", width: "140px", filter: true},
      {field: "srReference", header: "SR Reference", width: "160px", filter: true},
      // {field: "originMapPointCode", header: "Origin Code", width: "115px", filter: true},
      {field: "originMapPointName", header: "Collection Location", width: "115px", filter: true},
      // {field: "originAreaName", header: "Origin Town", width: "115px", filter: true},
      {field: "originAreaGroupName", header: "Collection Area", width: "115px", filter: true},
      // {field: "terminusMapPointCode", header: "Destination Code", width: "155px", filter: true},
      // {field: "terminusMapPointName", header: "Destination Name", width: "155px", filter: true},
      {field: "destinationSiteName", header: "Delivery Location", width: "155px", filter: true},
      {field: "terminusAreaGroupName", header: "Delivery Area", width: "155px", filter: true},
      {field: "productClassCode", header: "Product Type", width: "155px", filter: true},
      {field: "srAdditionalInformation", header: "Clover Note", width: "180px", filter: true},
      // {field: "deliveryWindow", header: "Delivery Window", width: "180px", filter: true},
      // {
      //   field: "actualCollectionArrival",
      //   header: "Collection Arrival",
      //   width: "160px",
      //   filter: true,
      //   dataType: "date",
      //   filterConstraint: new ColumnFilterModel("date")
      // },
      // {
      //   field: "actualCollectionDeparture",
      //   header: "Collection Departure",
      //   width: "200px",
      //   filter: true,
      //   dataType: "date", filterConstraint: new ColumnFilterModel("date")
      // },
      // {
      //   field: "actualDeliveryArrival",
      //   header: "Delivery Arrival",
      //   width: "160px",
      //   filter: true,
      //   dataType: "date",
      //   filterConstraint: new ColumnFilterModel("date")
      // },
      // {
      //   field: "actualDeliveryDeparture",
      //   header: "Delivery Departure",
      //   width: "200px",
      //   filter: true,
      //   dataType: "date",
      //   filterConstraint: new ColumnFilterModel("date")
      // },
      // {field: "totalShipmentWeightPlanned", header: "Planned Shipment Weight", width: "180px", filter: false},
      // {field: "shCustomComment2", header: "Delivery Note Number", width: "160px", filter: true},
      {
        field: "shLoadingDate",
        header: "Collection Date",
        width: "160px",
        filter: true,
        dataType: "date",
        filterConstraint: new ColumnFilterModel("date")
      },
      {
        field: "shDeliveryDate",
        header: "Delivery Date",
        width: "200px",
        filter: true,
        dataType: "date",
        filterConstraint: new ColumnFilterModel("date")
      },
      // {field: "unitWeight", header: "Weight Actual", width: "160px", filter: true},
      // {field: "shpComment6", header: "Weight", width: "140px", filter: true},
      {field: "shTotalWeightActual", header: "Actual Unit Weight", width: "160px", filter: true, dataType: "number"},
      {field: "shTotalWeightPlanned", header: "Planned Unit Weight", width: "140px", filter: true, dataType: "number"},
      {field: "shComment1", header: "Comments", width: "130px", filter: true},
      // {field: "srCustomComment2", header: "Chain PO", width: "130px", filter: true},
      {field: "srComment1", header: "Country Code", width: "190px", filter: true},
      // {field: "shCustomComment8", header: "Bin Serial Number", width: "170px", filter: true},
      // {field: "shCustomComment5", header: "Chain Load Number", width: "180px", filter: true},
      // {field: "volume", header: "Volume", width: "140px", filter: true},
      // {field: "actualVolume", header: "Actual Volume", width: "140px", filter: true},
      {field: "originAddress", header: "Collection Address", width: "180px", filter: true},
      {field: "destinationAddress", header: "Delivery Address", width: "180px", filter: true},
      // {field: "productCode", header: "Product Code", width: "180px", filter: true},
      {field: "totalQtyPlanned", header: "Planned Quantity", width: "100px", filter: true, dataType: "number"},
      {field: "totalQtyActual", header: "Actual Quantity", width: "100px", filter: true, dataType: "number"},
      {field: "tariffAmount", header: "Tariff Amount", width: "180px", filter: false, dataType: "number"},
      {field: "trComment4", header: "Trip Comments", width: "160px", filter: true, editable: true}
    ];

    this._dataKey = "shipmentId";
    this._tableState = {
      name: "shipment-management-state"
    };
  }
}
