import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { COMPONENTS } from "./components";
import { DIRECTIVES } from "./directives";

import "style-loader!./assets/css/flex.css";

@NgModule({
  imports: [CommonModule],
  declarations: [COMPONENTS, DIRECTIVES],
  providers: [],
  exports: [COMPONENTS, DIRECTIVES]
})
export class SlenderFlexModule { }
