import { Component } from '@angular/core';

import { ConfigService } from '../services';

@Component({
  selector: 'slender-root',
  template: '<router-outlet></router-outlet>'
})
export class RootComponent
{
  constructor(private _configService: ConfigService) { }
}
