import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {BriefAModel} from "../models/brief-a.model";
import {BriefActualDetailsModel} from "../models/brief-actual-details.model";
import {BriefExplanationModel} from "../models/brief-explanation.model";
import {IssueReasonDto} from "../models/issue-reason/issue-reason.dto";
import {UpdateStatusResponseModel} from "../models/response-status/update-status-response.model";

@Injectable()
export class BriefService {
  constructor(private _http: HttpClient) {
  }

  public getIssueReasons(): Observable<IssueReasonDto[]> {
    let path = `/api/brief/issueReasons`;
    return this._http.get<IssueReasonDto[]>(path);
  }

  public captureBriefA(briefA: BriefAModel): Observable<UpdateStatusResponseModel> {
    // return $http.post('/api/TR/AddBriefA', briefA);
    let path = `/api/brief/a`;
    return this._http.post<UpdateStatusResponseModel>(path, briefA);
  }

  public captureBriefActuals(actuals: BriefActualDetailsModel): Observable<UpdateStatusResponseModel> {
    // return $http.post('/api/TR/AddBriefExplanations', briefExplanations);
    let path = `/api/brief/actuals`;
    return this._http.post<UpdateStatusResponseModel>(path, actuals);
  }

  public captureBriefExplanations(briefExplanations: BriefExplanationModel): Observable<UpdateStatusResponseModel> {
    // return $http.post('/api/TR/AddBriefActuals', briefActuals);
    let path = `/api/brief/explanations`;
    return this._http.post<UpdateStatusResponseModel>(path, briefExplanations);
  }
}
