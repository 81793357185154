import {Component, OnInit} from "@angular/core";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FilterUtils} from "primeng/utils";
import {ColumnFilterModel} from "../../../models/table/column-filter.model";
import {FilterTypeModel} from "../../../models/table/filter-type.model";

@Component({
  templateUrl: "column-filter.html",
  styleUrls: ["column-filter.css"]
})
export class ColumnFilterDialog implements OnInit {
  public _type: FilterTypeModel;
  public _value: any[];

  public _currentFilter: ColumnFilterModel;

  public readonly _selectOptions: FilterTypeModel[];

  constructor(
    private _config: DynamicDialogConfig,
    private _ref: DynamicDialogRef
  ) {
    this._selectOptions = [
      {type: "equals", symbol: "\u003d", description: "Equals"},
      {type: "notEquals", symbol: "\u2260", description: "Not Equals"},
      {type: "lt", symbol: "\u003c", description: "Less Than"},
      {type: "lte", symbol: "\u22dc", description: "Equals or Less Than"},
      {type: "gt", symbol: "\u003e", description: "Greater Than"},
      {type: "gte", symbol: "\u22dd", description: "Equals or Greater Than"},
      {type: "between", symbol: "\u22dc \u22dd", description: "Between Inclusive"}
    ];

    this._currentFilter = new ColumnFilterModel("text");
    this._value = [];
  }

  public static RegisterCustom(): void {
    // @ts-ignore
    FilterUtils["between"] = (value: any, filter: any[]): boolean => {
      if(value === undefined || value === null) {
        return false;
      }

      if(!filter || filter[0] === undefined || filter[0] === null || filter[1] === undefined || filter[1] === null) {
        return false;
      }

      let smallest = filter[0];
      let largest = filter[1];

      if(filter[1] < smallest) {
        smallest = filter[1];
        largest = filter[0];
      }

      return value >= smallest && value <= largest;
    }

    // @ts-ignore
    FilterUtils["dateEquals"] = (value: Date, filter: Date): boolean => {
      if(!value || !filter) {
        return false;
      }

      filter.setHours(0);
      filter.setMinutes(0);
      filter.setSeconds(0);

      let nextDate = new Date(filter);
      nextDate.setHours(23);
      nextDate.setMinutes(59);
      nextDate.setSeconds(59);

      return value >= filter && value <= nextDate;
    }

    // @ts-ignore
    FilterUtils["dateNotEquals"] = (value: Date, filter: Date): boolean => {
      if(!value || !filter) {
        return false;
      }

      filter.setHours(0);
      filter.setMinutes(0);
      filter.setSeconds(0);

      let nextDate = new Date(filter);
      nextDate.setHours(23);
      nextDate.setMinutes(59);
      nextDate.setSeconds(59);

      return value < filter || value > nextDate;
    }

    // @ts-ignore
    FilterUtils["dateLt"] = (value: Date, filter: Date): boolean => {
      if(!value || !filter) {
        return false;
      }

      filter.setHours(0);
      filter.setMinutes(0);
      filter.setSeconds(0);

      return value < filter;
    }

    // @ts-ignore
    FilterUtils["dateGt"] = (value: Date, filter: Date): boolean => {
      if(!value || !filter) {
        return false;
      }

      filter.setHours(23);
      filter.setMinutes(59);
      filter.setSeconds(59);

      return value > filter;
    }

    // @ts-ignore
    FilterUtils["dateLte"] = (value: Date, filter: Date): boolean => {
      if(!value || !filter) {
        return false;
      }

      filter.setHours(23);
      filter.setMinutes(59);
      filter.setSeconds(59);

      return value <= filter;
    }

    // @ts-ignore
    FilterUtils["dateGte"] = (value: Date, filter: Date): boolean => {
      if(!value || !filter) {
        return false;
      }

      filter.setHours(0);
      filter.setMinutes(0);
      filter.setSeconds(0);

      return value >= filter;
    }

    // @ts-ignore
    FilterUtils["dateBetween"] = (value: Date, filter: Date[]): boolean => {
      if(!value || !filter[0] || !filter[1]) {
        return false;
      }

      filter[0].setHours(0);
      filter[0].setMinutes(0);
      filter[0].setSeconds(0);

      filter[1].setHours(0);
      filter[1].setMinutes(0);
      filter[1].setSeconds(0);

      let smallest = new Date(filter[0]);
      let largest = new Date(filter[1]);

      if(filter[1] < smallest) {
        smallest = filter[1];
        largest = filter[0];
      }

      smallest.setHours(0);
      smallest.setMinutes(0);
      smallest.setSeconds(0);

      largest.setHours(23);
      largest.setMinutes(59);
      largest.setSeconds(59);

      return value >= smallest && value <= largest;
    }
  }
  
  public ngOnInit(): void {
    if(this._config.data.currentFilter) {
      this._currentFilter = this._config.data.currentFilter;

      if(this._currentFilter.filterType) {
        this._type = this._currentFilter.filterType;
        this._type.type = this.getType();
      }

      if(this._currentFilter.value) {
        if(this._currentFilter.dataType === "date") {
          if(!this._currentFilter.value[0]) {
            this._value[0] = new Date(Date.now());
          } else {
            this._value[0] = new Date(this._currentFilter.value[0]);  
          }

          if(!this._currentFilter.value[1]) {
            this._value[1] = new Date(Date.now());
          } else {
            this._value[1] = new Date(this._currentFilter.value[1]);
          }
        } else {
          this._value[0] = this._currentFilter.value[0];
          this._value[1] = this._currentFilter.value[1];
        }
      }
    }
  }

  public applyFilter(): void {
    this._currentFilter.filterType = this._type;
    this._currentFilter.filterType.type = this.setType();
    
    this._currentFilter.value = this._value;

    this._ref.close(this._currentFilter);
  }

  public clearFilter(): void {
    this._currentFilter.filterType = undefined;
    this._currentFilter.value[0] = "";
    this._currentFilter.value[1] = "";

    this._ref.close(this._currentFilter);
  }

  public filterValid(): boolean {
    if(this._value[0] === undefined || this._value[0] == null || this._value[0] === "") {
      return false;
    }

    if(!this._type || this._type.type === "between") {
      if(this._value[1] === undefined || this._value[1] == null || this._value[1] === "") {
        return false;
      }
    }

    return !!this._currentFilter;
  }
  
  private getType(): string {
    if(this._currentFilter.dataType !== "date") {
      return this._type.type;
    }

    switch(this._type.type) {
      case "dateEquals":
        return "equals";
      case "dateNotEquals":
        return "notEquals";
      case "dateLt":
        return "lt";
      case "dateLte":
        return "lte";
      case "dateGt":
        return "gt";
      case "dateGte":
        return "gte";
      case "dateBetween":
        return "between";

      default:
        return this._type.type;
    }
  }
  
  private setType(): string {
    if(this._currentFilter.dataType !== "date") {
      return this._type.type;
    }
    
    switch(this._type.type) {
      case "equals":
        return "dateEquals";
      case "notEquals":
        return "dateNotEquals";
      case "lt":
        return "dateLt";
      case "lte":
        return "dateLte";
      case "gt":
        return "dateGt";
      case "gte":
        return "dateGte";
      case "between":
        return "dateBetween";
        
      default:
        return this._type.type;
    }
  }
}
