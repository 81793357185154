import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable()
export class AuthInterceptorService {
  private readonly _tokenKey: string = "token";

  constructor() {
  }

  public request(config: any): Observable<any> {
    config.headers = config.headers || {};

    let authData = sessionStorage.getItem(this._tokenKey);
    if(authData) {
      config.headers.Authorization = `Bearer ${authData}`;
    }

    let observable = new Observable<any>(subscriber => {
      subscriber.next(config);
    });
    return observable;
  }

  public response(rejection: any): Observable<any> {
    if(rejection.response === 401) {
      let sessionToken = sessionStorage.getItem(this._tokenKey);
      if(!sessionToken) {
        sessionStorage.clear();
      }

      window.location.href = `/PTWeb/Login?type=NotLoggedIn`;
    }

    let observable = new Observable<any>(subscriber => {
      subscriber.error(rejection);
    });
    return observable;
  }
}
