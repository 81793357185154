import {Component, OnInit} from "@angular/core";
import {AuthService} from "@slender/auth";
import {MenuItem} from "primeng/api";
import {PermissionConstants} from "../../constants/permission.constants";

enum MenuAction {
  none,
  userHaulier,
  userClaim
}

@Component({
  selector: "site-management",
  templateUrl: "site-management.html",
  styleUrls: ["site-management.css"]
})
export class SiteManagementComponent implements OnInit {
  public _superUser: boolean;

  public _menuItems: MenuItem[];
  public _currentAction: MenuAction;

  constructor(
    private _authService: AuthService
  ) {
    this._superUser = false;
    PermissionConstants.superUser.forEach(permission => {
      this._superUser = this._superUser || _authService.hasPermission(permission);
    });

    this.loadMenuItems();
  }

  public ngOnInit(): void {
  }

  public getPanelHeader(): string {
    switch(this._currentAction) {
      case MenuAction.none:
        return "";

      case MenuAction.userHaulier:
        return "User Haulier Assignment";

      case MenuAction.userClaim:
        return "Claim Day(s) Limit Setting";

      default:
        return "";
    }
  }

  private loadMenuItems(): void {
    this._menuItems = [
      {
        label: "Users",
        items: [
          {
            label: "Haulier Assignment",
            command: () => { this._currentAction = MenuAction.userHaulier; }
          }
        ]
      }
      //,
      //{
      //  label: "Users",
      //  items: [
      //    {
      //      label: "Claim Day(s) Setting",
      //      command: () => { this._currentAction = MenuAction.userClaim; }
      //    }
      //  ]
      //}
    ];

    this._currentAction = MenuAction.userHaulier;
  }
}
