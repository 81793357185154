import { Observable, Subscriber } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class Broadcaster<T> extends Observable<T>
{
  public get value(): T { return this._value!; }
  protected _value: T | undefined;

  public get hasSubscribers(): boolean { return this._subscribers.some(s => !s.closed); }

  protected _enableReplay: boolean;

  protected _subscribers: Subscriber<T>[];

  constructor(enableReplay = false)
  {
    super(s =>
    {
      this._subscribers.push(s);
      if (this._enableReplay && this._value !== undefined)
      {
        s.next(this._value);
      }
    });

    this._enableReplay = enableReplay;
    this._subscribers = [];
  }

  public subscribeUntil(notifier: Observable<any>, next?: (value: T) => void, error?: (error: any) => void, complete?: () => void)
  {
    return super
      .pipe(takeUntil(notifier))
      .subscribe(next, error, complete);
  }

  public purgeOn(notifier: Observable<any>)
  {
    notifier.subscribe(() =>
    {
      this._value = undefined;
    });
  }

  //public broadcastIfChanged(data: T)
  //{
  //  if (!this._broadcast.equals(data))
  //  {
  //    this.broadcast(data);
  //  }
  //}

  //public broadcastIfChangedByValue(data: T)
  //{
  //  if (!this._broadcast.equalsByValue(data))
  //  {
  //    this.broadcast(data);
  //  }
  //}

  public broadcast(value?: T): void
  {
    if (value !== undefined)
    {
      this._value = value
    }

    if (this._value !== undefined)
    {
      this.publish(this._value);
    }
  }

  public rebroadcast(interceptFn?: (model: T) => T): void
  {
    let value = interceptFn && this._value ? interceptFn(this._value) : this._value;
    if (value !== undefined)
    {
      this.publish(value);
    }
  }

  protected publish(value: T): void
  {
    for (let i = 0; i < this._subscribers.length;)
    {
      if (this._subscribers[i].closed)
      {
        this._subscribers.splice(i, 1);
      }
      else
      {
        this._subscribers[i].next(value);
        i++;
      }
    }
  }
}
