export * from './infrastructure';

import { LogsViewComponent } from './components/logs-view.component';
import { LogsViewLiveComponent } from './components/logs-view-live.component';
import { LogItemComponent } from './components/log-item.component';
import { LogsFilterComponent } from './components/logs-filter.component';

export const LOGS_COMPONENTS = [
	LogsViewComponent,
	LogsViewLiveComponent,
	LogItemComponent,
	LogsFilterComponent
];

//export * from './controllers/logs.controller';

import { LoggingService } from './services/logging.service';
export * from './services/logging.service';

export const FEATURE_SERVICES = [
	LoggingService
];
