import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {ColumnDefinitionModel} from "../../../models/table/column-definition.model";

@Component({
  selector: "column-group",
  templateUrl: "column-group.html",
  styleUrls: ["column-group.css"]
})
export class ColumnGroupComponent implements OnInit, OnChanges {
  @Input() columns: ColumnDefinitionModel[];

  @Output() remove: EventEmitter<ColumnDefinitionModel>;

  public _columns: ColumnDefinitionModel[];

  constructor() {
    this._columns = [];

    this.remove = new EventEmitter<ColumnDefinitionModel>();
  }

  public ngOnInit(): void {
    if(this.columns) {
      this._columns = this.columns;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.columns) {
      this._columns = changes.columns.currentValue;
    }
  }

  public removeColumn(column: ColumnDefinitionModel): void {
    this.remove.emit(column);
  }
}
