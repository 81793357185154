export class BriefExplanationModel {
  tripId: number;
  shipmentId: number;
  shipmentIds: string;
  userName: string;

  actualDistanceIssue: string;
  actualDistanceReason: string;
  actualDistanceAddInfo: string;

  dispatchWeightIssue: string;
  dispatchWeightReason: string;
  dispatchWeightAddInfo: string;

  deliveredWeightIssue: string;
  deliveredWeightReason: string;
  deliveredWeightAddInfo: string;

  collectionArrivalIssue: string;
  collectionArrivalReason: string;
  collectionArrivalAddInfo: string;

  collectionDepartureIssue: string;
  collectionDepartureReason: string;
  collectionDepartureAddInfo: string;

  deliveryArrivalIssue: string;
  deliveryArrivalReason: string;
  deliveryArrivalAddInfo: string;

  deliveryDepartureIssue: string;
  deliveryDepartureReason: string;
  deliveryDepartureAddInfo: string;

  constructor() {
    this.tripId = 0;
    this.shipmentId = 0;
    this.shipmentIds = "";
    this.userName = "";

    this.actualDistanceIssue = "";
    this.actualDistanceReason = "";
    this.actualDistanceAddInfo = "";

    this.dispatchWeightIssue = "";
    this.dispatchWeightReason = "";
    this.dispatchWeightAddInfo = "";

    this.deliveredWeightIssue = "";
    this.deliveredWeightReason = "";
    this.deliveredWeightAddInfo = "";

    this.collectionArrivalIssue = "";
    this.collectionArrivalReason = "";
    this.collectionArrivalAddInfo = "";

    this.collectionDepartureIssue = "";
    this.collectionDepartureReason = "";
    this.collectionDepartureAddInfo = "";

    this.deliveryArrivalIssue = "";
    this.deliveryArrivalReason = "";
    this.deliveryArrivalAddInfo = "";

    this.deliveryDepartureIssue = "";
    this.deliveryDepartureReason = "";
    this.deliveryDepartureAddInfo = "";
  }
}
