import { Component, Input, Output, EventEmitter, ElementRef, ContentChild, ViewEncapsulation, ViewChild, HostListener, Renderer2, Host, OnInit, AfterViewInit } from '@angular/core';
import { Collapse_BOX_ANIMATION } from './collapse-box.animations';
import { CollapseHeaderTitleDirective } from '../directives/collapse-header-title.directive';

@Component({
  selector: 'flx-collapse-box',
  templateUrl: 'collapse-box.html',
  styleUrls: ['collapse-box.css'],
  animations: [Collapse_BOX_ANIMATION],
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'flx flx-collapse-box',
    '[class.v]': 'direction === "column"',
  }
})
export class CollapseBoxComponent implements OnInit, AfterViewInit
{
  @ContentChild(CollapseHeaderTitleDirective, { static: true, read: ElementRef }) headerTitleElement: ElementRef;

  @Input()
  public direction: 'column' | 'row' = 'column';

  @Input()
  public resize: boolean = false;

  @Input()
  public viewBasis: number;

  @Input() public set open(value)
  {
    this._open = value;
    this.state = this.open ? 'open' : 'closed';
  }

  public get open()
  {
    return this._open;
  }

  public get close()
  {
    return !this.open;
  }

  private get container()
  {
    return this._elementRef.nativeElement;
  }

  private _open: boolean = true;

  public state: string;

  private _parent: HTMLElement;
  private _minViewBasis: number = 0;
  private _currentViewBasis: number;
  private _dragging: boolean = false;

  constructor(private _elementRef: ElementRef,
    private _renderer: Renderer2)
  {
  }

  ngOnInit()
  {
    if (this.resize)
    {
      this.setContainerComponent();
    }
  }

  ngAfterViewInit()
  {
    if (this.resize)
    {
      this.setMinViewBasis();
    }
    if (this.viewBasis)
    {
      this._currentViewBasis = this.viewBasis;
      this.updateViewBasis();
    }
  }

  private setMinViewBasis()
  {
    if (this.direction === 'column')
    {
      this._minViewBasis = (this.container.offsetHeight / this._parent.offsetHeight) * 100;
    }
    else
      this._minViewBasis = (this.container.offsetWidth / this._parent.offsetWidth) * 100;
  }

  public onHandleMouseDown(e: MouseEvent)
  {
    e.preventDefault();
    this._dragging = true;
  }

  public toggle()
  {
    this.open = !this.open;

    this.updateViewBasis();
  }

  public changeLayoutOrientation()
  {
    if (this.direction === 'row' && this.headerTitleElement)
    {
      return false;
    }

    return true;
  }

  @HostListener('document:mousemove', ['$event'])
  private onMouseMove(e: MouseEvent)
  {
    if (this._dragging)
    {
      if (this.direction === 'column')
      {
        this._currentViewBasis = ((e.y - this.container.offsetTop) / this._parent.offsetHeight) * 100;
      }
      else
        this._currentViewBasis = ((e.x - this.container.offsetLeft) / this._parent.offsetWidth) * 100;

      this.updateViewBasis();
    }
  }

  @HostListener('document:mouseup')
  private onMouseUp()
  {
    if (this._dragging)
    {
      this._dragging = false;
    }
  }

  private setContainerComponent()
  {
    this._parent = this._renderer.parentNode(this.container);
  }

  private updateViewBasis()
  {
    if (this.open)
    {
      if (this._currentViewBasis >= this._minViewBasis && this._currentViewBasis <= 100)
      {
        this._renderer.setStyle(this.container, 'flex-basis', this._currentViewBasis + '%');
      }
    }
    else
      this._renderer.setStyle(this.container, 'flex-basis', 'auto');
  }
}
