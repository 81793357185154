import {ShiftEntryModel} from "./shift-entry.model";

export class ShiftsModel {
  entries: ShiftEntryModel[];
  text: string;

  constructor() {
    this.entries = [];
  }

  public static Copy(model: ShiftsModel): ShiftsModel {
    let shiftModel = new ShiftsModel();

    shiftModel.text = model.text;
    model.entries.forEach(shift => {
      shiftModel.entries.push(ShiftEntryModel.Copy(shift));
    });

    return shiftModel;
  }

  public validate(): boolean {
    let shiftsValid: boolean = true;

    this.entries.forEach(shift => {
      shiftsValid = shiftsValid && shift.validate();
    });

    return shiftsValid;
  }
}
