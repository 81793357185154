import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HaulierModel} from "../models/haulier.model";
import {UserHaulierModel} from "../models/user-haulier.model";

@Injectable()
export class UserService {
  constructor(private _http: HttpClient) {
  }

  public getUserHauliers(): Observable<UserHaulierModel[]> {
    let path = "/api/users/haulierMappings";

    return this._http.get<UserHaulierModel[]>(path);
  }

  public saveUserHauliers(userHaulier: UserHaulierModel): Observable<UserHaulierModel> {
    let path = "/api/users/haulierMappings";

    return this._http.post<UserHaulierModel>(path, userHaulier);
  }

  public getHauliers(): Observable<HaulierModel[]> {
    let path = "/api/users/hauliers";

    return this._http.get<HaulierModel[]>(path);
  }
}
