import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {ShiftEntryModel} from "Opsi.HaulierWeb.UI.Server/src/app/models/home/shift-entry.model";
import {ShiftsModel} from "../../../models/home/shifts.model";

@Component({
  selector: "shift-table",
  templateUrl: "shift-table.html",
  styleUrls: ["shift-table.css"]
})
export class ShiftTableComponent implements OnInit, OnChanges {
  @Input() shifts: ShiftsModel;
  @Input() superUser: boolean;
  @Output() update: EventEmitter<ShiftsModel>;

  public _edit: boolean;
  public _message: string;
  public _shifts: ShiftsModel;

  constructor() {
    this._edit = false;
    this._message = "";
    this._shifts = new ShiftsModel();

    this.update = new EventEmitter<ShiftsModel>();
  }

  public ngOnInit(): void {
    if(this.shifts) {
      this._shifts = ShiftsModel.Copy(this.shifts);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.shifts && changes.shifts.currentValue) {
      this._shifts = ShiftsModel.Copy(changes.shifts.currentValue);
    }
  }

  public deleteShift(shiftId: number): void {
    let idx = this._shifts.entries.findIndex(s => s.id === shiftId);
    this._shifts.entries.splice(idx, 1);
  }

  public editData(): void {
    this._edit = true;
  }

  public addData(): void {
    let id = 0;
    if(this._shifts.entries.length > 0) {
      id = this._shifts.entries[this._shifts.entries.length - 1].id + 1;
    }

    let shift = new ShiftEntryModel();
    shift.id = id;

    this._shifts.entries.push(shift);
  }

  public saveData(): void {
    let shifts: ShiftsModel = ShiftsModel.Copy(this._shifts);

    if(!shifts.validate()) {
      this._message = "Invalid shifts: Time Slot, Time From, and Time Tso required";
      return;
    } else {
      this._message = "";
    }

    this.update.emit(shifts);
    this._edit = false;
  }

  public cancelEdit(): void {
    this._edit = false;

    if(!this.shifts) {
      return;
    }

    this._shifts = ShiftsModel.Copy(this.shifts);
  }
}
