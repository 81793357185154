import { DynamicSeparatorDirective } from "./dynamic-sep.directive";
import { CollapseContentDirective } from './collapse-content.directive';
import { CollapseHeaderTitleDirective } from './collapse-header-title.directive';
import { CollapseHeaderContentDirective } from './collapse-header-content.directive';

export const DIRECTIVES = [
  DynamicSeparatorDirective,
  CollapseContentDirective,
  CollapseHeaderTitleDirective,
  CollapseHeaderContentDirective
];
