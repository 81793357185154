import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import { ClaimModel } from "../models/claim.model";
import { ClaimSaveModel } from "../models/claim-save.model";
import { UpdateStatusResponseModel } from "../models/response-status/update-status-response.model";
import { ClaimIssueReasonDto } from "../models/claim-issue-reason/claim-issue-reason.dto";

@Injectable()
export class ClaimService {
  constructor(private _http: HttpClient) {
  }

  public getAllClaims(fromDate: Date, toDate: Date): Observable<ClaimModel[]> {
    let path = `/api/claims`;

    let params = new HttpParams()
      .set("fromDate", fromDate.toISOString())
      .set("toDate", toDate.toISOString());

    return this._http.get<ClaimModel[]>(path, {params: params});
  }

  public getUpdatedClaims(fromDate: Date, toDate: Date, trCodes: string[]): Observable<ClaimModel[]> {
    let path = `/api/claims/${trCodes}/updated`;

    let params = new HttpParams()
      .set("fromDate", fromDate.toISOString())
      .set("toDate", toDate.toISOString());

    return this._http.get<ClaimModel[]>(path, { params: params });
  }

  public saveClaimData(claimData: ClaimSaveModel): Observable<UpdateStatusResponseModel> {
    let path = `/api/claims/saveClaim`;

    return this._http.post<UpdateStatusResponseModel>(path, claimData);
  }

  public changeClaimStatus(tripId: number, claimStatus: string, rejectReason: string, claimId: number | null): Observable<UpdateStatusResponseModel> {
    let path = `/api/claims/${tripId}/${claimStatus}/${rejectReason}/${claimId}/changeClaimStatus`;

    return this._http.post<UpdateStatusResponseModel>(path, "");
  }


  public getClaimIssueReasons(): Observable<ClaimIssueReasonDto[]> {
    let path = `/api/claims/issueReasons`;
    return this._http.get<ClaimIssueReasonDto[]>(path);
  }

  //ToDo: Return an Array when multiple claims will be allowed
  public loadClaimSavedData(transportRequestId: number, claimId: number): Observable<ClaimSaveModel[]> {
    let path = `/api/claims/${transportRequestId}/${claimId}/details`;

    return this._http.get<ClaimSaveModel[]>(path);
  }

}
