import { Injectable, Injector } from '@angular/core'
import { Broadcaster } from '@slender/core';
import { Info, Warning, ErrorDetails } from '../features';

@Injectable()
export class EventController
{
  public event = new Broadcaster();

  public info = new Broadcaster<Info>();
  public warning = new Broadcaster<Warning>();
  public error = new Broadcaster<ErrorDetails>();
}
