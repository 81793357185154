import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {filter, map} from "rxjs/operators";

@Injectable()
export class JsonNumberInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      filter(event => event instanceof HttpResponse && req.url.includes("api")),
      map((event: HttpResponse<any>) => {
        event = event.clone({
          body: this.convert(event.body)
        });
        
        return event;
      })
    );
  }
  
  private convert(body: any): any {
    if(body === null || body === undefined) {
      return body;
    }
    
    if(typeof body !== "object") {
      return body;
    }
    
    for(let key of Object.keys(body)) {
      let value = body[key];
      
      if(typeof value === "number") {
        let temp = body[key].toFixed(2);
        body[key] = parseFloat(temp);
      } else if(typeof value === "object") {
        body[key] = this.convert(value);
      }
    }
    
    return body;
  }
}
