import { Injectable } from '@angular/core';
import { Broadcaster } from '@slender/core';
import { ErrorDetails } from '../features';

@Injectable()
export class AsyncTaskController
{
  public taskChanges = new Broadcaster<AsyncTask[]>();
  public statusChanges = new Broadcaster<AppStatus>(true);

  private _tasks: AsyncTask[] = [];
  private _statusTimeout: any;

  public startTask(name: string): string
  {
    let task = new AsyncTask();
    task.id = Math.floor(Math.random() * 1000000000).toString();
    task.name = name;
    this._tasks.push(task);
    this.taskChanges.broadcast(this._tasks);

    return task.id;
  }

  public updateTask(id: string, progress: number)
  {
    let task = this._tasks.find(t => t.id == id);
    if (!!task)
    {
      task.progress = progress;
      this.taskChanges.broadcast(this._tasks);
    }
  }

  public completeTask(id: string)
  {
    let task = this._tasks.find(t => t.id == id);
    if (!!task)
    {
      task.isComplete = true;
      this.taskChanges.broadcast(this._tasks);
    }
  }

  public throwTaskError(id: string, error: any)
  {
    let task = this._tasks.find(t => t.id == id);
    if (!!task)
    {
      task.isComplete = true;
      task.error = ErrorDetails.from(error);
      this.taskChanges.broadcast(this._tasks);
    }
  }

  public updateStatus(status: AppStatus, timeout: number = 0)
  {
    this.statusChanges.broadcast(status);
    if (timeout > 0)
    {
      if (this._statusTimeout)
      {
        clearTimeout(this._statusTimeout);
      }
      this._statusTimeout = setTimeout(() =>
      {
        this.statusChanges.broadcast(new AppStatus('info', "Ready"));
        this._statusTimeout = null;
      }, timeout * 1000);
    }
  }
}

export class AppStatus
{
  public type: AppStatusType;
  public text: string;

  constructor(type: AppStatusType, text: string)
  {
    this.type = type;
    this.text = text;
  }
}

export type AppStatusType = 'info' | 'wait' | 'success' | 'warning' | 'error';

export class AsyncTask
{
  public id: string;
  public name: string;
  public isComplete: boolean;
  public progress: number;
  public error: ErrorDetails;
}
