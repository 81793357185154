import {Component, OnInit} from "@angular/core";
import {AuthService} from "@slender/auth";
import {MessageService} from "primeng/api";
import {PermissionConstants} from "../../constants/permission.constants";
import {ContactModel} from "../../models/home/contact.model";
import {DriverDetailModel} from "../../models/home/driver-details.model";
import {ShiftsModel} from "../../models/home/shifts.model";
import {HomeService} from "../../services/home.service";

class HomeData {
  data: string;
}

@Component({
  selector: "home-page",
  templateUrl: "home.html",
  styleUrls: ["home.css"],
  providers: [MessageService]
})
export class HomeComponent implements OnInit {
  public _isSuperUser: boolean;

  private _dataResult: any;
  public _drivers: DriverDetailModel[];
  public _expediters: ContactModel[];
  public _other: ContactModel[];
  public _shifts: ShiftsModel;
  public _notes: string;

  constructor(
    private _authService: AuthService,
    private _homeService: HomeService,
    private _messageService: MessageService
  ) {
    this._isSuperUser = false;

    PermissionConstants.superUser.forEach(permission => {
      this._isSuperUser = this._isSuperUser || this._authService.hasPermission(permission);
    });
  }

  public ngOnInit(): void {
    this.loadHomeData();
  }

  public updateContacts(type: string, contacts: ContactModel[]): void {
    switch(type) {
      case "expediter":
        this._expediters = [];
        contacts.forEach(contact => {
          this._expediters.push(ContactModel.Copy(contact));
        });
        break;

      case "other":
        this._other = [];
        contacts.forEach(contact => {
          this._other.push(ContactModel.Copy(contact));
        });
        break;

      default:
        this._other = [];
        contacts.forEach(contact => {
          this._other.push(ContactModel.Copy(contact));
        });
    }

    this.saveHomeData();
  }

  public updateDrivers(drivers: DriverDetailModel[]): void {
    this._drivers = [];
    drivers.forEach(driver => {
      this._drivers.push(DriverDetailModel.Copy(driver));
    });

    this.saveHomeData();
  }

  public updateShift(shifts: ShiftsModel): void {
    this._shifts = ShiftsModel.Copy(shifts);

    this.saveHomeData();
  }

  public updateNote(event: string): void {
    this._notes = JSON.parse(event);

    this.saveHomeData();
  }

  private loadHomeData(): void {
    this._homeService.GetHomeData().subscribe(obs => {
      if(!obs) {
        return;
      }

      let homeData: HomeData = obs;
      this._dataResult = JSON.parse(homeData.data);

      if(this._dataResult.hasOwnProperty("drivers")) {
        this._drivers = this._dataResult.drivers;
      }
      if(this._dataResult.hasOwnProperty("expediters")) {
        this._expediters = this._dataResult.expediters;
      }
      if(this._dataResult.hasOwnProperty("other")) {
        this._other = this._dataResult.other;
      }
      if(this._dataResult.hasOwnProperty("shifts")) {
        this._shifts = this._dataResult.shifts;
      }
      if(this._dataResult.hasOwnProperty("notes")) {
        this._notes = this._dataResult.notes;
      }
    }, error => {
      this._messageService.add({severity: "error", summary: "Load Data", detail: "Failed to load home data"});
      console.warn(error);
    });
  }

  private saveHomeData(): void {
    let dataResult = {
      "updatedOn": Date.now(),
      "drivers": this._drivers,
      "expediters": this._expediters,
      "other": this._other,
      "shifts": this._shifts,
      "notes": this._notes
    };

    this._homeService.SaveHomeData(dataResult).subscribe(() => {
      this._messageService.add({severity: "success", summary: "Save", detail: "Data saved."});
    }, error => {
      this._messageService.add({severity: "error", summary: "Save Data", detail: "Failed to save home data"});
      console.warn(error);
    });
  }
}
