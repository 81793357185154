import {AuthInterceptorService} from "./auth-interceptor.service";
import {BriefService} from "./brief.service";
import {ClaimService} from "./claim.service";
import {ExpeditorService} from "./expeditor.service";
import {FileService} from "./file.service";
import {HomeService} from "./home.service";
import {RatesService} from "./rates.service";
import {TableService} from "./table.service";
import {TransportRequestService} from "./transport-request.service";
import {UserProfileService} from "./user-profile.service";
import {UserService} from "./user.service";

export const SERVICES = [
  AuthInterceptorService,
  BriefService,
  ClaimService,
  ExpeditorService,
  FileService,
  HomeService,
  RatesService,
  TableService,
  TransportRequestService,
  UserProfileService,
  UserService
];
