import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ConfirmationService, Message} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {DialogResponseModel} from "../../models/response-status/dialog-response.model";
import {TransportRequestModel} from "../../models/transport-request.model";
import {TransportRequestService} from "../../services/transport-request.service";
import {AcceptTripDialog} from "../accept-trip/accept-trip.dialog";
import {RejectTripDialog} from "../reject-trip/reject-trip.dialog";

@Component({
  selector: "accept-reject-trip-cell",
  templateUrl: "accept-reject-trip-cell.html",
  styleUrls: ["accept-reject-trip-cell.css"],
  providers: [ConfirmationService, DialogService]
})
export class AcceptRejectTripCellComponent implements OnInit {
  @Input() readOnlyVar: boolean;
  @Input() row: TransportRequestModel;

  @Output() responseEmitter: EventEmitter<DialogResponseModel>;

  public _messages: Message[];
  public _row: TransportRequestModel;
  public _updating: boolean;

  constructor(
    private _confirmationService: ConfirmationService,
    private _dialogService: DialogService,
    private _tripService: TransportRequestService
  ) {
    this.responseEmitter = new EventEmitter<DialogResponseModel>();

    this._updating = false;
  }

  public ngOnInit(): void {
    if(this.row) {
      this._row = this.row;
       this._messages = [];
    }
  }

  public showAcceptDetails(): void {
    let trCode = this._row.code;
    let trId = this._row.transportRequestId;
    let vehicleRegistration = this._row.plannedVehicleRegistration;

    const ref = this._dialogService.open(AcceptTripDialog, {
      closable: false,
      data: {
        trCode: trCode,
        trId: trId,
        haulierId: this._row.haulierId,
        vehicleRegistration: vehicleRegistration
      },
      header: "Confirm Planned Information",
      width: "45%"
    });

    ref.onClose.subscribe(obs => {
      if(!obs) {
        return;
      }

      this.responseEmitter.emit(obs);
    });
  }

  public showRejectDetails(): void {
    let trId = this._row.transportRequestId;
    let trCode = this._row.code;

    const ref = this._dialogService.open(RejectTripDialog, {
      closable: false,
      data: {
        trCode: trCode,
        trId: trId
      },
      header: "Reject Shipment",
      width: "35%"
    });

    ref.onClose.subscribe(obs => {
      if(!obs) {
        return;
      }
      this.responseEmitter.emit(obs);
    });
  }

  public acceptTripConfirmation(): void {
    this._confirmationService.confirm({
      message: "Confirm trip acceptance?",
      header: "Accept Trip",
      icon: "pi pi-question-circle",
      accept: () => {
        this.acceptTrip();
      }
    });
  }

  private acceptTrip(): void {
    let dialogResponse = new DialogResponseModel("Accept");

    this._updating = true;

    this._tripService.changeTransportRequestStatus(
      this.row.code,
      0,
      2060,
      ""
    ).subscribe(obs => {
      dialogResponse.addResponseRange(obs);

      this._updating = false;

      this.responseEmitter.emit(dialogResponse);
    }, error => {
      this._messages.push({ severity: "error", detail: `Failed to update trip status. Error: ${error.message}.` });
    });
  }
}
