import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

import { ADMIN_COMPONENTS } from './admin';

@NgModule({
  imports: [
    CommonModule,

    MatInputModule,
    MatButtonModule,
    MatMenuModule
  ],
  declarations: [ADMIN_COMPONENTS],
  exports: [ADMIN_COMPONENTS]
})
export class SlenderAdminModule { }
