import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { TransportRequestModel } from "../../models/transport-request.model";
import { ClaimModel } from "../../models/claim.model";
import { ClaimBriefDialog } from "../claim-brief/claim-brief.dialog";
import { DialogResponseModel } from "../../models/response-status/dialog-response.model";
import { DialogService } from "primeng/dynamicdialog";
import { ClaimService } from "../../services/claim.service";
import { ConfirmationService, Message } from "primeng/api";
import { AuthService } from "@slender/auth";
import { UpdateStatusResponseModel } from "../../models/response-status/update-status-response.model";
import { TripClaimDialog } from "../trip-claim-dialog/trip-claim-dialog.component";
import { ClaimSaveModel } from "../../models/claim-save.model";

@Component({
  selector: "trip-claim-status-cell",
  templateUrl: "trip-claim-status-cell.html",
  styleUrls: ["trip-claim-status-cell.css"],
  providers: [ConfirmationService, DialogService]
})
export class TripClaimStatusCellComponent implements OnInit {
  @Input() type: string;
  @Input() transportRequestRow: TransportRequestModel;
  @Input() claimRow: ClaimSaveModel;
  //ToDo: Only one claimRow must be passed, refactor this
  @Input() claimRow1: ClaimModel;

  @Input() readOnlyVar: boolean;
  @Input() cloverClaim: boolean;
  @Input() transporterClaim: boolean;

  @Output() responseEmitter: EventEmitter<DialogResponseModel>;

  public _userName: string;
  public _readOnlyVar: boolean;
  public _cloverClaim: boolean;
  public _transporterClaim: boolean;

  public _isClaimLodger: boolean;
  public _isSameUserGroup: boolean;

  public _disableClaimView: boolean;

  private _claimDetails: ClaimSaveModel;
  private _claimRowDetails: ClaimModel;
  public _messages: Message[];

  constructor(
    private _confirmationService: ConfirmationService,
    private _dialogService: DialogService,
    private _claimService: ClaimService,
    private _authService: AuthService,
  ) {
    this.responseEmitter = new EventEmitter<DialogResponseModel>();
    this._claimDetails = new ClaimSaveModel();
    this._messages = [];

    this._isSameUserGroup = false;
    this._disableClaimView = false;
  }

  public ngOnInit(): void {
    this._claimDetails = this.claimRow;
    this._claimRowDetails = this.claimRow1;

    this._userName = this._authService.getUsername();
    this._readOnlyVar = this.readOnlyVar;
    this._cloverClaim = this.cloverClaim;
    this._transporterClaim = this.transporterClaim;

    //This Czhecks if the user that created the claim is the one 
    //viewing it at the moment
    //you cannot deal with your own claim
    if (this._claimDetails) {
      if (this._userName === this._claimDetails.userGroup) {
        this._isClaimLodger = true;
      }
      else {
        this._isClaimLodger = false;  
      }

      //This checks if the person is a clover user and if the 
      //same user is the one that created the claim.
      // Clover users cannot Deal with Clover created claims and Vice-versa for Transporters
      if (this._cloverClaim && this._claimDetails.userGroup === "Clover") {
        this._isSameUserGroup = true;
      }

      if (this._transporterClaim && this._claimDetails.userGroup === "Transporter") {
        this._isSameUserGroup = true;
      }

      if (!this.claimRow1.tripClaim) {
        this._disableClaimView = true;
      }
    }
  }

  //public acceptClaimCompletion(): void {
  //  this._confirmationService.confirm({
  //    message: "Confirm claim completion?",
  //    header: "Complete Claim",
  //    icon: "pi pi-question-circle",
  //    accept: () => {
  //      this.confirmClaim();
  //    }
  //  });
  //}

  //private confirmClaim(): void {
  //  let dialogResponse = new DialogResponseModel("Claim");

  //  this._claimService.changeClaimStatus(this.claimRow.transportRequestId, 'Claim Completed', ' ').subscribe(obs => {
  //    if (!obs) {
  //      return;
  //    }

  //    //let dialogResponse = new DialogResponseModel("Claim");
  //    dialogResponse.addResponse(obs);

  //    this.responseEmitter.emit(dialogResponse);
  //    this._messages.push({
  //      severity: "success",
  //      detail: "Status Updated to Completed."
  //    });

  //  }, error => {
  //    this._messages.push({ severity: "error", detail: "Failed to update claim status" });
  //  });
  //}

  public showClaimForm(): void {

    const ref = this._dialogService.open(ClaimBriefDialog, {
      data: {
        claimDetails: this._claimDetails,
        readOnlyVar: false,
        cloverClaim: this.cloverClaim,
        transporterClaim: this.transporterClaim,
        isClaimLodger: this._isClaimLodger,
        isSameUserGroup: this._isSameUserGroup,
        type: 'new'
      },
      header: "Lodge A Claim For " + this.claimRow1.transportRequestCode,
      width: "85%"
    });

    //ref.onClose.subscribe(obs => {
    //  if (!obs) {
    //    return;
    //  }

    //  this.responseEmitter.emit(obs);
    //});
    ref.onClose.subscribe(obs => {

      if (obs) {
        let updateResponse = new UpdateStatusResponseModel();
        updateResponse.isError = false;
        updateResponse.message = "Claim Updated";
        updateResponse.code = `${this.claimRow1.transportRequestCode}`;

        let response = new DialogResponseModel("Claims");
        response.addResponse(updateResponse);
        this.responseEmitter.emit(response);

      }
      
    });
  }  


  public showTripClaims(): void {

    const ref = this._dialogService.open(TripClaimDialog, {
      data: {
        claimDetails: this._claimDetails,
        readOnlyVar: false,
        cloverClaim: this.cloverClaim,
        transporterClaim: this.transporterClaim,
        isClaimLodger: this._isClaimLodger,
        isSameUserGroup: this._isSameUserGroup
      },
      header: "View Claims For " + this.claimRow1.transportRequestCode,
      width: "85%"
    });

    ref.onClose.subscribe(obs => {

      if (obs) {
        let updateResponse = new UpdateStatusResponseModel();
        updateResponse.isError = false;
        updateResponse.message = "Claim Updated";
        updateResponse.code = `${this._claimDetails.transportRequestId}`;

        let response = new DialogResponseModel("Claims");
        response.addResponse(updateResponse);
        this.responseEmitter.emit(response);

      }

    });
  }  
}
