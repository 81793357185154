import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {TableStateModel} from "../models/table/table-state.model";
import {UserProfileService} from "./user-profile.service";

@Injectable()
export class TableService {
  constructor(
    private _http: HttpClient,
    private _userProfileService: UserProfileService
  ) {
  }

  public GetTableStates(name: string): Observable<any> {
    let obj = localStorage.getItem(name);
    let data: any = null;
    if(obj) {
      data = obj;
    }

    return new Observable<any>(subscriber => {
      subscriber.next(data);
    });
  }

  public SaveTableStates(data: TableStateModel): Observable<any> {
    if(data.name != "") {
      localStorage.setItem(data.name, JSON.stringify(data));
      // this._userProfileService.setUserProfile().subscribe();
    }

    return new Observable<any>(subscriber => {
      subscriber.next(true)
    });
  }
}
