import { HubConnectionBuilder, HubConnection } from '@aspnet/signalr';
import { Broadcaster } from '@slender/core';

export abstract class SignalRService<T>
{
  public broadcaster = new Broadcaster<T>();

  protected _hubConnection: HubConnection;

  public connect(url: string, channel?: string)
  {
    if (!this._hubConnection)
    {
      this._hubConnection = new HubConnectionBuilder()
        .withUrl(url)
        .build();

      this._hubConnection.onclose(err =>
      {
        console.log("Connection lost: " + err);
        setTimeout(() => this.start(), 100);
      });

      if (channel)
      {
        this._hubConnection.on(channel, value => this.onReceive(value));
      }

      this.start();
    }
  }

  public start()
  {
    this._hubConnection.start()
      .then(() => console.debug('Connection started'))
      .catch(err =>
      {
        console.error(err.toString());
        setTimeout(() => this.start(), 5000);
      });
  }

  protected onReceive(value: any)
  {
    this.broadcaster.broadcast(value);
  }
}
