export class DriverRouteModel {
  id: number;
  origin: string;
  direction: string;
  destination: string;
  vehicleType: string;

  constructor() {
    this.id = -1;
  }

  public static Copy(model: DriverRouteModel): DriverRouteModel {
    let route = new DriverRouteModel();

    route.id = model.id;
    route.origin = model.origin;
    route.direction = model.direction;
    route.destination = model.destination;
    route.vehicleType = model.vehicleType;

    return route;
  }

  public validate(): boolean {
    if(!this.origin) {
      return false;
    }
    if(!this.direction) {
      return false;
    }
    if(!this.destination) {
      return false;
    }
    if(!this.vehicleType) {
      return false;
    }

    return true;
  }
}
