import {CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {SlenderFlexModule} from "@slender/flex";

import {SlenderFoundationModule} from "@slender/foundation";

import {AppRoutingModule} from "./app-routing.component";
import {COMPONENTS} from "./components";
import {AcceptTripDialog} from "./components/accept-trip/accept-trip.dialog";
import {FileDownloadDialog} from "./components/file-download/file-download.dialog";
import {FileUploadDialog} from "./components/file-upload/file-upload.dialog";
import {RejectTripDialog} from "./components/reject-trip/reject-trip.dialog";
import { ShipmentBriefDialog } from "./components/shipment-brief/shipment-brief.dialog";
import { ClaimBriefDialog } from "./components/claim-brief/claim-brief.dialog";
import {ShipmentManagementDialog} from "./components/shipment-management/shipment-management.dialog";
import {ColumnFilterDialog} from "./components/table-display/column-filter/column-filter.dialog";
import {VehicleRegistrationDialog} from "./components/vehicle-registration/vehicle-registration.dialog";

import {CONTROLLERS} from "./controllers";
import {JsonDateInterceptor} from "./interceptors/json-date.interceptor";
import {JsonNumberInterceptor} from "./interceptors/json-number.interceptor";
import {PrimeClassModule} from "./primeng.module";
import {SERVICES} from "./services";

@NgModule({
  imports: [
    HttpClientModule,

    CommonModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,

    SlenderFoundationModule,
    SlenderFlexModule,

    AppRoutingModule,
    PrimeClassModule,
  ],
  declarations: [COMPONENTS],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JsonDateInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JsonNumberInterceptor, multi: true},
    CONTROLLERS, SERVICES
  ],
  entryComponents: [
    AcceptTripDialog,
    ColumnFilterDialog,
    FileDownloadDialog,
    FileUploadDialog,
    RejectTripDialog,
    ShipmentBriefDialog,
    ClaimBriefDialog,
    ShipmentManagementDialog,
    VehicleRegistrationDialog
  ]
})
export class AppModule {
}
