import { Directive, ElementRef} from '@angular/core';

@Directive({
  selector: 'flx-collapse-content',
  host: {
    'class' : 'flx-collapse-content'
  }
})
export class CollapseContentDirective
{
  constructor()
  {
  }
}