import {NgModule} from "@angular/core";
import {AccordionModule} from "primeng/accordion";
import {ConfirmationService} from "primeng/api";
import {ButtonModule} from "primeng/button";
import {CardModule} from "primeng/card";
import {CheckboxModule} from "primeng/checkbox";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DropdownModule} from "primeng/dropdown";
import {DynamicDialogModule} from "primeng/dynamicdialog";
import {EditorModule} from "primeng/editor";
import {FileUploadModule} from "primeng/fileupload";
import {InputTextModule} from "primeng/inputtext"
import {InputTextareaModule} from "primeng/inputtextarea";
import {MenuModule} from "primeng/menu";
import {MessageModule} from "primeng/message";
import {MessagesModule} from "primeng/messages";
import {MultiSelectModule} from "primeng/multiselect";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {PaginatorModule} from "primeng/paginator";
import {PanelModule} from "primeng/panel";
import {PickListModule} from "primeng/picklist";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {TableModule} from "primeng/table";
import {ToastModule} from "primeng/toast";
import {TooltipModule} from "primeng/tooltip";
import {TriStateCheckboxModule} from "primeng/tristatecheckbox";
import {FilterUtils} from "primeng/utils";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { SelectButtonModule } from 'primeng/selectbutton';

import {CalendarModule} from "./components/primeng-custom/calendar/calendar.component";

@NgModule({
  exports: [
    AccordionModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ConfirmDialogModule,
    ConfirmationService,
    DropdownModule,
    DynamicDialogModule,
    EditorModule,
    FileUploadModule,
    FilterUtils,
    MenuModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PickListModule,
    ProgressSpinnerModule,
    InputTextModule,
    InputTextareaModule,
    TableModule,
    ToastModule,
    TooltipModule,
    TriStateCheckboxModule,
    VirtualScrollerModule,
    SelectButtonModule
  ]
})
export class PrimeClassModule {
}
