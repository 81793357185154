import { Component, Input, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';

import { Observable, of as observableOf } from 'rxjs';

import { LogItem, LogFilterModel, LogFormatModel } from '../infrastructure';
import { LoggingService } from '../services/logging.service';

@Component({
  selector: 'slender-logs-view',
  templateUrl: 'logs-view.html',
  styleUrls: ['logs-view.css']
})
export class LogsViewComponent
{
  @Input()
  public set logs(value: Observable<any[]> | any[])
  {
    this.historyLogs$ = (value instanceof Observable) ? value : observableOf(value);
  }

  @Input()
  public set filter(value: LogFilterModel)
  {
    if (value)
    {
      this.historyLogs$ = this._loggingService.getLogs(value);
    }
  }

  @Input()
  public format = new LogFormatModel();

  public historyLogs$: Observable<LogItem[]>;

  constructor(private _loggingService: LoggingService)
  {
    this.historyLogs$ = this._loggingService.getLogs();
  }
}
