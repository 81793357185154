import {Injectable} from "@angular/core";
import {IFilter} from "../models/filter/filter.interface";
import {PrimaryFilterController} from "./primary-filter.controller";
import Timer = NodeJS.Timer;

@Injectable()
export class PrimaryFilter {
  public get dateRange(): Date[] {
    return this._filter.dateRange;
  }
  public set dateRange(range: Date[]) {
    range[0].setHours(0);
    range[0].setMinutes(0);
    range[0].setSeconds(0);

    range[1].setHours(23);
    range[1].setMinutes(59);
    range[1].setSeconds(59);

    this._filter.dateRange = range;

    this.setFilters();
  }

  public get monthPicked(): Date {
    return this._filter.monthPicked;
  }
  public set monthPicked(picked: Date) {
    this._filter.monthPicked = picked;

    this.setFilters();
  }

  public get textSearch(): string {
    return this._filter.textSearch;
  }
  public set textSearch(text: string) {
    this._filter.textSearch = text;

    this.setFilters();
  }

  private readonly _filter: IFilter;

  private _filterTimeout: Timer;

  constructor(private _filterController: PrimaryFilterController) {
    this._filter = _filterController.getFilters();
  }

  private setFilters(): void {
    if(this._filterTimeout) {
      clearTimeout(this._filterTimeout);
    }

    this._filterTimeout = setTimeout(() => {
      this._filterController.setFilter(this._filter);
    }, 1000);
  }
}
